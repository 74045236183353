import React from "react";
import { useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import { config } from "config/config";
import { apiFetcher } from "utils/apiUtils";

export const LinkTable = (props) => {
  const {
    maxHeight,
    columns,
    rowKey,
    setSelectedRow,
    data,
    AC_SN,
    tableHeader,
    unselectable,
    fieldLabel,
    selectedRow,
  } = props;
  const theme = useTheme();
  const styles = ({
    container: {
      maxHeight,
      width: "100%",
      scrollbarWidth: "thin",
    },
    tableRow: {
      "&$selected, &$selected:hover": {
        backgroundColor: theme.palette.secondary.main,
      },
    },
    outlinedDiv: {
      width: "100%",
    },
    cell: {
      fontSize: 11,
      padding: "6px 6px 6px 6px",
    },
    selected: {},
  });
  const buildLink = (val) => {
    let foundFirstNum = false;
    let linkNum = "";

    for (let i = 0; i < val.length; i++) {
      const c = val[i];
      if (!foundFirstNum && c >= "0" && c <= "9") {
        foundFirstNum = true;
      }
      if (foundFirstNum) {
        linkNum = linkNum.concat(c);
      }
    }
    // return "FIM".concat((linkNum).concat(".pdf"))
    return linkNum;
  };

  const getModelNum = () => {
    const serialNum = parseInt(AC_SN);
    if (serialNum >= 19001) {
      return "1000";
    } else if (serialNum >= 15001) {
      return "900";
    } else if (serialNum >= 10002 && serialNum <= 10347) {
      return "700";
    } else {
      return "200";
    }
  };

  const handleClick = async (linkNum, modelNum) => {
    await apiFetcher(
      `${config.apiURL}/mdp/fimpdfs?`,
      "POST",
      { fileNum: linkNum, modelNum },
      { "Content-Type": "application/json" },
      false
    )
      .then((response) =>
        response.blob().then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "FIM" + linkNum + ".pdf";
          a.click();
        })
      )
      .catch((e) => {
        console.error(e);
      });
  };
  // eslint-disable-next-line react/display-name
  const InputComponent = React.forwardRef((props, ref) =>
    <div ref={ref} {...props} />);

  const OutlinedDiv = ({ children, label }) => {
    return (
      <TextField
        sx={styles.outlinedDiv}
        variant="outlined"
        label={label}
        multiline
        InputLabelProps={{ shrink: true }}
        InputProps={{
          inputComponent: InputComponent,
        }}
        inputProps={{ children }}
      />
    );
  };

  return (
    <OutlinedDiv label={fieldLabel}>
      <TableContainer sx={styles.container}>
        <Table size="small">
          {tableHeader ? (
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    sx={ styles.cell }
                    size="small"
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
          ) : null}
          <TableBody>
            {data.map((row, index) => {
              return (
                <TableRow
                  hover
                  tabIndex={-1}
                  key={`FIMPDF_${index}`}
                  sx={styles.tableRow}
                  classes={{ selected: styles.selected }}
                  selected={
                    unselectable
                      ? false
                      : row[rowKey] === (selectedRow ? selectedRow[rowKey] : "")
                  }
                  onClick={unselectable ? null : () => setSelectedRow(row)}
                >
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell
                        sx={ styles.cell }
                        size="small"
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, cursor: "pointer" }}
                        onClick={() =>
                          handleClick(buildLink(value), getModelNum())
                        }
                      >
                        {value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </OutlinedDiv>
  );
};
