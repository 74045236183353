import React, { Fragment } from "react";
import Grid from "@mui/material/Grid";
import { StaticTextField } from "mdp/components/Miscellaneous/StaticTextField";
import { StaticTextBox } from "mdp/components/Miscellaneous/StaticTextBox";
import { DataTable } from "mdp/components/Miscellaneous/DataTable";
import CircularProgress from "@mui/material/CircularProgress";
import { DefaultText } from "mdp/components/Miscellaneous/DefaultText";

// Column definitions of the "Data logic" table
const dataColumns = [
  { id: "BUS", label: "BUS", minWidth: "20px", align: "left" },
  { id: "IOC_BUS", label: "IOC", minWidth: "15px", align: "left" },
  { id: "LABEL", label: "LBL", minWidth: "15px", align: "left" },
  { id: "SDI", label: "SDI", minWidth: "15px", align: "left" },
  { id: "OPERATOR", label: "OPR", minWidth: "20px", align: "left" },
  { id: "BIT_MASK", label: "Bit Mask", minWidth: "150px", align: "left" },
  { id: "Comments", label: "Comments", minWidth: "120px", align: "left" },
];

export const DetailsPanelLogicTab = (props) => {
  const {
    panelState,
    selectedEQID,
    isMessageDetailsLoading,
    messageDetailsError,
  } = props;
  // selected row in the "Data logic" table
  return (
    <Fragment>
      {/* If there's an error fetching the data */}
      {messageDetailsError ? (
        <div>
          <DefaultText text={messageDetailsError.toString()} />
        </div>
      ) : null}
      {/* if data is loading show spinner */}
      {isMessageDetailsLoading ? (
        <div>
          <CircularProgress color="primary" />
        </div>
      ) : null}
      {/* show data if not error and not loading */}
      {!isMessageDetailsLoading && !messageDetailsError ? (
        <div className="detailsPanelDataTabContainer">
          <Grid container spacing={3}>
            <Grid item xs>
              <StaticTextField
                fieldLabel="Fault Logged"
                fieldValue={
                  panelState?.EQ_INFO?.filter(
                    (i) => i.EQ_ID === selectedEQID
                  )[0]?.FAULT_LOGGED || ""
                }
              />
            </Grid>
            <Grid item xs>
              <StaticTextField
                fieldLabel="Message #"
                fieldValue={
                  panelState?.EQ_INFO?.filter(
                    (i) => i.EQ_ID === selectedEQID
                  )[0]?.MSG_NB || ""
                }
              />
            </Grid>
          </Grid>
          <div className="detailsPanelDataTable2">
            <div>
              <StaticTextBox
                maxRows={7}
                fieldLabel="Logic Used to Trigger Message"
                fieldValue={
                  panelState?.EQ_INFO?.filter(
                    (i) => i.EQ_ID === selectedEQID
                  )[0]?.LOGIC || ""
                }
              />
            </div>
            <div>
              <StaticTextBox
                maxRows={7}
                fieldLabel="Help and Troubleshooting Tips"
                fieldValue={
                  panelState?.EQ_INFO?.filter(
                    (i) => i.EQ_ID === selectedEQID
                  )[0]?.EQ_DESCRIPTION || ""
                }
              />
            </div>
            <div>
              <DataTable
                maxHeight="270px"
                tableHeader={true}
                fieldLabel="Data Logic"
                data={
                  panelState?.EQ_DEFINITIONS?.filter(
                    (i) => i.EQ_ID === selectedEQID
                  ) || []
                }
                columns={dataColumns}
                unselectable
              />
            </div>
          </div>
        </div>
      ) : null}
    </Fragment>
  );
};
