import React from "react";
import TextField from "@mui/material/TextField";
export const StaticTextBox = (props) => {
  const { fieldLabel, labelSmall, editable, fieldValue, setValue, maxRows } =
    props;
  const styles = ({
    textField: {
      width: "100%",
      marginBottom: "30px",
    },
    textFieldText: {
      fontSize: 11,
    },
    textFieldLabelSmall: {
      fontSize: 14,
    },
  });
  return (
    <TextField
      maxRows={maxRows}
      multiline
      variant="outlined"
      sx={styles.textField}
      label={fieldLabel}
      value={fieldValue ?? ""}
      onChange={(event) => {
        setValue(event.target.value);
      }}
      InputProps={{
        readOnly: !editable,
        classes: {
          style: styles.textFieldText,
        },
      }}
      InputLabelProps={{
        shrink: true,
        classes: {
          root: labelSmall ? styles.textFieldLabelSmall : null,
        },
      }}
    />
  );
};
