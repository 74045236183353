/* eslint-disable max-len */
import React, { useState } from 'react';
import Card from '@mui/material/Card';
import { RawDataTablePanel } from 'mdp/components/RawDataTab/RawDataTablePanel';
import { RawDataFiltersPanel } from 'mdp/components/RawDataTab/RawDataFiltersPanel';
import { DataUploadPanel } from 'mdp/components/RawDataTab/DataUploadPanel';
/* eslint-enable max-len */

export const RawDataTab = (props) => {
  const [isUploadPanelOpen, setIsUploadPanelOpen] = useState(false);

  return (
    <div className='threePanelTab'>
      <div className='filtersPanel'>
        <Card className='threePanelTabCard'>
          <RawDataFiltersPanel />
        </Card>
      </div>
      <div className='tablePanel expandRight'>
        <Card className='threePanelTabCard'>
          <RawDataTablePanel setIsUploadPanelOpen={setIsUploadPanelOpen} />
        </Card>
      </div>
      <DataUploadPanel
        isUploadPanelOpen={isUploadPanelOpen}
        setIsUploadPanelOpen={setIsUploadPanelOpen}
      />
    </div>
  );
};
