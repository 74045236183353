import React, { Fragment, useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import { DefaultText } from "mdp/components/Miscellaneous/DefaultText";
import { TextField, Button, Box } from "@mui/material";
import { apiFetcher } from "utils/apiUtils";
import { config } from "config/config";

export const DetailsPanelFieldNotesTab = (props) => {
  const {
    panelState,
    selectedEQID,
    isMessageDetailsLoading,
    messageDetailsError,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [text, setText] = useState("");
  const [userSideOperatorNotes, setUserSideOperatorNotes] = useState([]);

  const onChangeText = (event) => {
    setText(event.target.value);
  };

  const onSave = async () => {
    setIsLoading(true);
    apiFetcher(
      `${config.apiURL}/mdp/mdcmessages/${panelState.MSG_ID}/details`,
      "POST",
      {
        eqid: selectedEQID,
        acModel: panelState.AC_MODEL,
        mdtVersion: panelState.MDT_VERSION,
        fieldNotes: text,
      },
      {
        "Content-Type": "application/json",
      }
    )
      .then((response) => {
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
      });
    let found = false;
    for (let i = 0; i < userSideOperatorNotes.length; i++) {
      if (userSideOperatorNotes[i].EQ_ID === selectedEQID) {
        found = true;
        const newUserSideOperatorNotes = userSideOperatorNotes;
        newUserSideOperatorNotes[i].FIELD_NOTES = text;
        setUserSideOperatorNotes([...newUserSideOperatorNotes]);
      }
    }
    if (found === false) {
      const newUserSideOperatorNotes = userSideOperatorNotes;
      newUserSideOperatorNotes.push({
        FIELD_NOTES: text,
        EQ_ID: selectedEQID,
      });
      setUserSideOperatorNotes([
        ...newUserSideOperatorNotes,
        ...userSideOperatorNotes,
      ]);
    }
  };

  useEffect(() => {
    if (panelState && panelState.OPERATOR_NOTES) {
      setText(
        Object.keys(panelState.OPERATOR_NOTES).length === 0
          ? ""
          : panelState?.OPERATOR_NOTES?.filter(
              (i) => i.EQ_ID === selectedEQID
            )[0]?.FIELD_NOTES || ""
      );
      setUserSideOperatorNotes(panelState.OPERATOR_NOTES);
    }
  }, [props]);

  return (
    <Fragment>
      {/* If there's an error fetching the data */}
      {messageDetailsError ? (
        <div>
          <DefaultText text={messageDetailsError.toString()} />
        </div>
      ) : null}
      {/* if data is loading show spinner */}
      {isMessageDetailsLoading ? (
        <div>
          <CircularProgress color="primary" />
        </div>
      ) : null}
      {/* show data if not error and not loading */}
      {!isMessageDetailsLoading && !messageDetailsError ? (
        <div style={{ width: "100%", paddingTop: "10px" }}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <TextField
                minRows={5}
                multiline
                variant="outlined"
                label="Field Notes"
                fullWidth
                inputProps={{ style: { fontSize: 11 } }}
                value={text}
                onChange={onChangeText}
              />
            </Grid>
            {selectedEQID && (
              <Box m={1} pt={1} sx={{ width: '100%' }}>
                {isLoading ? (
                  <div>
                    <CircularProgress color="primary" />
                  </div>
                ) : (
                  <Button
                    sx={{ px: 5, width: '100%' }}
                    variant="outlined"
                    color="primary"
                    onClick={onSave}
                  >
                    Save Field Notes
                  </Button>
                )}
              </Box>
            )}
          </Grid>
        </div>
      ) : null}
    </Fragment>
  );
};
