import React from 'react';
import Card from '@mui/material/Card';
import 'orca/components/AlertsTab/AlertsTab.css';
import { AlertsFiltersPanel } from 'orca/components/AlertsTab/AlertsFiltersPanel';
import { AlertsTablePanel } from 'orca/components/AlertsTab/AlertsTablePanel';


export const AlertsTab = (props) => {
  const styles = ({
    root: {
      height: '100%',
      marginRight: '10px',
      borderTop: '1px solid rgba(0,0,0,0.2)',
      borderLeft: '1px solid rgba(0,0,0,0.2)',
      borderRight: '1px solid rgba(0,0,0,0.2)',
      borderBottom: '1px solid rgba(0,0,0,0.2)',
    },
  });

  return (
    <div className="alertsTab">
      <div className="alertsTabFiltersPane">
        <Card sx={styles.root}>
          <AlertsFiltersPanel></AlertsFiltersPanel>
        </Card>
      </div>
      <div className="alertsTabDataPane">
        <Card sx={styles.root}>
          <AlertsTablePanel></AlertsTablePanel>
        </Card>
      </div>
    </div>
  );
};
