const localConfig = {
  runLocally: true,
  apiURL: "http://127.0.0.1:5000/api/v1",
  // initial number of results to fetch when querying database
  initialFetchLimit: "500",
  CRJ: "CRJ100/200/400",
  CRJ700: "CRJ700/900/1000",
  issuer: "https://dev-84188543.okta.com/oauth2/default",
  clientId: "0oa6p6v8kaGdsoGgS5d7",
  omdURL: "https://omd-dev.mhirj.com",
  cttURL: "https://componenttracker-dev.mhirj.com",
};

const prodConfig = {
  runLocally: false,
  apiURL:
    "https://dnasuitebe-dev.mhirj.com/api/v1",
  initialFetchLimit: "500",
  CRJ: "CRJ100/200/400",
  CRJ700: "CRJ700/900/1000",
  issuer: "https://mhirj.okta.com/oauth2/default", // "https://mhirj.okta.com/oauth2/default",
  clientId: "0oa5ra9kh3JvoSi2d4h7", // "0oa2dg2ul5Xqr3XcX4h7",
  omdURL: "https://omd-dev.mhirj.com",
  cttURL: "https://componenttracker-dev.mhirj.com",
};

export const config =
  process.env.NODE_ENV === "production" ? prodConfig : localConfig;
