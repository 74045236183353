import React, { Fragment } from 'react';
import { Tilt } from 'react-tilt';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import { Grid as MaterialGrid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import FlightIcon from '@mui/icons-material/Flight';
import 'mdp/components/FleetTab/FleetTab.css';


const tiltOptions = { max: 25, scale: 1.08, perspective: 300, speed: 400 };

const getStyles = (theme, acTileWidth, acTileHeight) => {
  return ({
    acTile: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: `${acTileWidth}px`,
      height: `${acTileHeight}px`,
      '&:hover': {
        cursor: 'pointer',
      },
    },
    planeIcon: {
      paddingRight: '5px',
      width: '25px',
      height: '25px',
    },
    tiltStyles: {
      width: `${acTileWidth}px`,
      height: `${acTileHeight}px`,
    },
    divider: {
      height: '65%',
      margin: '0 10px 0 10px',
    },
    titleText: {
      color: theme.palette.text.title1,
      fontSize: '50px',
      fontWeight: '600',
    },
    acTitle: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      textAlign: 'center',
      justifyContent: 'center',
      fontSize: '18px',
      color: theme.palette.text.secondary,
      paddingBottom: '20px',
    },
  });
};

export const ACTile = ({
  ac,
  acTileWidth,
  acTileHeight,
  handleTileClick,
  showRegistrationNumber,
}) => {
  /* A component that renders a single aircraft tile in the fleet tab

     @param <ac:JSON>: Check Backend documentation on the /fleettab endpoint
     for format of this object
     @param <acTileHeight:int>: height of the tile
     @param <acTileWidth:int>: witdth of the tile
     @param <handelTileCLick:func>: what to do when a tile is clicked
  */
  const theme = useTheme();
  const styles = getStyles(theme, acTileWidth, acTileHeight);

  return (
    <Tilt sx={styles.tiltStyles} options={tiltOptions}>
      <Paper
        sx={styles.acTile}
        onClick={() => handleTileClick(ac.AC_SN, ac.OPERATOR_CODE)}
      >
        <MaterialGrid container spacing={1}>
          <MaterialGrid item xs={6}>
            <FlightIcon sx={styles.planeIcon} color="primary" />
            {showRegistrationNumber ? ac.AC_TN : ac.AC_SN}
          </MaterialGrid>
          <MaterialGrid item xs={6}>
            {ac.OPERATOR_CODE}
          </MaterialGrid>
          <MaterialGrid item xs={12}>
            <div className="lastUploaded">
              <h2>Last Download:</h2>
              <p>{ac.LAST_DOWNLOAD_DATE}</p>
            </div>
          </MaterialGrid>
        </MaterialGrid>
        <div className="tileFooter">
          <div className="tileInfo">
            <h2>Fault</h2>
            <p>{ac.FAULT}</p>
          </div>
          {ac.AC_MODEL === 'CRJ700' && (
            <Fragment>
              <Divider sx={styles.divider} orientation="vertical" />
              <div className="tileInfo">
                <h2>Service</h2>
                <p>{ac.SERVICE}</p>
              </div>
            </Fragment>
          )}
        </div>
      </Paper>
    </Tilt>
  );
};

export const AllACTile = ({ acTileWidth, acTileHeight, handleTileClick }) => {
  // function that renders the "All Aircraft" tile
  const theme = useTheme();
  const styles = getStyles(theme, acTileWidth, acTileHeight);

  return (
    <Tilt sx={styles.tiltStyles} options={tiltOptions}>
      <Paper sx={styles.acTile} onClick={() => handleTileClick('All')}>
        <div style={styles.acTitle}>All Aircraft</div>
      </Paper>
    </Tilt>
  );
};
