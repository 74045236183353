import React from "react";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";

const styles = ({
  formControl: {
    margin: "5px 0 0 0",
    minWidth: "100%",
    maxWidth: "100%",
  },
  listText: {
    fontSize: 12,
  },
  menu: {
    width: "250px",
    height: "35px",
  },
  selectBox: {
    fontSize: 12,
  },
});

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: "400px",
      minWidth: "200px",
      maxWidth: "250px",
    },
  },
};

export const CheckBoxSelect = (props) => {
  const {
    filterState,
    label,
    multiple,
    filterName,
    shouldValidate,
    setFilterState,
    options,
  } = props;

  const handleChange = (event) => {
    setFilterState({
      ...filterState,
      [filterName]: event.target.value,
    });
  };
  const renderValues = (selected) => {
    if (multiple) {
      return selected.join(", ");
    } else {
      return selected;
    }
  };
  return (
    <FormControl variant="standard" sx={styles.formControl}>
      <InputLabel id={label}>{label}</InputLabel>
      <Select
        variant="standard"
        error={!filterState[filterName].length && shouldValidate}
        sx={styles.selectBox}
        labelId={label}
        multiple={multiple}
        value={filterState[filterName]}
        onChange={handleChange}
        input={<Input />}
        renderValue={renderValues}
        MenuProps={MenuProps}>
        {options.map((item) => (
          <MenuItem sx={styles.menu} key={item} value={item}>
            {multiple && (
              <Checkbox
                color="primary"
                checked={filterState[filterName].indexOf(item) > -1}
              />
            )}
            <ListItemText
              primaryTypographyProps={{ sx: styles.listText }}
              primary={item}
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
