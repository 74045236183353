import React, { useState, useEffect } from 'react';
import image from 'assets/mhirj_logo.png';
import { useTheme } from '@mui/material/styles';


const getStyles = (theme) => {
  return ({
    searchBar: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
    },
    searchBarText: {
      fontSize: 16,
      marginLeft: '10px',
      flex: 1,
    },
    searchIcon: {
      marginLeft: '10px',
      fill: theme.palette.primary.light,
      width: '20px',
      height: '20px',
    },
    clearSearchIcon: {
      fill: theme.palette.primary.light,
      width: '20px',
      height: '20px',
    },
    clearSearchIconButton: {
      marginRight: '10px',
      padding: '0 0 0 0',
      margin: '0 10px 0 0',
    },
    titleText: {
      color: theme.palette.text.title1,
      fontSize: '50px',
      fontWeight: '600',
    },
    subtitleText: {
      color: theme.palette.text.title1,
      fontStyle: 'italic',
    },
    formLabel: {
      textAlign: 'left',
      paddingTop: '1rem',
    },
    filters: {},
    switch: {
      '& label': {
        textAlign: 'center',
        float: 'right',
        '@media (max-width: 950px)': {
          float: 'left',
        },
      },
    },
  });
};

export const ErrorPopup = () => {
  useEffect(() => {
    const pathname = window.location.pathname;
    const route = pathname.substring(pathname.indexOf('/') + 1).toLowerCase();
    // console.log(route);
    if (route.startsWith('mdp')) {
      setErrorMessage(
          // eslint-disable-next-line max-len
          'Sorry, you don\'t have access to The Maintenance Diagnostic Platform (MDP)',
      );
    } else if (route.startsWith('orca')) {
      setErrorMessage('Sorry, you don\'t have access to ORCA');
    } else {
      setErrorMessage('Sorry, the requested page doesn\'t exist');
    }
  }, [window.location.pathname]);

  const theme = useTheme();
  const styles = getStyles(theme);
  const [errorMessage, setErrorMessage] = useState();
  return (
    <>
      <img
        src={image}
        alt={'MHIRJ Logo'}
        height={100}
        width={100}
        style={{ position: 'absolute' }}
      />
      <div className="title">
        <h1 style={styles.titleText}>DNA Suite</h1>
        <p style={styles.subtitleText}>Version 1.1</p>
      </div>
      <div className="title">
        <br></br>
        <br></br>
        <br></br>
        <h4>{errorMessage}</h4>
        <h4>
          To learn more about DNA service or to get access to our services,
          please reach out to the MHIRJ eServices
        </h4>
        <h4>team at eServices@mhirj.com</h4>
      </div>
    </>
  );
};
