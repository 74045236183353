/* eslint-disable new-cap */
import React, { useState, createContext } from 'react';

export const SpltFiltersContext = createContext();

export const SpltFiltersContextProvider = (props) => {
  const getDateString = (offset) => {
    const newDate = new Date();
    newDate.setMonth(newDate.getMonth() + offset);
    const tzOffset = newDate.getTimezoneOffset();
    const newDateWithOffset = new Date(
        newDate.getTime() - tzOffset * 60 * 1000);
    return newDateWithOffset.toISOString().split('T')[0];
  };

  const defaultSpltFilterState = {
    name: 'spltFilterState',
    startDate: getDateString(-1), // one month ago
    endDate: getDateString(0),
    acModel: '',
    fleets: [],
    ata: [],
    acsn: [],
    actn: [],
    messageType: [],
    chronic: [],
    category: [],
    excludeFleets: false,
    excludeAta: false,
    excludeAcsn: false,
    excludeActn: false,
    excludeMessageTypes: false,
    excludeChronic: false,
    excludeCategory: false,
  };
  // state of the filters panel in the splt data tab
  const [
    spltFilterState,
    setSpltFilterState,
  ] = useState(defaultSpltFilterState);
  // copy of the filters that were applied in the current search
  const [appliedSpltFilters, setAppliedSpltFilters] = useState(null);
  // number of rows to display per page in the raw data table
  const [rowsPerPage, setRowsPerPage] = useState(100);
  // page number the user is on in the raw data table
  const [curTablePage, setCurTablePage] = useState(0);
  const [mimicApplyClick, setMimicApplyClick] = useState(false);
  // state used to store data selected
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  // state used to store current sorting condition
  const [sortDirection, setSortDirection] = useState("asc");
  const [sortCol, setSortCol] = useState(null);

  return (
    <SpltFiltersContext.Provider
      value={{
        spltFilterState,
        setSpltFilterState,
        appliedSpltFilters,
        setAppliedSpltFilters,
        defaultSpltFilterState,
        rowsPerPage,
        setRowsPerPage,
        curTablePage,
        setCurTablePage,
        mimicApplyClick,
        setMimicApplyClick,
        selectedRows,
        setSelectedRows,
        selectedRowIndex,
        setSelectedRowIndex,
        sortDirection,
        setSortDirection,
        sortCol,
        setSortCol,
      }}
    >
      {props.children}
    </SpltFiltersContext.Provider>
  );
};
export default SpltFiltersContextProvider;
