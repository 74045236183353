import React, { Fragment } from "react";
import { Tooltip, Button } from "@mui/material";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { useTheme } from "@mui/material/styles";
import { getCurrentDate } from "utils/utilFunctions";
import { generateCSV } from "orca/components/Miscellaneous/GenerateCsv";

export const CsvExportButton = (props) => {
  /*
  Component representing the export table button in the data table
  */
  const {
    tableData,
    hasTableData,
    fileName,
    setMessageInfo,
    setShowAlertPopup,
   } = props;

  const theme = useTheme();
  const styles = ({
    tableIconButton: {
      padding: "3 10 3 10",
      margin: "0 0 0 0",
      backgroundColor: "rgba(202, 204, 206, 0.38)",
      color: "black",
      "&:hover": {
        backgroundColor: "rgba(202, 204, 206)",
      },
    },
    buttonIcons: {
      width: "20px",
      height: "20px",
      marginRight: "8px",
      fill: theme.palette.primary.dark,
    },
  });

  const handleClick = () => {
    // if no data is selected to download
    if (hasTableData && tableData.length === 0) {
      setMessageInfo({
        message: "No data is selected",
        severity: "error",
      });
      setShowAlertPopup(true);
      // if no search performed
    } else if (tableData.length === 0) {
      setMessageInfo({
        message: "A valid search must be performed first",
        severity: "error",
      });
      setShowAlertPopup(true);
    } else {
      const header = Object.keys(tableData[0]);
      generateCSV(header, tableData, fileName + getCurrentDate());
      setMessageInfo({
        message: "Download Successfully",
        severity: "info",
      });
      setShowAlertPopup(true);
    }
  };

  return (
    <Fragment>
      <Tooltip title="Export table to csv">
        <Button
          sx={styles.tableIconButton}
          variant="contained"
          disableElevation
          size="small"
          onClick={handleClick}
        >
          <SaveAltIcon sx={styles.buttonIcons} />
          download
        </Button>
      </Tooltip>
    </Fragment>
  );
};
