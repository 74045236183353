/* eslint-disable max-len */
import React, { Fragment, useState } from "react";
import { useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import NotesIcon from "@mui/icons-material/Notes";
import IconButton from "@mui/material/IconButton";
import { NotesPanel } from "mdp/components/MessagesTab/MessagesTable/NotesPanel.js";
import { DefaultText } from "mdp/components/Miscellaneous/DefaultText";
import { apiFetcher } from "utils/apiUtils";
import { config } from "config/config";
import { Button, Tooltip } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";

export const MainDataTable = (props) => {
  /*
  Component representing main mdc messages table

  @props <url:string>: backend url to query rows from
  @props <columns:list>: list of columns in the table e.g.
  [{key: "AC_SN",label: "Serial Number", minWidth: 50,}]
  @props <rowKey:string>: column name that acts as a unique identifier for
  each row e.g. MDC messages data from the backend has a MSG_ID column
  @props <tableData:list>: state that holds the table data
  @props <setTableData: setter for tableData state
  @props isLoading: state that represents if a query is running
  @props setIsLoading: setter for isLoading sate
  @props error: state that holds errors when fetching data from backend
  @props setError: setter for error state
  @props appliedFilters: state stores copy of the filters that were applied
  @props rowsPerPage: state stores number or rows per table page
  @props setRowsPerPage: setter for rowsPerPage state
  @props curTablePage: state stores current table page user is on
  @props setCurTablePage: setter for curTablePage state
  @props selectedRow: state stores row that user has currently selected
  @props setSelectedRow: setter for selectedRow state
  @props isDetailsPanelExpand:bool: is the details panel expanded modal open
  @props setIsDetailsPanelExpand: setter for isDetailsPanelExpand
  @props setMimicApplyClick: state setter for whether to trigger a mimicked
  "Apply" button click
  @props <messageFilterState:object>: state of the filters panel
  @props <setMessageFilterState>: setter for messageFilterState
  @props <formatFilters:fucntion>: takes in filterState and mutates it. Used to
  make some sort of change to the filterState before it is sent to the backend
  */
  const {
    rowsPerPage,
    setRowsPerPage,
    setMimicApplyClick,
    tableData,
    specialistEditMode,
    setTableData,
    setCurTablePage,
    formatFilters,
    columns,
    setSelectedRow,
    url,
    appliedFilters,
    selectedRow,
    setIsLoading,
    rowKey,
    setError,
    setIsDetailsPanelExpand,
    setMessageFilterState,
    isLoading,
    error,
    curTablePage,
    messageFilterState,
  } = props;
  const theme = useTheme();

  const styles = ({
    tableHeader: {
      color: theme.palette.primary.main,
      backgroundColor: "white",
      fontWeight: "bold",
    },
    spinnerAndText: {
      marginTop: "42vh",
    },
    container: {
      height: "88%",
      scrollbarWidth: "thin",
    },
    tableRow: {
      "&$selected, &$selected:hover": {
        backgroundColor: theme.palette.secondary.main,
      },
    },
    headerCell: {
      padding: "6px 0 6px 8px",
    },
    tableCell: {
      fontSize: 11,
      padding: "6px 0 6px 8px",
    },
    noteIcon: {
      width: "18px",
      height: "18px",
    },
    noteIconButton: {
      padding: "0 0 0 0",
      margin: "0 0 0 0",
    },
    selected: {},
  });

  const [isNotesPanelOpen, setIsNotesPanelOpen] = useState(false);
  const [selectedMessageID, setSelectedMessageID] = useState(null);
  const [isDialogueOpen, setIsDialogueOpen] = useState(false);

  const handleChangePage = (event, newPage) => {
    /*
    Changes the current page in the MDC messages table

    @param event: dummy variable
    @param newPage: integer containing the new page number the user clicked
    */
    // if there isn't enough cached data to show the next page then
    // request more rows from the backend and append them to the table state
    if (newPage * rowsPerPage >= tableData.data.length) {
      setIsLoading(true);
      // if formatFilters prop is provided then format the filters parameters
      // before sending the request to the backend
      let payload;
      if (formatFilters) {
        payload = formatFilters(appliedFilters);
      } else {
        payload = appliedFilters;
      }
      apiFetcher(
        `${url}?offset=${tableData.data.length}&limit=${rowsPerPage}`,
        "POST",
        payload,
        {
          "Content-Type": "application/json",
        }
      )
        .then((response) => {
          setTableData({
            ...tableData,
            data: [...tableData.data, ...response.data],
          });
          setCurTablePage(newPage);
          setIsLoading(false);
        })
        .catch((e) => {
          setError(e);
        });
    } else {
      setCurTablePage(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    /*
    Changes the number of rows to show per page

    @param event: string containg the number of rows per page set by the user
    */

    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage); // update rows per page
    setCurTablePage(0); // reset to page 0
    // if there isnt enough cached data to show the first page then request
    // it from the backend append them to the table state
    if (newRowsPerPage > tableData.data.length) {
      setIsLoading(true);
      // if formatFilters prop is provided then format the filters parameters
      // before sending the request to the backend
      let payload;
      if (formatFilters) {
        payload = formatFilters(appliedFilters);
      } else {
        payload = appliedFilters;
      }
      apiFetcher(
        `${url}?offset=${tableData.data.length}&limit=${rowsPerPage}`,
        "POST",
        payload,
        {
          "Content-Type": "application/json",
        }
      )
        .then((response) => {
          setTableData({
            ...tableData,
            data: [...tableData.data, ...response.data],
          });

          setIsLoading(false);
        })
        .catch((e) => {
          setError(e);
        });
    }
  };

  const handleNoteButtonClick = (messageID) => {
    /*
  This function executes when a user clicks on the message notes icon
  for a specific message

  @param <messageID:string>: MSG_ID of the message that was clicked
  */
    setIsNotesPanelOpen(true);
    setSelectedMessageID(messageID);
  };

  const handleBookmarkClick = (row) => {
    /*
  This function executes when a user clicks on a checkmark box in the
  bookmark column. It updates the BOOKMARK column in the database
  for the mdc messages that was clicked.

  @param <row:JSONObject>: the mdc message that was clicked
  */

    const modifiedTableData = tableData.data;
    // find the index of the row that was clicked
    const rowIdx = modifiedTableData.findIndex((i) => i.MSG_ID === row.MSG_ID);

    // let the user know the bookmark status is updating
    modifiedTableData[rowIdx] = {
      ...row,
      BOOKMARK: "updating",
    };
    setTableData({ ...tableData, data: modifiedTableData });

    // update the database
    // if the message is already bookmarked un-bookmark it else bookmark it
    apiFetcher(
      `${config.apiURL}/mdp/mdcmessages/${row.MSG_ID}/bookmarks?bookmark=${
        row.BOOKMARK === 1 ? "False" : "True"
      }`,
      "POST"
    )
      .then((response) => {
        // once the database has been updated render the changes in the frontend
        modifiedTableData[rowIdx] = {
          ...row,
          BOOKMARK: row.BOOKMARK === 1 ? 0 : 1,
        };
        setTableData({ ...tableData, data: modifiedTableData });
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const handleSelectRow = (row, double) => {
    if (!specialistEditMode) {
      setSelectedRow(row);
      if (double) {
        setIsDetailsPanelExpand(true);
      }
    } else {
      setIsDialogueOpen(true);
    }
  };

  // Check if user has subscribed MDP v3
  const isSubscribed = tableData?.data[0]?.EICAS !== "Subscription Required"

  return (
    <Fragment>
      {/* If there's an error fetching the data */}
      {error && (
        <div style={styles.spinnerAndText}>
          <DefaultText text={error.toString()} />
        </div>
      )}
      {/* default message to show if the user hasnt made a query */}
      {!error && !tableData && !isLoading && (
        <div style={styles.spinnerAndText}>
          <DefaultText text="Apply some filters" />
        </div>
      )}
      {/* if the query is complete or query in progress show the table */}
      {!error && (tableData || isLoading) && (
        <Fragment>
          <TableContainer sx={styles.container}>
            {/* if query in progress show loading spinner */}
            {isLoading && (
              <div style={styles.spinnerAndText}>
                <CircularProgress color="primary" />
              </div>
            )}
            {/* if the query completed but the table is empty */}
            {!isLoading && !tableData.data.length && (
              <div style={styles.spinnerAndText}>
                <DefaultText text="No records found" />
              </div>
            )}
            {/* if query completed and table is not empty */}
            {!isLoading && tableData.data.length ? (
              <Table stickyHeader size="small">
                {/* Column headers of the table */}
                <TableHead>
                  <TableRow>
                    {columns.map((column, index) => (
                      <TableCell
                        sx={styles.headerCell}
                        key={column.key}
                        align={column.align}
                        style={{
                          ...styles.tableHeader,
                          minWidth: column.minWidth,
                        }}
                      >
                        {/*
                        for sortable columns render sorting icons. if the
                        user clicks on a column header to sort it then update
                        orderbyCol and orderbyDir in the message filters
                        context. This will trigger a new search to the backend
                        essentially mimicking the user clicking the "apply"
                        button
                        */}
                        {column.sortable ? (
                          <TableSortLabel
                            active={
                              messageFilterState.orderbyCol === column.key
                            }
                            direction={messageFilterState.orderbyDir}
                            onClick={() => {
                              setMessageFilterState({
                                ...messageFilterState,
                                orderbyCol: column.key,
                                orderbyDir:
                                  messageFilterState.orderbyDir === "asc"
                                    ? "desc"
                                    : "asc",
                              });
                              setMimicApplyClick(true);
                            }}
                          >
                            <Tooltip
                              title={
                                column.key === "EICAS" && !isSubscribed
                                ? "Subscription Required" : ""}>
                                  {column.label}
                              </Tooltip>
                          </TableSortLabel>
                        ) : (
                          column.label
                        )}
                      </TableCell>
                    ))}
                    <TableCell
                      sx={styles.headerCell}
                      key={`Notes Header`}
                      style={{
                        ...styles.tableHeader,
                        minWidth: 65,
                      }}
                    >
                      Notes
                    </TableCell>
                    <TableCell
                      sx={styles.headerCell}
                      key={`Bookmark Header`}
                      style={{
                        ...styles.tableHeader,
                        minWidth: 65,
                      }}
                    >
                      Bookmark
                    </TableCell>
                  </TableRow>
                </TableHead>
                {/* main body of the table */}
                <TableBody>
                  {/* render a table row for each MDC message based on number of
                  message per page and the current page number */}
                  {tableData.data
                    .slice(
                      curTablePage * rowsPerPage,
                      curTablePage * rowsPerPage + rowsPerPage
                    )
                    .map((row, index) => {
                      let colour = "white";
                      if (row.EVENT_NOTE === "1") {
                        colour = "#F0F4FE";
                      }
                      if (selectedRow) {
                        if (
                          selectedRow.MSG_ID === row.MSG_ID &&
                          row.EVENT_NOTE !== "1"
                        ) {
                          colour = "lightgrey";
                        } else if (
                          row.EVENT_NOTE === "1" &&
                          selectedRow.MSG_ID === row.MSG_ID
                        ) {
                          colour = "#D3D3F0";
                        }
                      }

                      return (
                        <TableRow
                          style={{ backgroundColor: colour }}
                          hover
                          tabIndex={-1}
                          key={row[rowKey]}
                          sx={styles.tableRow}
                          classes={{ selected: styles.selected }}
                          selected={row[rowKey] === selectedRow?.MSG_ID}
                        >
                          {columns.map((column, index) => {
                            const value = (column.key === "EICAS" && !isSubscribed)
                            ? "" : row[column.key];
                            return (
                              <TableCell
                                key={column.key}
                                align={column.align}
                                sx={styles.tableCell}
                                onClick={() => {
                                  handleSelectRow(row, false);
                                }}
                                onDoubleClick={() => {
                                  handleSelectRow(row, true);
                                }}
                              >
                                {value}
                              </TableCell>
                            );
                          })}
                          {/* Mesage Notes Column */}
                          <TableCell
                            key={`Notes ${index}`}
                            sx={styles.tableCell}
                          >
                            <IconButton
                              sx={styles.noteIconButton}
                              onClick={() => handleNoteButtonClick(row.MSG_ID)}
                              size="large">
                              <NotesIcon
                                sx={styles.noteIcon}
                                color="primary"
                              />
                            </IconButton>
                          </TableCell>
                          {/* Bookmark Column */}
                          <TableCell
                            key={"Bookmark"}
                            padding="checkbox"
                            sx={styles.tableCell}
                          >
                            {/* If the Bookmark status of a message is
                              updating then show the loading spinner */}
                            {row.BOOKMARK === "updating" ? (
                              <CircularProgress color="primary" size={20} />
                            ) : (
                              <Checkbox
                                color="primary"
                                checked={row.BOOKMARK === 1}
                                onClick={() => {
                                  handleBookmarkClick(row);
                                }}
                              />
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            ) : null}
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[50, 100, 200, 300]}
            component="div"
            count={tableData?.totalRows || 0}
            rowsPerPage={rowsPerPage}
            page={curTablePage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Fragment>
      )}
      <NotesPanel
        setTableData={setTableData}
        tableData={tableData}
        isPanelOpen={isNotesPanelOpen}
        setIsPanelOpen={setIsNotesPanelOpen}
        messageID={selectedMessageID}
        setMessageID={setSelectedMessageID}
      />
      <Dialog maxWidth="xs" open={isDialogueOpen}>
        <DialogTitle>
          Discard or Save Your Specialist Input Edits Before Switching Selected
          Message
        </DialogTitle>
        <DialogActions>
          <Button
            color="error"
            onClick={() => {
              setIsDialogueOpen(false);
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};
