import React, { useState, createContext } from 'react';

export const TimelineDataContext = createContext();

export const TimelineDataContextProvider = (props) => {
  // if the Timeline Data table data is loading
  const [isLoading, setIsLoading] = useState(false);
  // if theres an error when querying Timeline Data table data
  const [error, setError] = useState(null);
  // state used to store data from Timeline Data Table
  const [timelineTableState, setTimelineTableState] = useState(null);

  return (
    <TimelineDataContext.Provider
      value={{
        timelineTableState,
        setTimelineTableState,
        isLoading,
        setIsLoading,
        error,
        setError,
      }}
    >
      {props.children}
    </TimelineDataContext.Provider>
  );
};
export default TimelineDataContextProvider;
