/* eslint-disable max-len */
import React, { useState } from "react";
import Card from "@mui/material/Card";
import { MessageFiltersPanel } from "mdp/components/MessagesTab/Filters/MessageFiltersPanel";
import { MessageTablePanel } from "mdp/components/MessagesTab/MessagesTable/MessageTablePanel";
import { MessageDetailsPanel } from "mdp/components/MessagesTab/MessageDetails/MessageDetailsPanel";
/* eslint-enable max-len */

export const MessagesTab = (props) => {
  const { specialistEditMode, setSpecialistEditMode } = props;

  const [hideDetails, setHideDetails] = useState(false);
  const [hideFilters, setHideFilters] = useState(false);
  // is the details panel expanded popup open
  const [isDetailsPanelExpand, setIsDetailsPanelExpand] = useState(false);

  const getMessagePaneClass = () => {
    // return the appropriate css class depending on which panels are hidden
    let classname = "tablePanel";
    if (hideDetails && !hideFilters) {
      classname = "tablePanel expandRight";
    } else if (hideFilters && !hideDetails) {
      classname = "tablePanel expandLeft";
    } else if (hideFilters && hideDetails) {
      classname = "tablePanel expandFull";
    }
    return classname;
  };

  return (
    <div className="threePanelTab">
      {/* filters panel */}
      <div className={"filtersPanel".concat(hideFilters ? " hide" : "")}>
        <Card className="threePanelTabCard">
          <MessageFiltersPanel specialistEditMode={specialistEditMode} />
        </Card>
      </div>
      {/* MDC messages table */}
      <div className={getMessagePaneClass()}>
        <Card className="threePanelTabCard">
          <MessageTablePanel
            hideDetails={hideDetails}
            hideFilters={hideFilters}
            setHideDetails={setHideDetails}
            setHideFilters={setHideFilters}
            isDetailsPanelExpand={isDetailsPanelExpand}
            setIsDetailsPanelExpand={setIsDetailsPanelExpand}
            specialistEditMode={specialistEditMode}
          />
        </Card>
      </div>
      {/* Details panel */}
      <div className={"detailsPanel".concat(hideDetails ? " hide" : "")}>
        <Card className="threePanelTabCard">
          <MessageDetailsPanel
            isDetailsPanelExpand={isDetailsPanelExpand}
            setIsDetailsPanelExpand={setIsDetailsPanelExpand}
            specialistEditMode={specialistEditMode}
            setSpecialistEditMode={setSpecialistEditMode}
          />
        </Card>
      </div>
    </div>
  );
};
