import React, { Fragment, useContext, useState, useLayoutEffect } from 'react';
import CardContent from "@mui/material/CardContent";
import { useTheme } from "@mui/material/styles";
import { ReviewFiltersContext } from 'orca/contexts/ReviewFiltersContext';
import { ReviewDataContext } from "orca/contexts/ReviewDataContext";
import { ReviewTable } from 'orca/components/ReviewTab/ReviewTable';

const columns = [
  {
    key: 'ata',
    label: 'ATA',
    minWidth: 50,
    sortable: true,
  },
  {
    key: 'ac_sn',
    label: 'Serial',
    minWidth: 50,
    sortable: true,
  },
  {
    key: 'ac_tn',
    label: 'Tail',
    minWidth: 50,
    sortable: true,
  },
  {
    key: 'message_type',
    label: 'Report Type',
    minWidth: 60,
    sortable: true,
  },
  {
    key: 'discrepancy',
    label: 'Discrepancy',
    minWidth: 150,
    sortable: false,
  },
  {
    key: 'corrective_action',
    label: 'Corrective Action',
    minWidth: 150,
    sortable: false,
  },
  {
    key: 'reported_date',
    label: 'Reported Date',
    minWidth: 80,
    sortable: true,
  },
  {
    key: 'resolved_date',
    label: 'Resolved Date',
    minWidth: 80,
    sortable: true,
  },
  {
    key: 'remove_closed_date_user',
    label: 'Removed/Closed Date',
    minWidth: 80,
    sortable: true,
  },
  {
    key: 'mdc_linkage',
    label: 'MDC Linkage',
    minWidth: 100,
    sortable: false,
  },
  {
    key: 'orca_status',
    label: 'Status',
    minWidth: 50,
    sortable: true,
  }
];

export const RemoveTablePanel = (props) => {
  const theme = useTheme();
  const styles = ({
    tableTitleBar: {
      color: theme.palette.text.title2,
      fontSize: 18,
      borderBottom: "1px solid " + theme.palette.default.main,
      backgroundColor: theme.palette.secondary.main,
      height: "30px",
      padding: "0 0 0 0",
    },
    tableTitleBarTitle: {
      color: theme.palette.text.title2,
      paddingTop: "1px",
      textAlign: "center",
      width: "100%",
    },
  })

  const {
    reviewTableState,
    setReviewTableState,
    setIsLoading,
    error,
    setError,
  } = useContext(ReviewDataContext);

  const {
    topRowsPerPage,
    setTopRowsPerPage,
    topCurTablePage,
    setTopCurTablePage,
    topSelectedRowId,
    setTopSelectedRowId,
    topSelectedRows,
    setTopSelectedRows,
    sortDirectionTop,
    setSortDirectionTop,
    sortColTop,
    setSortColTop,
  } = useContext(ReviewFiltersContext);

  const [displayedTableData, setDisplayedTableData] = useState([]);

  useLayoutEffect(() => {
    setDisplayedTableData(
      reviewTableState?.data
      .map(({ original_ata, ...rest }) => {
        if (rest.orca_status === 'R' || rest.orca_status === 'C') {
          return {
            ...rest,
            orca_status: rest.orca_status === 'R' ? 'Removed' : 'Closed'
          };
        }
        // return null if the item does not match the filter criteria
        return null;
      })
      .filter(row => row !== null) // remove the null values
    );
  }, [reviewTableState])

  return (
    <Fragment>
      <CardContent sx={styles.tableTitleBar}>
        <div style={styles.tableTitleBarTitle}>Removed and Closed</div>
      </CardContent>

      <ReviewTable
        rowKey="defect_id"
        tableName="remove"
        columns={columns}
        tableData={{
          ...reviewTableState,
          data: displayedTableData,
        }}
        setTableData={setDisplayedTableData}
        setReviewTableState={setReviewTableState}
        error={error}
        setError={setError}
        setIsLoading={setIsLoading}
        rowsPerPage={topRowsPerPage}
        setRowsPerPage={setTopRowsPerPage}
        curTablePage={topCurTablePage}
        setCurTablePage={setTopCurTablePage}
        selectedRowIndex={topSelectedRowId}
        setSelectedRowIndex={setTopSelectedRowId}
        selectedRows={topSelectedRows}
        setSelectedRows={setTopSelectedRows}
        sortDirection={sortDirectionTop}
        setSortDirection={setSortDirectionTop}
        sortCol={sortColTop}
        setSortCol={setSortColTop}
      >
      </ReviewTable>
    </Fragment>
  );
};
