/* eslint-disable max-len */
import React, { Fragment } from "react";
import IconButton from "@mui/material/IconButton";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import Tooltip from "@mui/material/Tooltip";
import CardContent from "@mui/material/CardContent";
import { useTheme } from "@mui/material/styles";


/* eslint-enable max-len */
export const TablePanel = (props) => {
  const {
    title,
    hideFilters,
    setHideDetails,
    children,
    hideDetails,
    setHideFilters,
    headerButtons,
  } = props;
  const theme = useTheme();
  const styles = ({
    tableTitleBar: {
      color: theme.palette.text.title2,
      fontSize: 18,
      borderBottom: "1px solid " + theme.palette.default.main,
      backgroundColor: theme.palette.secondary.main,
      minHeight: "22px",
      height: "5%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "0 0 0 0",
    },
    hideIconButtonLeft: {
      padding: "0 0 0 0",
      margin: "0 70px 0 10px",
    },
    hideIconButtonRight: {
      padding: "0 0 0 0",
      margin: "0 10px 0 0",
    },
    hideButton: {
      width: "20px",
      height: "20px",
      fill: theme.palette.primary.dark,
    },
    tableTitleBarTitle: {
      color: theme.palette.text.title2,
      textAlign: "center",
      width: "100%",
    },
    tableButtonsContainer: {
      display: "flex",
      flexDirection: "row",
      position: "relative",
      marginLeft: "auto",
    },
  });

  return (
    <Fragment>
      {/* Title bar */}
      <CardContent sx={styles.tableTitleBar}>
        <Tooltip title="Hide/Show Filters Panel">
          <IconButton
            sx={styles.hideIconButtonLeft}
            onClick={() => setHideFilters(!hideFilters)}
            size="large">
            {hideFilters ? (
              <ArrowForwardIosRoundedIcon sx={styles.hideButton} />
            ) : (
              <ArrowBackIosRoundedIcon sx={styles.hideButton} />
            )}
          </IconButton>
        </Tooltip>
        <div style={styles.tableTitleBarTitle}>{title}</div>
        <div style={styles.tableButtonsContainer}>
          {headerButtons}

          <Tooltip title="Hide/Show Message Details Panel">
            <IconButton
              sx={styles.hideIconButtonRight}
              onClick={() => setHideDetails(!hideDetails)}
              size="large">
              {hideDetails ? (
                <ArrowBackIosRoundedIcon sx={styles.hideButton} />
              ) : (
                <ArrowForwardIosRoundedIcon sx={styles.hideButton} />
              )}
            </IconButton>
          </Tooltip>
        </div>
      </CardContent>
      {/* Table */}
      <Fragment>{children}</Fragment>
    </Fragment>
  );
};
