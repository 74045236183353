import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { Grid, Box } from "@mui/material";
import Modal from "@mui/material/Modal";
import Slide from "@mui/material/Slide";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import NotesIcon from "@mui/icons-material/Notes";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { DefaultText } from "mdp/components/Miscellaneous/DefaultText";
import { apiFetcher } from "utils/apiUtils";
import { config } from "config/config";

export const NotesPanel = (props) => {
  /*
  Component representing note panel popup on MDC Messages table
  Allows user to view/add notes for a selected mdc message

  @param <messageID:string>: mdc message id of the selected row in the
  MDC messages table
  @param <setMessageID>: setter for messageID
  @param <isPanelOpen:bool>: whether to show the message notes panel
  @param <setIsPanelOpen>: setter for isPanelOpen
  */
  const {
    setIsPanelOpen,
    tableData,
    setMessageID,
    setTableData,
    isPanelOpen,
    messageID,
  } = props;

  const height = window.innerHeight;
  const theme = useTheme();
  const styles = ({
    title: {
      color: theme.palette.text.title2,
      fontSize: 18,
      borderBottom: `1px solid ${theme.palette.default.main}`,
      backgroundColor: theme.palette.secondary.main,
      height: "35px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "0 0 0 0",
    },
    exitButton: {
      position: "relative",
      marginLeft: "auto",
      marginRight: "5px",
    },
    spinnerAndText: {
      marginTop: "40%",
      marginBottom: "auto",
      marginLeft: "45%",
      marginRight: "auto",
    },
    detailsPanelTitle: {
      position: "absolute",
    },
    divider: {
      height: "100%",
      margin: "0 5px 0 0",
    },
    buttonIcons: {
      width: "25px",
      height: "25px",
      fill: theme.palette.primary.dark,
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    card: {
      display: "flex",
      flexDirection: "column",
      width: height > 905 ? "40%" : "65%",
      height: "75%",
    },
    textPanel: {
      padding: theme.spacing(2),
      paddingLeft: theme.spacing(1),
      textAlign: "center",
      color: theme.palette.text.secondary,
      height: "63vh",
    },
    historyPanel: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      padding: theme.spacing(2),
      paddingRight: "0px",
      textAlign: "center",
      color: theme.palette.text.secondary,
      height: "62vh",
    },
    historyList: {
      "&:hover": {
        background: theme.palette.secondary.main,
        cursor: "pointer",
      },
    },
    addNewNoteButton: {
      "&:hover": {
        cursor: "pointer",
      },
      paddingBottom: "10px",
    },
    confirmationPanel: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: height > 905 ? "center" : "flex-start",
      width: "95%",
      height: "60px",
      margin: "0 auto 0 auto",
    },
  });
  const [isNotesLoading, setIsNotesLoading] = useState(null);
  const [notesData, setNotesData] = useState(null);
  const [notesError, setNotesError] = useState(null);
  const [selectedNote, setSelectedNote] = useState(null);
  const [userInputText, setUserInputText] = useState("");

  const getNotesData = () => {
    setIsNotesLoading(true);
    apiFetcher(`${config.apiURL}/mdp/mdcmessages/${messageID}/notes`, "GET")
      .then((response) => {
        setNotesData(response.data);
        setIsNotesLoading(false);
      })
      .catch((e) => {
        setIsNotesLoading(false);
        setNotesError(e);
      });
  };

  const cancel = () => {
    setIsNotesLoading(null);
    setUserInputText("");
    setSelectedNote(null);
    setNotesError(null);
    setIsPanelOpen(false);
  };

  const saveNotesData = () => {
    setIsNotesLoading(true);
    apiFetcher(
      `${config.apiURL}/mdp/mdcmessages/${messageID}/notes`,
      "POST",
      { noteText: userInputText },
      { "Content-Type": "application/json" }
    )
      .then((response) => {
        const modifiedTableData = tableData.data.map((row) => {
          if (row.MSG_ID === messageID) {
            return {
              ...row,
              EVENT_NOTE: "1",
            };
          }
          return row;
        });

        setTableData({ ...tableData, data: modifiedTableData });
        setUserInputText("");
        setSelectedNote(null);
        getNotesData();
      })
      .catch((e) => {
        setIsNotesLoading(false);
        setUserInputText("");
        setSelectedNote(null);
        setNotesError(e);
      });
  };

  const deleteNote = () => {
    setIsNotesLoading(true);
    apiFetcher(
      `${config.apiURL}/mdp/mdcmessages/${messageID}/notes`,
      "DELETE",
      { noteid: selectedNote.NOTE_ID },
      { "Content-Type": "application/json" }
    )
      .then((response) => {
        if (notesData.length <= 1) {
          apiFetcher(
            `${config.apiURL}/mdp/mdcmessages/${messageID}/notes`,
            "PATCH",
            null,
            {
              "Content-Type": "application/json",
            }
          ).then((response) => {
            const modifiedTableData = tableData.data.map((row) => {
              if (row.MSG_ID === messageID) {
                return {
                  ...row,
                  EVENT_NOTE: "0",
                };
              }
              return row;
            });
            setTableData({ ...tableData, data: modifiedTableData });
          });
        }
        setUserInputText("");
        setSelectedNote(null);
        getNotesData();
      })
      .catch((e) => {
        setIsNotesLoading(false);
        setUserInputText("");
        setSelectedNote(null);
        setNotesError(e);
      });
  };

  useEffect(() => {
    if (messageID) {
      getNotesData();
    }
  }, [messageID]);

  return (
    <Modal
      sx={styles.modal}
      closeAfterTransition
      open={isPanelOpen}
      onClose={() => {
        setIsPanelOpen(false);
        setMessageID(null);
        setUserInputText("");
        setSelectedNote(null);
      }}
      BackdropProps={{
        timeout: 600,
      }}
    >
      <Slide in={isPanelOpen} direction="down" mountOnEnter unmountOnExit>
        <Card elevation={3} sx={styles.card}>
          {/* Title bar */}
          <CardContent sx={styles.title}>
            <div style={styles.detailsPanelTitle}>Message Notes</div>
            <div style={styles.exitButton}>
              <IconButton onClick={cancel} size="large">
                <CloseIcon />
              </IconButton>
            </div>
          </CardContent>
          {/* Panel Content */}
          {/* If there's an error fetching the data */}
          {notesError && (
            <div style={styles.spinnerAndText}>
              <DefaultText text={notesError.toString()} />
            </div>
          )}
          {/* If data is loading */}
          {isNotesLoading && (
            <div style={styles.spinnerAndText}>
              <CircularProgress color="primary" />
            </div>
          )}
          {/* once data has loaded and no errors */}
          {!isNotesLoading && !notesError && notesData && (
            <Grid
              container
              spacing={0}
              justifyContent="center"
              alignItems="center"
              style={{ overflow: "hidden" }}
            >
              <Grid item xs={4} style={{ overflowY: "auto" }}>
                {/* history panel */}
                <div style={styles.historyPanel}>
                  <List dense={true}>
                    {/* Add new note button */}
                    <ListItem
                      divider
                      sx={styles.addNewNoteButton}
                      onClick={() => setSelectedNote(null)}
                    >
                      <ListItemIcon>
                        <NoteAddIcon />
                      </ListItemIcon>
                      <ListItemText primary="Add New Note" />
                    </ListItem>
                    {/* note history list */}
                    {notesData.map((note, index) => (
                      <ListItem
                        key={`${note}: ${index}`}
                        divider
                        sx={styles.historyList}
                        onClick={() => setSelectedNote(note)}
                        selected={note.EDIT_DATE === selectedNote?.EDIT_DATE}
                      >
                        <ListItemIcon>
                          <NotesIcon />
                        </ListItemIcon>
                        <ListItemText primary={note.EDIT_DATE} />
                      </ListItem>
                    ))}
                  </List>
                  <Divider sx={styles.divider} orientation="vertical" />
                </div>
              </Grid>

              {/* Text box panel */}
              <Grid item xs={8}>
                <div style={styles.textPanel}>
                  <TextField
                    placeholder="Add a new note"
                    multiline
                    disabled={selectedNote}
                    variant="outlined"
                    fullWidth={true}
                    maxRows={height > 905 ? 23 : 18}
                    value={selectedNote ? selectedNote.NOTES : userInputText}
                    onChange={(e) => setUserInputText(e.target.value)}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div style={styles.confirmationPanel}>
                  <Box m={1} pt={0}>
                    <Button
                      sx={{ px: 20 }}
                      disabled={isNotesLoading || userInputText === ""}
                      variant="outlined"
                      color="primary"
                      onClick={saveNotesData}
                    >
                      Save
                    </Button>
                  </Box>
                  <Box m={1} pt={0}>
                    <Button
                      sx={{ px: 20 }}
                      disabled={isNotesLoading || !selectedNote}
                      variant="outlined"
                      color="primary"
                      onClick={deleteNote}
                    >
                      Delete
                    </Button>
                  </Box>
                </div>
              </Grid>
            </Grid>
          )}
        </Card>
      </Slide>
    </Modal>
  );
};
