/* eslint-disable max-len */
import React, { Fragment, useState, useEffect, useContext } from "react";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from '@mui/material/Alert';

import { DetailsPanelDetailsTab } from "mdp/components/MessagesTab/MessageDetails/Details/DetailsPanelDetailsTab";
import { DetailsPanelDataTab } from "mdp/components/MessagesTab/MessageDetails/Data/DetailsPanelDataTab";
import { DetailsPanelLogicTab } from "mdp/components/MessagesTab/MessageDetails/Logic/DetailsPanelLogicTab";
import { DetailsPanelSpecialistInputTab } from "mdp/components/MessagesTab/MessageDetails/SpecialistInput/DetailsPanelSpecialistInputTab";
// import { DetailsPanelMmelTab } from "mdp/components/MessagesTab/MessageDetails/MMEL/DetailsPanelMmelTab";
import { DetailsPanelFieldNotesTab } from "mdp/components/MessagesTab/MessageDetails/FieldNotes/DetailsPanelFieldNotesTb";
import { MessageDetailsExpanded } from "mdp/components/MessagesTab/MessageDetailsExpanded";
import { DetailsPanel } from "mdp/components/Miscellaneous/DetailsPanel";
import { apiFetcher } from "utils/apiUtils";
import { DetailsPanelFimLinks } from "mdp/components/MessagesTab/MessageDetails/FIM/DetailsPanelFimLinks";
import { DetailsPanelSurroundingMessagesTab } from "mdp/components/MessagesTab/MessageDetails/SurroundingMessages/DetailsPanelSurroundingMessagesTab";

import { MessageFiltersContext } from "mdp/contexts/MessageFiltersContext";
import { config } from "config/config";
import "mdp/components/MessagesTab/MessageDetails/MessageDetailsPanel.css";
import { Button, Box } from "@mui/material";
import { EmailPopup } from "mdp/components/MessagesTab/EmailPopup";
import { reformatMsgDates } from "mdp/components/MessagesTab/MessagesTable/MessageTablePanel";
/* eslint-enable max-len */

export const MessageDetailsPanel = (props) => {
  const {
    setSpecialistEditMode,
    specialistEditMode,
    isDetailsPanelExpand,
    setIsDetailsPanelExpand,
  } = props;
  const styles = ({
    accordianRoot: {
      boxShadow: "none",
    },
    accordianLabel: {
      fontSize: 16,
    },
  });

  const [binHex, setBinHex] = useState({ binHex: "Bin" });
  // message details data from backend
  const [messageDetails, setMessageDetails] = useState(null);
  const [isMessageDetailsLoading, setIsMessageDetailsLoading] = useState(false);
  const [messageDetailsError, setMessageDetailsError] = useState(null);
  // equation ID that user has selected
  const [selectedEQID, setSelectedEQID] = useState(null);
  // row that user selected in mdc messages table
  const { selectedRow, messageFilterState, defaultMessageFilterState } =
    useContext(MessageFiltersContext);

  const [surroundingMessages, setSurroundingMessages] = useState(null);
  const [isSurroundingMessagesLoading, setIsSurroundingMessagesLoading] =
    useState(false);
  const [surroundingMessagesError, setSurroundingMessagesError] =
    useState(null);

  const [panelState, setPanelState] = useState(null);

  const [isEmailPopupExpanded, setIsEmailPopupExpanded] = useState(false);
  const [userEmail, setUserEmail] = useState("");

  useEffect(() => {
    const oktaStorage = JSON.parse(localStorage.getItem("okta-token-storage"));
    if (oktaStorage.idToken.claims.email) {
      setUserEmail(oktaStorage.idToken.claims.email);
      console.log(userEmail);
    }
  }, []);

  // const [showValidationPopup, setShowValidationPopup] = useState({
  // show: false,
  // type: "error",
  // message: "",
  // });

  const [showSuccess, setShowSuccess] = useState(false);

  const handleClosePopup = (event, reason, setPopupState) => {
    if (reason === "clickaway") {
      return;
    }
    setPopupState(false);
  };

  const getMessageDetails = () => {
    setIsMessageDetailsLoading(true);
    setMessageDetailsError(null);
    setMessageDetails(null);
    apiFetcher(
      `${config.apiURL}/mdp/mdcmessages/${selectedRow.MSG_ID}/details`,
      "GET"
    )
      .then((response) => {
        setMessageDetails({
          ...response.data,
          IS_SPECIALIST: response.data.IS_SPECIALIST === "True",
        });
        // by default select the first equation id
        setSelectedEQID(
          response.data.EQ_INFO[0] ? response.data.EQ_INFO[0].EQ_ID : null
        );
        setIsMessageDetailsLoading(false);
      })
      .catch((e) => {
        setMessageDetailsError(e);
        setIsMessageDetailsLoading(false);
      });
  };

  useEffect(() => {
    if (messageDetails && selectedRow) {
      setPanelState({
        ...selectedRow,
        ...(messageDetails || {}),
      });
    }
  }, [messageDetails, selectedRow]);

  useEffect(() => {
    if (panelState) {
      getSurroundingMessages();
    }
  }, [panelState]);

  const getSurroundingDates = (date) => {
    // converting to date so when minutes are added they can go to the next day.
    const dateObj = new Date(date);
    const startDateObj = new Date(dateObj.getTime() - 3 * 60000);
    const endDateObj = new Date(dateObj.getTime() + 3 * 60000);

    const startYear = startDateObj.getFullYear();
    const startMonth = (startDateObj.getMonth() + 1)
      .toString()
      .padStart(2, "0");
    const startDay = startDateObj.getDate().toString().padStart(2, "0");
    const startHour = startDateObj.getHours().toString().padStart(2, "0");
    const startMinute = startDateObj.getMinutes().toString().padStart(2, "0");

    const endYear = endDateObj.getFullYear();
    const endMonth = (endDateObj.getMonth() + 1).toString().padStart(2, "0");
    const endDay = endDateObj.getDate().toString().padStart(2, "0");
    const endHour = endDateObj.getHours().toString().padStart(2, "0");
    const endMinute = endDateObj.getMinutes().toString().padStart(2, "0");

    const startDate = `${startYear}-${startMonth}-${startDay}`;
    const endDate = `${endYear}-${endMonth}-${endDay}`;
    const startTime = `${startHour}:${startMinute}`;
    const endTime = `${endHour}:${endMinute}`;

    return { startDate, startTime, endDate, endTime };
  };

  const getSurroundingMessages = () => {
    const dateFilters = getSurroundingDates(panelState.MSG_Date);

    const payload = {
      ...defaultMessageFilterState,
      acsn: [panelState.AC_SN],
      acModel: panelState.AC_MODEL,
      startDate: dateFilters.startDate,
      startTime: dateFilters.startTime,
      endDate: dateFilters.endDate,
      endTime: dateFilters.endTime,
      endDateOffset: "0",
    };

    setIsSurroundingMessagesLoading(true);
    setSurroundingMessagesError(null);
    setSurroundingMessages(null);
    apiFetcher(
      `${config.apiURL}/mdp/mdcmessages?offset=0&limit=500`,
      "POST",
      payload,
      { "Content-Type": "application/json" }
    )
      .then((response) => {
        setSurroundingMessages(response.data);
        setIsSurroundingMessagesLoading(false);
      })
      .catch((e) => {
        setSurroundingMessagesError(e);
        setIsSurroundingMessagesLoading(false);
      });
  };

  // const sendEmail = async () => {
  //  setIsEmailLoading(true);
  //  apiFetcher(`${config.apiURL}/mdp/sendemail/${selectedRow.MSG_ID}`,
  //   "POST", selectedRow, {
  //   "Content-Type": "application/json",
  //  })
  //   .then((response) => {
  //    setIsEmailLoading(false);
  //    setShowSuccess(true);
  //   })
  //   .catch((e) => {
  //    setIsEmailLoading(false);
  //    console.log(e)
  //   });
  // };

  useEffect(() => {
    if (selectedRow) {
      getMessageDetails();
    }
  }, [selectedRow]);

  return (
    <>
      <Fragment>
        <DetailsPanel
          title="Message Details"
          setIsDetailsPanelExpand={setIsDetailsPanelExpand}
          specialistEditMode={specialistEditMode}
          isDetailsPanelExpand={isDetailsPanelExpand}
        >
          <Accordion sx={styles.accordianRoot}>
            <AccordionSummary
              sx={styles.accordianLabel}
              expandIcon={<ExpandMoreIcon color="primary" />}
            >
              Details
            </AccordionSummary>
            <AccordionDetails>
              <DetailsPanelDetailsTab
                panelState={{
                  ...selectedRow,
                  ...(messageDetails || {}),
                }}
                selectedEQID={selectedEQID}
                setSelectedEQID={setSelectedEQID}
                isMessageDetailsLoading={isMessageDetailsLoading}
                messageDetailsError={messageDetailsError}
              />
            </AccordionDetails>
          </Accordion>

          <Accordion sx={styles.accordianRoot}>
            <AccordionSummary
              sx={styles.accordianLabel}
              expandIcon={<ExpandMoreIcon color="primary" />}
            >
              Data
            </AccordionSummary>
            <AccordionDetails>
              <DetailsPanelDataTab
                panelState={{
                  ...selectedRow,
                  ...(messageDetails || {}),
                }}
                binHex={binHex}
                setBinHex={setBinHex}
                isMessageDetailsLoading={isMessageDetailsLoading}
                messageDetailsError={messageDetailsError}
              />
            </AccordionDetails>
          </Accordion>

          <Accordion sx={styles.accordianRoot}>
            <AccordionSummary
              sx={styles.accordianLabel}
              expandIcon={<ExpandMoreIcon color="primary" />}
            >
              Logic
            </AccordionSummary>
            <AccordionDetails>
              <DetailsPanelLogicTab
                panelState={{
                  ...selectedRow,
                  ...(messageDetails || {}),
                }}
                selectedEQID={selectedEQID}
                isMessageDetailsLoading={isMessageDetailsLoading}
                messageDetailsError={messageDetailsError}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion sx={styles.accordianRoot}>
            <AccordionSummary
              sx={styles.accordianLabel}
              expandIcon={<ExpandMoreIcon color="primary" />}
            >
              Fault Isolation Manuals
            </AccordionSummary>
            <AccordionDetails>
              <DetailsPanelFimLinks
                panelState={{
                  ...selectedRow,
                  ...(messageDetails || {}),
                }}
                selectedEQID={selectedEQID}
                isMessageDetailsLoading={isMessageDetailsLoading}
                messageDetailsError={messageDetailsError}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion sx={styles.accordianRoot}>
            <AccordionSummary
              sx={styles.accordianLabel}
              expandIcon={<ExpandMoreIcon color="primary" />}
            >
              Specialist Input
            </AccordionSummary>
            <AccordionDetails>
              <DetailsPanelSpecialistInputTab
                panelState={{
                  ...selectedRow,
                  ...(messageDetails || {}),
                }}
                selectedEQID={selectedEQID}
                specialistEditMode={specialistEditMode}
                setSpecialistEditMode={setSpecialistEditMode}
                isMessageDetailsLoading={isMessageDetailsLoading}
                messageDetailsError={messageDetailsError}
              />
            </AccordionDetails>
          </Accordion>
          {/* <Accordion sx={styles.accordianRoot}>
            <AccordionSummary
              sx={styles.accordianLabel}
              expandIcon={<ExpandMoreIcon color="primary" />}
            >
              MMEL
            </AccordionSummary>
            <AccordionDetails>
              <DetailsPanelMmelTab
                panelState={{
                  ...selectedRow,
                  ...(messageDetails || {}),
                }}
                setSelectedEQID={setSelectedEQID}
                selectedEQID={selectedEQID}
                isMessageDetailsLoading={isMessageDetailsLoading}
                messageDetailsError={messageDetailsError}
                specialistEditMode={specialistEditMode}
                setSpecialistEditMode={setSpecialistEditMode}
                isDetailsPanelExpand={isDetailsPanelExpand}
              />
            </AccordionDetails>
          </Accordion> */}
          <Accordion sx={styles.accordianRoot}>
            <AccordionSummary
              sx={styles.accordianLabel}
              expandIcon={<ExpandMoreIcon color="primary" />}
            >
              Field Notes
            </AccordionSummary>
            <AccordionDetails>
              <DetailsPanelFieldNotesTab
                panelState={{
                  ...selectedRow,
                  ...(messageDetails || {}),
                }}
                selectedEQID={selectedEQID}
                isMessageDetailsLoading={isMessageDetailsLoading}
                messageDetailsError={messageDetailsError}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion sx={styles.accordianRoot}>
            <AccordionSummary
              sx={styles.accordianLabel}
              expandIcon={<ExpandMoreIcon color="primary" />}
            >
              Surrounding Messages
            </AccordionSummary>
            <AccordionDetails>
              <DetailsPanelSurroundingMessagesTab
                panelState={{
                  ...selectedRow,
                  ...(messageDetails || {}),
                }}
                selectedRow={selectedRow}
                specialistEditMode={specialistEditMode}
                isSurroundingMessagesLoading={isSurroundingMessagesLoading}
                surroundingMessagesError={surroundingMessagesError}
                surroundingMessages={
                  reformatMsgDates(messageFilterState.dateFormat, {
                    data: surroundingMessages,
                  }).data
                }
              />
            </AccordionDetails>
          </Accordion>
          {selectedRow && userEmail !== "" && (
            <Box m={1} pt={10}>
              <Button
                sx={{ px: 20, padding: '5px 15px' }}
                variant="outlined"
                color="primary"
                onClick={() => setIsEmailPopupExpanded(true)}
              >
                Send Alert to MHIRJ CRC
              </Button>
            </Box>
          )}
        </DetailsPanel>
        <MessageDetailsExpanded
          panelState={{
            ...selectedRow,
            ...(messageDetails || {}),
          }}
          selectedRow={selectedRow}
          selectedEQID={selectedEQID}
          setSelectedEQID={setSelectedEQID}
          isMessageDetailsLoading={isMessageDetailsLoading}
          messageDetailsError={messageDetailsError}
          binHex={binHex}
          setBinHex={setBinHex}
          isDetailsPanelExpand={isDetailsPanelExpand}
          setIsDetailsPanelExpand={setIsDetailsPanelExpand}
          surroundingMessages={
            reformatMsgDates(messageFilterState.dateFormat, {
              data: surroundingMessages,
            }).data
          }
          isSurroundingMessagesLoading={isSurroundingMessagesLoading}
          surroundingMessagesError={surroundingMessagesError}
          specialistEditMode={specialistEditMode}
          setSpecialistEditMode={setSpecialistEditMode}
          userEmail={userEmail}
          setIsEmailPopupExpanded={setIsEmailPopupExpanded}
        />
        <EmailPopup
          isDetailsPanelExpand={isEmailPopupExpanded}
          setIsDetailsPanelExpand={setIsEmailPopupExpanded}
          panelState={{
            ...selectedRow,
            ...(messageDetails || {}),
          }}
          binHex={binHex}
          setBinHex={setBinHex}
          selectedEQID={selectedEQID}
          setSelectedEQID={setSelectedEQID}
          userEmail={userEmail}
        />
      </Fragment>
      <Snackbar
        open={showSuccess}
        autoHideDuration={5000}
        onClose={(event, reason) =>
          handleClosePopup(event, reason, setShowSuccess)
        }
      >
        <MuiAlert
          onClose={(event, reason) =>
            handleClosePopup(event, reason, setShowSuccess)
          }
          severity="success"
          elevation={6}
          variant="filled"
        >
          Email sent successfully!
        </MuiAlert>
      </Snackbar>
    </>
  );
};
