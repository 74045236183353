import React, { Fragment } from 'react';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import { DefaultText } from 'mdp/components/Miscellaneous/DefaultText';
import { apiFetcher } from 'utils/apiUtils';
import Tooltip from '@mui/material/Tooltip';

export const FlightDeckEffectsTable = (props) => {
  /*
  Component representing main tables used in the application

  @props <url:string>: backend url to query rows from
  @props <columns:list>: list of columns in the table e.g.
  [{key: "AC_SN",label: "Serial Number", minWidth: 50,}]
  @props <rowKey:string>: column name that acts as a unique identifier for
  each row e.g. MDC messages data from the backend has a MSG_ID column
  @props <tableData:list>: state that holds the table data
  @props <setTableData: setter for tableData state
  @props isLoading: state that represents if a query is running
  @props setIsLoading: setter for isLoading sate
  @props error: state that holds errors when fetching data from backend
  @props setError: setter for error state
  @props appliedFilters: state stores copy of the filters that were applied
  @props rowsPerPage: state stores number or rows per table page
  @props setRowsPerPage: setter for rowsPerPage state
  @props curTablePage: state stores current table page user is on
  @props setCurTablePage: setter for curTablePage state
  @props selectedRows: state stores row that user has currently selected
  @props setSelectedRows: setter for selectedRow state
  @props setMimicApplyClick: state setter for whether to trigger a mimicked
  "Apply" button click
  @props <flightDeckEffectsFilterState:object>: state of the filters panel
  @props <setFlightDeckEffectsFilterState>: set flightDeckEffectsFilterState
  @props <formatFilters:fucntion>: takes in filterState and mutates it. Used to
  make some sort of change to the filterState before it is sent to the backend
  */
  const {
    rowsPerPage,
    url,
    setSelectedRow,
    isLoading,
    setTableData,
    selectedRow,
    columns,
    rowKey,
    setFlightDeckEffectsFilterState,
    error,
    setRowsPerPage,
    appliedFilters,
    setIsLoading,
    formatFilters,
    setMimicApplyClick,
    curTablePage,
    setError,
    flightDeckEffectsFilterState,
    setCurTablePage,
    tableData,
  } = props;

  const theme = useTheme();
  const styles = ({
    tableHeader: {
      color: theme.palette.primary.main,
      backgroundColor: 'white',
      fontWeight: 'bold',
    },
    spinnerAndText: {
      marginTop: '30%',
      marginBottom: 'auto',
    },
    container: {
      height: '88%',
      scrollbarWidth: 'thin',
    },
    tableRow: {
      '&$selected, &$selected:hover': {
        backgroundColor: theme.palette.secondary.main,
      },
    },
    headerCell: {
      padding: '6px 0 6px 8px',
    },
    tableCell: {
      fontSize: 11,
      padding: '6px 0 6px 8px',
    },
    selected: {},
  });

  const handleChangePage = (event, newPage) => {
    /*
    Changes the page in the FDE & Sensory table

    @param event: dummy variable
    @param newPage: integer containing the new page number the user clicked
    */
    // if there isn't enough cached data to show the next page then
    // request more rows from the backend and append them to the table state
    if (newPage * rowsPerPage >= tableData.data.length) {
      setIsLoading(true);
      // if formatFilters prop is provided then format the filters parameters
      // before sending the request to the backend
      let payload;
      if (formatFilters) {
        payload = formatFilters(appliedFilters);
      } else {
        payload = appliedFilters;
      }
      apiFetcher(
          `${url}?offset=${tableData.data.length}&limit=${rowsPerPage}`,
          'POST',
          payload,
          { 'Content-Type': 'application/json' },
      )
          .then((response) => {
            setTableData({
              ...tableData,
              data: [...tableData.data, ...response.data],
            });
            setCurTablePage(newPage);
            setIsLoading(false);
          })
          .catch((e) => {
            setError(e);
          });
    } else {
      setCurTablePage(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    /*
    Changes the number of rows to show per page

    @param event: string containg the number of rows per page set by the user
    */

    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage); // update rows per page
    setCurTablePage(0); // reset to page 0
    // if there isnt enough cached data to show the first page then request
    // it from the backend append them to the table state
    if (newRowsPerPage > tableData.data.length) {
      setIsLoading(true);
      // if formatFilters prop is provided then format the filters parameters
      // before sending the request to the backend
      let payload;
      if (formatFilters) {
        payload = formatFilters(appliedFilters);
      } else {
        payload = appliedFilters;
      }
      apiFetcher(
          `${url}?offset=${tableData.data.length}&limit=${rowsPerPage}`,
          'POST',
          payload,
          { 'Content-Type': 'application/json' },
      )
          .then((response) => {
            setTableData({
              ...tableData,
              data: [...tableData.data, ...response.data],
            });

            setIsLoading(false);
          })
          .catch((e) => {
            setError(e);
          });
    }
  };

  const handleRowClick = (row) => {
    setSelectedRow(row);
  };

  return (
    <Fragment>
      {/* If there's an error fetching the data */}
      {error && (
        <div style={styles.spinnerAndText}>
          <DefaultText text={error.toString()} />
        </div>
      )}
      {/* default message to show if the user hasnt made a query */}
      {!error && tableData.data === null && !isLoading && (
        <div style={styles.spinnerAndText}>
          <DefaultText text="Apply some filters" />
        </div>
      )}
      {/* if the query is complete or query in progress show the table */}
      {!error && (tableData.data?.length || isLoading) && (
        <Fragment>
          <TableContainer sx={styles.container}>
            {/* if query in progress show loading spinner */}
            {isLoading && (
              <div style={styles.spinnerAndText}>
                <CircularProgress color="primary" />
              </div>
            )}
            {/* if the query completed but the table is empty */}
            {!isLoading && tableData.data?.length === 0 && (
              <div style={styles.spinnerAndText}>
                <DefaultText text="No records found" />
              </div>
            )}
            {/* if query completed and table is not empty */}
            {!isLoading && tableData.data?.length ? (
              <Table stickyHeader size="small">
                {/* Column headers of the table */}
                <TableHead>
                  <TableRow key="headers">
                    {columns.map((column) => (
                      <TableCell
                        sx={styles.headerCell}
                        key={column.key}
                        title={column.tooltip}
                        align={column.align}
                        style={{
                          ...styles.tableHeader,
                          minWidth: column.minWidth,
                        }}
                      >
                        {/* for sortable columns render sorting icons. if the
                        user clicks on a column header to sort it then update
                        orderbyCol and orderbyDir in the message filters
                        context. This will trigger a new search to the backend
                        essentially mimicking the user clicking the "apply"
                        button */}
                        {column.sortable ? (
                          <TableSortLabel
                            active={
                              flightDeckEffectsFilterState.orderbyCol === column.key
                            }
                            direction={flightDeckEffectsFilterState.orderbyDir}
                            onClick={() => {
                              setFlightDeckEffectsFilterState({
                                ...flightDeckEffectsFilterState,
                                orderbyCol: column.key,
                                orderbyDir:
                                  flightDeckEffectsFilterState.orderbyDir === 'asc'
                                    ? 'desc'
                                    : 'asc',
                              });
                              setMimicApplyClick(true);
                            }}
                          >
                            {column.label}
                          </TableSortLabel>
                        ) : (
                          column.label
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                {/* main body of the table */}
                <TableBody>
                  {/* render a table row for each MDC message based on number of
                  message per page and the current page number */}
                  {tableData.data
                      .slice(
                          curTablePage * rowsPerPage,
                          curTablePage * rowsPerPage + rowsPerPage,
                      )
                      .map((row, index) => {
                        let colour = 'white';
                        if (
                          selectedRow &&
                        selectedRow.FLIGHT_DECK_EVENT === row.FLIGHT_DECK_EVENT
                        ) {
                          colour = 'lightgrey';
                        }
                        return (
                          <TableRow
                            style={{ backgroundColor: colour }}
                            hover
                            tabIndex={-1}
                            key={row[rowKey]}
                            sx={styles.tableRow}
                            onClick={(event) => handleRowClick(row)}
                          >
                            {columns.map((column) => {
                              const value = row[column.key];
                              return (
                                <TableCell
                                  key={column.key}
                                  align={column.align}
                                  sx={styles.tableCell}
                                >
                                  {column.truncate ? (
                                  value?.length > column.truncate ? (
                                    <Tooltip title={value}>
                                      <div>
                                        {value.substr(0, column.truncate - 1) +
                                          '...'}
                                      </div>
                                    </Tooltip>
                                  ) : (
                                    value
                                  )
                                ) : (
                                  value
                                )}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                </TableBody>
              </Table>
            ) : null}
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[50, 100, 200, 300]}
            component="div"
            count={tableData?.data?.length || 0}
            rowsPerPage={rowsPerPage}
            page={curTablePage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Fragment>
      )}
    </Fragment>
  );
};
