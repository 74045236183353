import React, { Fragment, useContext } from "react";
import Menu from "@mui/material/Menu";
import { useTheme } from "@mui/material/styles";
import { ToggleGroup } from "mdp/components/Miscellaneous/ToggleGroup";
import Typography from "@mui/material/Typography";
import { MessageFiltersContext } from "mdp/contexts/MessageFiltersContext";

export const DateFormatMenu = (props) => {
  /*
  A dropdown menu containing various dateformats to select from.

  @props
  <menuAnchor: Object> the object to place the menu on. Basically the menu
  location
  <setMenuAnchor: function>: setter for menuAnchor
  */
  const { setMenuAnchor, menuAnchor } = props;
  const theme = useTheme();
  const styles = ({
    formatSelector: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between",
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(1),
    },
  });
  const { messageFilterState, setMessageFilterState } = useContext(
    MessageFiltersContext
  );

  return (
    <Fragment>
      <Menu
        anchorEl={menuAnchor}
        keepMounted
        open={Boolean(menuAnchor)}
        onClose={() => setMenuAnchor(null)}
      >
        <div style={styles.formatSelector}>
          <Typography variant="body2">Date Format</Typography>
          <ToggleGroup
            exclusive
            nonEmpty
            selectOptions={["YYYY/MM/DD", "DD/MM/YYYY", "MM/DD/YYYY"]}
            filterState={messageFilterState}
            setFilterState={setMessageFilterState}
            filterName="dateFormat"
          />
        </div>
      </Menu>
    </Fragment>
  );
};
