import React from 'react';

const styles = {
  color: '#9c9292',
  fontStyle: 'italic',
};

export const DefaultText = ({ text }) => {
  return <p style={styles}>{text}</p>;
};
