import React, { Fragment, useState, useContext } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useTheme } from '@mui/material/styles';
import { apiFetcher } from 'utils/apiUtils';
import { getCurrentDate } from 'utils/utilFunctions';
import { config } from 'config/config';
import { MessageFiltersContext } from 'mdp/contexts/MessageFiltersContext';
import { formatFiltersState } from '../Filters/MessageFiltersPanel';

export const CsvExportButton = () => {
  /*
  Component representing the export table button ion the
  main mdc messages table
  */
  const theme = useTheme();
  const styles = ({
    tableIconButton: {
      padding: '0 0 0 0',
      margin: '0 10px 0 0',
    },
    buttonIcons: {
      width: '25px',
      height: '25px',
      fill: theme.palette.primary.dark,
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    backdropComponents: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
  });
  const [isGeneratingReport, setIsGeneratingReport] = useState(false);
  const [showValidationPopup, setShowValidationPopup] = useState(false);
  const { messageFilterState, appliedMessageFilters } = useContext(
      MessageFiltersContext,
  );

  const handleClosePopup = (event, reason) => {
    // function for closing "need to perform a search first" popup
    if (reason === 'clickaway') {
      return;
    }
    setShowValidationPopup(false);
  };

  const handleClick = () => {
    if (!appliedMessageFilters) {
      setShowValidationPopup(true);
    } else {
      setIsGeneratingReport(true);
      // if formatFilters prop is provided then format the filters parameters
      // before sending the request to the backend
      let payload;
      if (formatFiltersState) {
        payload = formatFiltersState(appliedMessageFilters);
      } else {
        payload = appliedMessageFilters;
      }
      payload.dateFormat = messageFilterState.dateFormat;
      apiFetcher(
          `${config.apiURL}/mdp/reports/exportmdctable?reporttype=csv`,
          'POST',
          payload,
          {
            'Content-Type': 'application/json',
          },
          false,
      )
          .then((response) => {
            setIsGeneratingReport(false);
            response.blob().then((blob) => {
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.href = url;
              a.download = 'MDCMessagesExport ' + getCurrentDate() + '.csv';
              a.click();
            });
          })
          .catch((e) => {
            console.error(e);
            setIsGeneratingReport(false);
          });
    }
  };

  return (
    <Fragment>
      <Tooltip title="Export table to csv">
        <IconButton sx={styles.tableIconButton} onClick={handleClick} size="large">
          <SaveAltIcon sx={styles.buttonIcons} />
        </IconButton>
      </Tooltip>

      <Backdrop sx={styles.backdrop} open={isGeneratingReport}>
        <div style={styles.backdropComponents}>
          <CircularProgress sx={styles.buttonIcons} />
          <Typography variant="body2" color="inherit">
            Exporting table...
          </Typography>
        </div>
      </Backdrop>
      <Snackbar
        open={showValidationPopup}
        autoHideDuration={5000}
        onClose={(event, reason) => handleClosePopup(event, reason)}
      >
        <MuiAlert
          onClose={(event, reason) => handleClosePopup(event, reason)}
          severity="error"
          elevation={6}
          variant="filled"
        >
          A search must be performed first
        </MuiAlert>
      </Snackbar>
    </Fragment>
  );
};
