/* eslint-disable max-len */
/* Root component representing the expanded popup version of the Details panel */
import React, { useState, useEffect } from "react";
import CardContent from "@mui/material/CardContent";
import { useTheme } from "@mui/material/styles";
import Modal from "@mui/material/Modal";
import Slide from "@mui/material/Slide";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { DetailsPanelDetailsTab } from "mdp/components/MessagesTab/MessageDetails/Details/DetailsPanelDetailsTab";
import { DetailsPanelDataTab } from "mdp/components/MessagesTab/MessageDetails/Data/DetailsPanelDataTab";
import { DetailsPanelFimLinks } from "mdp/components/MessagesTab/MessageDetails/FIM/DetailsPanelFimLinks";
import { DetailsPanelFieldNotesTab } from "mdp/components/MessagesTab/MessageDetails/FieldNotes/DetailsPanelFieldNotesTb";
import { DetailsPanelSpecialistInputTab } from "mdp/components/MessagesTab/MessageDetails/SpecialistInput/DetailsPanelSpecialistInputTab";
// import { DetailsPanelMmelTab } from "mdp/components/MessagesTab/MessageDetails/MMEL/DetailsPanelMmelTab";
import { DetailsPanelLogicTab } from "mdp/components/MessagesTab/MessageDetails/Logic/DetailsPanelLogicTab";
import { ToggleGroup } from "mdp/components/Miscellaneous/ToggleGroup";
import { DetailsPanelSurroundingMessagesTab } from "mdp/components/MessagesTab/MessageDetails/SurroundingMessages/DetailsPanelSurroundingMessagesTab";
import { Button } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
/* eslint-enable max-len */

export const MessageDetailsExpanded = (props) => {
  const {
    panelState,
    selectedRow,
    selectedEQID,
    setSelectedEQID,
    isMessageDetailsLoading,
    messageDetailsError,
    binHex,
    setBinHex,
    isDetailsPanelExpand,
    setIsDetailsPanelExpand,
    surroundingMessages,
    isSurroundingMessagesLoading,
    surroundingMessagesError,
    specialistEditMode,
    setSpecialistEditMode,
    userEmail,
    setIsEmailPopupExpanded
  } = props;
  const [height, setHeight] = useState(window.innerHeight);
  useEffect(() => {
    const handleWindowResize = () => setHeight(window.innerHeight);
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  const theme = useTheme();
  const styles = ({
    title: {
      color: theme.palette.text.title2,
      fontSize: 18,
      borderBottom: "1px solid " + theme.palette.default.main,
      backgroundColor: theme.palette.secondary.main,
      height: "5%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    detailsPanelTitle: {
      position: "absolute",
    },
    detailsPanelButtons: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      position: "relative",
      marginLeft: "auto",
      marginRight: "5px",
    },
    binhexSelectExpanded: {
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    iconButtons: {
      padding: "0 0 0 0",
      margin: "0 10px 0 10px",
    },
    buttonIcons: {
      width: "25px",
      height: "25px",
      fill: theme.palette.primary.dark,
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    card: {
      display: "flex",
      flexDirection: "column",
      width: "75%",
      height: height > 905 ? "78%" : "95%",
    },
    detailsArea: {
      display: "grid",
      gridTemplateAreas:
        // eslint-disable-next-line max-len
        " 'A1 A2 A2' 'A3 A3 A3' 'A4 A4 A4' 'A5 A5 A5' 'A6 A6 A6' 'A7 A7 A7' 'A8 A8 A8' 'A9 A9 A9' ",
      gridGap: "20px",
      padding: "20px",
      height: "95%",
      overflow: "scroll",
    },
    detailsSection: {
      gridArea: "A1",
    },
    dataSection: {
      gridArea: "A2",
    },
    fimLinksSection: {
      gridArea: "A3",
    },
    alertSection: {
      marginTop: "20px",
      gridArea: "A4",
    },
    fieldNotesSection: {
      gridArea: "A5",
    },
    specialistInputSection: {
      gridArea: "A6",
    },
    // mmelSection: {
    //   gridArea: "A7",
    // },
    logicSection: {
      gridArea: "A7",
    },
  });

  const [isDialogueOpen, setIsDialogueOpen] = useState(false);

  const handleClose = () => {
    if (!specialistEditMode) {
      setIsDetailsPanelExpand(false);
    } else {
      setIsDialogueOpen(true);
    }
  };

  return <>
    <Modal
      sx={styles.modal}
      closeAfterTransition
      open={isDetailsPanelExpand}
      onClose={() => handleClose()}
      BackdropProps={{
        timeout: 600,
      }}
    >
      <Slide
        in={isDetailsPanelExpand}
        direction="down"
        mountOnEnter
        unmountOnExit
      >
        <Card elevation={3} sx={styles.card}>
          {/* Title bar with close button */}

          <CardContent sx={styles.title}>
            <div style={styles.detailsPanelTitle}>Message Details</div>
            <div style={styles.detailsPanelButtons}>
              <div style={styles.binhexSelectExpanded}>
                <ToggleGroup
                  nonEmpty
                  exclusive
                  selectOptions={["Bin", "Hex"]}
                  filterState={binHex}
                  setFilterState={setBinHex}
                  filterName="binHex"
                />
              </div>
              <IconButton
                sx={styles.iconButtons}
                onClick={() => handleClose()}
                size="large">
                <CloseIcon sx={styles.buttonIcons} />
              </IconButton>
            </div>
          </CardContent>

          {/* Message Details Area */}
          <div style={styles.detailsArea}>
            <div style={styles.detailsSection}>
              <DetailsPanelDetailsTab
                panelState={panelState}
                selectedEQID={selectedEQID}
                setSelectedEQID={setSelectedEQID}
                isMessageDetailsLoading={isMessageDetailsLoading}
                messageDetailsError={messageDetailsError}
              />
            </div>
            <div style={styles.dataSection}>
              <DetailsPanelDataTab
                panelState={panelState}
                binHex={binHex}
                setBinHex={setBinHex}
                isMessageDetailsLoading={isMessageDetailsLoading}
                messageDetailsError={messageDetailsError}
                inExpanded={true}
              />
            </div>
            <div style={styles.fimLinksSection}>
              <DetailsPanelFimLinks
                panelState={panelState}
                selectedEQID={selectedEQID}
                isMessageDetailsLoading={isMessageDetailsLoading}
                messageDetailsError={messageDetailsError}
              />
            </div>
            {selectedRow && userEmail !== "" && (
            <div style={styles.alertSection}>
              <Button
                sx={{ width: '100%', px: 20, padding: '5px 15px' }}
                variant="outlined"
                color="primary"
                onClick={() => setIsEmailPopupExpanded(true)}
              >
                Send Alert to MHIRJ CRC
              </Button>
            </div>
            )}
            <div style={styles.fieldNotesSection}>
              <DetailsPanelFieldNotesTab
                panelState={panelState}
                selectedEQID={selectedEQID}
                isMessageDetailsLoading={isMessageDetailsLoading}
                messageDetailsError={messageDetailsError}
                />
            </div>
            <div style={styles.specialistInputSection}>
              <DetailsPanelSpecialistInputTab
                panelState={panelState}
                selectedEQID={selectedEQID}
                specialistEditMode={specialistEditMode}
                setSpecialistEditMode={setSpecialistEditMode}
                isMessageDetailsLoading={isMessageDetailsLoading}
                messageDetailsError={messageDetailsError}
                inExpanded={true}
              />
            </div>
            {/* <div style={styles.mmelSection}>
              <DetailsPanelMmelTab
                panelState={panelState}
                selectedEQID={selectedEQID}
                setSelectedEQID={setSelectedEQID}
                inExpanded={true}
                specialistEditMode={specialistEditMode}
                setSpecialistEditMode={setSpecialistEditMode}
              />
            </div> */}
            <div style={styles.logicSection}>
              <DetailsPanelLogicTab
                panelState={panelState}
                selectedEQID={selectedEQID}
                isMessageDetailsLoading={isMessageDetailsLoading}
                messageDetailsError={messageDetailsError}
              />
            </div>
            <div style={styles.surroundingMessagesSection}>
              <DetailsPanelSurroundingMessagesTab
                panelState={panelState}
                selectedRow={selectedRow}
                surroundingMessages={surroundingMessages}
                isSurroundingMessagesLoading={isSurroundingMessagesLoading}
                surroundingMessagesError={surroundingMessagesError}
                setIsDetailsPanelExpand={setIsDetailsPanelExpand}
                specialistEditMode={specialistEditMode}
                inExpanded={true}
              />
            </div>
          </div>
        </Card>
      </Slide>
    </Modal>
    <Dialog maxWidth="xs" open={isDialogueOpen}>
      <DialogTitle>
        Discard or Save Your Specialist Input Edits Before Switching Pages
      </DialogTitle>
      <DialogActions>
        <Button
          color="error"
          onClick={() => {
            setIsDialogueOpen(false);
          }}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  </>;
};
