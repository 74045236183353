import React, { useState, createContext } from 'react';

export const FleetTabFiltersContext = createContext();

export const FleetTabFiltersContextProvider = (props) => {
  const [searchInput, setSearchInput] = useState('');
  const [sortBy, setSortBy] = useState('LAST_DOWNLOAD_DATE');
  const [filterOPR, setFilterOPR] = useState('All');
  const [filterAC, setFilterAC] = useState({
    CRJ: true,
    CRJ700: true,
  });
  const [showRegistrationNumber, setShowRegistrationNumber] = useState(true);

  return (
    <FleetTabFiltersContext.Provider
      value={{
        searchInput,
        setSearchInput,
        sortBy,
        setSortBy,
        filterOPR,
        setFilterOPR,
        filterAC,
        setFilterAC,
        showRegistrationNumber,
        setShowRegistrationNumber,
      }}
    >
      {props.children}
    </FleetTabFiltersContext.Provider>
  );
};

export default FleetTabFiltersContextProvider;
