import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import PublishIcon from "@mui/icons-material/Publish";
import SaveIcon from "@mui/icons-material/Save";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from '@mui/material/Alert';
import { useTheme } from "@mui/material/styles";


import {
  exportToJSON,
  getCurrentDate,
  handleFileUpload,
} from "utils/utilFunctions";

export const FilterSaver = (props) => {
  const { filterStateName, filterState, setFilterState } = props;
  const theme = useTheme();
  const styles = ({
    iconButtons: {
      padding: "0 0 0 0",
      margin: "0 10px 0 0",
    },
    buttonIcons: {
      width: "25px",
      height: "25px",
      fill: theme.palette.primary.dark,
    },
    uploadBox: {
      display: "none",
    },
    label: {
      marginBottom: 0,
    },
  });

  const [showSuccess, setShowSuccess] = useState(false);
  const [showFailure, setShowFailure] = useState(false);

  const applyUploadedFilters = (uploadedFilters) => {
    if (filterStateName === uploadedFilters.name) {
      // making it so that dates aren't affected by filter application
      uploadedFilters.startDate = filterState.startDate;
      uploadedFilters.endDate = filterState.endDate;
      uploadedFilters.endDateOffset = filterState.endDateOffset;

      if (filterStateName === "MessageFiltersState" || filterStateName === "EqIdFiltersState") {
        if (!uploadedFilters.eicas) {
          uploadedFilters.eicas = []
          uploadedFilters.excludeEicas = false
        }
      }

      setFilterState(uploadedFilters);
      setShowSuccess(true);
    } else {
      setShowFailure(true);
    }
  };

  const handleClosePopup = (event, reason, setPopupState) => {
    if (reason === "clickaway") {
      return;
    }
    setPopupState(false);
  };
  return <>
    <input
      style={styles.uploadBox}
      type="file"
      id="fileUpload"
      onChange={(e) =>
        handleFileUpload(e.target.files[0], applyUploadedFilters)
      }
    />
    <label htmlFor="fileUpload" style={styles.label}>
      <Tooltip title="Upload a saved filter file">
        <IconButton
          color="primary"
          sx={styles.iconButtons}
          component="span"
          size="large">
          <PublishIcon sx={styles.buttonIcons} />
        </IconButton>
      </Tooltip>
    </label>
    <Tooltip title="Save current filter settings to file">
      <IconButton
        color="primary"
        sx={styles.iconButtons}
        onClick={() =>
          exportToJSON(
            "SavedFilters" + getCurrentDate() + ".json",
            filterState
          )
        }
        size="large">
        <SaveIcon sx={styles.buttonIcons} />
      </IconButton>
    </Tooltip>
    <Snackbar
      open={showSuccess}
      autoHideDuration={5000}
      onClose={(event, reason) =>
        handleClosePopup(event, reason, setShowSuccess)
      }
    >
      <MuiAlert
        onClose={(event, reason) =>
          handleClosePopup(event, reason, setShowSuccess)
        }
        severity="success"
        elevation={6}
        variant="filled"
      >
        Filter settings uploaded successfully!
      </MuiAlert>
    </Snackbar>
    <Snackbar
      open={showFailure}
      autoHideDuration={5000}
      onClose={(event, reason) =>
        handleClosePopup(event, reason, setShowFailure)
      }
    >
      <MuiAlert
        onClose={(event, reason) =>
          handleClosePopup(event, reason, setShowFailure)
        }
        severity="error"
        elevation={6}
        variant="filled"
      >
        Incompatible saved filters file!
      </MuiAlert>
    </Snackbar>
  </>;
};
