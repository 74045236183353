import React, { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "./TabNav.css";
import FlightIcon from "@mui/icons-material/Flight";
import InfoIcon from "@mui/icons-material/Info";
import StorageIcon from "@mui/icons-material/Storage";
import TableChartIcon from "@mui/icons-material/TableChart";
import SearchIcon from "@mui/icons-material/Search";
import ArrowBack from "@mui/icons-material/ArrowBack";
import UndoIcon from "@mui/icons-material/Undo";
import { Button } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import orcaLogo from "assets/circled_orca_logo.png";

const tabIconStyles = ({
  icon: {
    paddingRight: "10px",
    width: "30px",
    height: "30px",
  },
  arrowBack: {
    transform: "rotate(90deg)",
  },
  undoIcon: {
    transform: "rotate(90deg)",
    marginLeft: "3px",
  },
  orcaLogo: {
    width: "25px",
    height: "25px",
    marginRight: "10px",
  },
});

export const TabNav = (props) => {
  const { specialistEditMode } = props;
  const history = useHistory();
  const location = useLocation();
  const [isDialogueOpen, setIsDialogueOpen] = useState(false);
  const [curTab, setCurTab] = useState("/Fleet");

  // Returns active if the given tabname = curTab
  const isTabActive = (tabName) => {
    return curTab === tabName ? "active" : "";
  };

  useEffect(() => {
    // make verticle scroll behaviour behave like horizontal scroll
    const navContainer = document.querySelector(".tabBar");
    const handleScroll = (event) => {
      event.preventDefault();
      navContainer.scrollLeft += event.deltaY;
    };
    navContainer.addEventListener("wheel", handleScroll);

    // Update curTab upon route change
    setCurTab(location.pathname);

    // Clean up the event listener when the component unmounts
    return () => {
      navContainer.removeEventListener("wheel", handleScroll);
    };
  }, [location]);

  const handleClick = (tabName) => {
    if (specialistEditMode) {
      setIsDialogueOpen(true);
    } else {
      history.push(tabName);
    }
  };

  // use mousewheel to scroll the navigation bar
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;

    const handleMouseWheel = (event) => {
      container.scrollLeft -= event.deltaY;
      event.preventDefault();
    };

    container.addEventListener("wheel", handleMouseWheel);

    return () => {
      container.removeEventListener("wheel", handleMouseWheel);
    };
  }, []);

  return (
    <>
      <div className="tabBar" ref={containerRef}>
        <ul className="nav">
          <li
            className={"tab " + isTabActive("/mdp/FlightDeckEffects")}
            onClick={() => {
              handleClick("FlightDeckEffects");
            }}
          >
            <InfoIcon sx={tabIconStyles.icon} color="primary" />
            <p className="tabLabelSmall">FDE & Sensory</p>
          </li>
          <li
            className={"tab " + isTabActive("/mdp/EqIDLookup")}
            onClick={() => {
              handleClick("EqIDLookup");
            }}
          >
            <SearchIcon sx={tabIconStyles.icon} color="primary" />
            <p className="tabLabelSmall">Troubleshooting</p>
          </li>
          <li
            className={"tab " + isTabActive("/mdp/RawData")}
            onClick={() => {
              handleClick("RawData");
            }}
          >
            <StorageIcon sx={tabIconStyles.icon} color="primary" />
            <p className="tabLabel">Raw Data</p>
          </li>
          <li
            className={"tab " + isTabActive("/mdp/Messages")}
            onClick={() => {
              handleClick("Messages");
            }}
          >
            <TableChartIcon sx={tabIconStyles.icon} color="primary" />
            <p className="tabLabel">Messages</p>
          </li>
          <li
            className={"tab " + isTabActive("/mdp/Fleet")}
            onClick={() => {
              handleClick("Fleet");
            }}
          >
            <FlightIcon sx={tabIconStyles.icon} color="primary" />
            <p className="tabLabel">Fleet</p>
          </li>
          {location.state?.from && (
            <li
              className={"orcaTabButton"}
              onClick={() => {
                handleClick(location.state?.from);
              }}
            >
              <img src={orcaLogo} style={tabIconStyles.orcaLogo} color="white" />
              <p className="tabLabel">ORCA</p>
              <UndoIcon sx={tabIconStyles.undoIcon} color="white" />
            </li>
          )}
          <li
            className={"tab backTab"}
            onClick={() => {
              handleClick("/");
            }}
          >
            <ArrowBack sx={tabIconStyles.arrowBack} color="white" />
          </li>
        </ul>
      </div>
      <Dialog maxWidth="xs" open={isDialogueOpen}>
        <DialogTitle>
          Discard or Save Your Specialist Input Edits Before Switching Tabs
        </DialogTitle>
        <DialogActions>
          <Button
            color="error"
            onClick={() => {
              setIsDialogueOpen(false);
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
