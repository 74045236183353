import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import { useFetch } from "utils/apiUtils";
import { config } from "config/config";
import "orca/components/TimelineTab/TimelineTab.css";
import { LoadingIcon } from "orca/components/Miscellaneous/LoadingIcon";
import { TimelineFiltersPanel } from "orca/components/TimelineTab/TimelineFiltersPanel";
import { TimelineTablePanel } from "orca/components/TimelineTab/TimelineTablePanel";
import { ChronicTablePanel } from "orca/components/TimelineTab/ChronicTablePanel";

export const TimelineTab = (props) => {
  const { setIsFirstTime, isFirstTime } = props;
  const styles = ({
    root: {
      height: "100%",
      marginRight: "10px",
      borderTop: "1px solid rgba(0,0,0,0.2)",
      borderLeft: "1px solid rgba(0,0,0,0.2)",
      borderRight: "1px solid rgba(0,0,0,0.2)",
      borderBottom: "1px solid rgba(0,0,0,0.2)",
    },
  });

  // fetchs filter
  const { data: fleetList, error: fleetListError } = useFetch(
    `${config.apiURL}/orca/filteroptions/oprname`,
    "GET"
  );
  const { data: acsnList, error: acsnListError } = useFetch(
    `${config.apiURL}/orca/filteroptions/acsn`,
    "GET"
  );
  const { data: actnList, error: actnListError } = useFetch(
    `${config.apiURL}/orca/filteroptions/actn`,
    "GET"
  );
  const { data: ataList, error: ataListError } = useFetch(
    `${config.apiURL}/orca/filteroptions/ata`,
    "GET"
  );
  const { data: messageTypeList, error: messageTypeListError } = useFetch(
    `${config.apiURL}/orca/filteroptions/messagetype`,
    "GET"
  );

  // check filter loading status
  const isFiltersLoading = () => {
    const isLoading = Boolean(
      (!fleetList.oprData ||
        !acsnList.data ||
        !actnList.data ||
        !ataList.data ||
        !messageTypeList) &&
        !fleetListError &&
        !acsnListError &&
        !actnListError &&
        !ataListError &&
        !messageTypeListError
    );
    setIsFirstTime(false);
    return isLoading;
  };

  const isFiltersFetchError = () => {
    return Boolean(
      fleetListError || acsnListError || actnListError ||
      ataListError || messageTypeListError
    );
  };

  // shows loading logo at least 2 seconds
  const [showIcon, setShowIcon] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowIcon(false);
    }, 2000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <div className="wrapper">
      {isFirstTime && (showIcon || isFiltersLoading()) ? (
        <LoadingIcon maxWidth={`500px`}></LoadingIcon>
      ) : (
        <div className="timelineTab">
          <div className="timelineTabFiltersPane">
            <Card sx={styles.root}>
              <TimelineFiltersPanel
                isFiltersLoading={isFiltersLoading()}
                isFiltersFetchError={isFiltersFetchError()}
                fleetList={fleetList}
                acsnList={acsnList}
                actnList={actnList}
                ataList={ataList}
                messageTypeList={messageTypeList}
              ></TimelineFiltersPanel>
            </Card>
          </div>

          <div className="timelineTabDataPane">
            <div className="timelineTabTimelinePane">
              <Card sx={styles.root}>
                <TimelineTablePanel
                  ataList={ataList}
                ></TimelineTablePanel>
              </Card>
            </div>
            <div className="timelineTabChronicPane">
              <Card sx={styles.root}>
                <ChronicTablePanel></ChronicTablePanel>
              </Card>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
