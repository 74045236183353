import React, { useState, createContext } from 'react';

export const RawDataDataContext = createContext();

export const RawDataDataContextProvider = (props) => {
  // if the Raw Data table data is loading
  const [isLoading, setIsLoading] = useState(false);
  // if theres an error when querying Raw Data table data
  const [error, setError] = useState(null);
  // state used to store data from Raw Data Table
  const [rawDataTableState, setRawDataTableState] = useState(null);

  return (
    <RawDataDataContext.Provider
      value={{
        rawDataTableState,
        setRawDataTableState,
        isLoading,
        setIsLoading,
        error,
        setError,
      }}
    >
      {props.children}
    </RawDataDataContext.Provider>
  );
};

export default RawDataDataContextProvider;
