import React, { useState, createContext } from 'react';

export const AlertsFiltersContext = createContext();

export const AlertsFiltersContextProvider = (props) => {
  const getDateString = (offset) => {
    const newDate = new Date();
    newDate.setMonth(newDate.getMonth() + offset);
    const tzOffset = newDate.getTimezoneOffset();
    const newDateWithOffset = new Date(
        newDate.getTime() - tzOffset * 60 * 1000);
    return newDateWithOffset.toISOString().split('T')[0];
  };

  const defaultAlertsFilterState = {
    name: 'alertsFilterState',
    startDate: getDateString(-1), // one month ago
    endDate: getDateString(0),
    acModel: '',
    fleets: [],
    acsn: [],
    actn: [],
    messageType: [],
    keywords: [],
    searchKeyword: '',
    excludeFleets: false,
    excludeAcsn: false,
    excludeActn: false,
    excludeMessageTypes: false,
    excludeKeywords: false,
  };
  // state of the filters panel in the raw data tab
  const [
    alertsFilterState,
    setAlertsFilterState
  ] = useState(defaultAlertsFilterState);
  // copy of the filters that were applied in the current search
  const [appliedAlertsFilters, setAppliedAlertsFilters] = useState(null);
  // number of rows to display per page in the raw data table
  const [rowsPerPage, setRowsPerPage] = useState(100);
  // page number the user is on in the raw data table
  const [curTablePage, setCurTablePage] = useState(0);
  // rows that the user has selected in the raw data table
  const [selectedTableRows, setSelectedTableRows] = useState([]);
  const [mimicApplyClick, setMimicApplyClick] = useState(false);
  // number of rows to display per page in the mdc messages table
  const [prevAlertsFilterState, setPrevAlertsFilterState] = useState(null);

  // state used to store selected keywords accordion
  const [keywordsSelected, setKeywordsSelected] = useState([]);
  // state used to store selected ac_tn accordion
  const [acsnSelected, setAcsnSelected] = useState([]);
  // state used to store selected checkbox
  const [selectedRows, setSelectedRows] = useState([]);
  // state used to store selected row
  const [rowSelected, setRowSelected] = useState(null);


  return (
    <AlertsFiltersContext.Provider
      value={{
        alertsFilterState,
        setAlertsFilterState,
        appliedAlertsFilters,
        setAppliedAlertsFilters,
        defaultAlertsFilterState,
        rowsPerPage,
        setRowsPerPage,
        curTablePage,
        setCurTablePage,
        selectedTableRows,
        setSelectedTableRows,
        prevAlertsFilterState,
        setPrevAlertsFilterState,
        mimicApplyClick,
        setMimicApplyClick,
        keywordsSelected,
        setKeywordsSelected,
        acsnSelected,
        setAcsnSelected,
        selectedRows,
        setSelectedRows,
        rowSelected,
        setRowSelected,
      }}
    >
      {props.children}
    </AlertsFiltersContext.Provider>
  );
};
export default AlertsFiltersContextProvider;
