import React, { Fragment, useState, useMemo } from "react";
import axios from "axios";
import CardContent from "@mui/material/CardContent";
import { useTheme } from "@mui/material/styles";
import Modal from "@mui/material/Modal";
import Slide from "@mui/material/Slide";
import Grow from "@mui/material/Grow";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import CircularProgress from '@mui/material/CircularProgress';
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import Check from "@mui/icons-material/Check";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";
import { useDropzone } from "react-dropzone";
import { config } from "config/config";
import "mdp/components/RawDataTab/RawDataTablePanel.css";

export const DataUploadPanel = (props) => {
  const { setIsUploadPanelOpen, isUploadPanelOpen } = props;
  // files that are being uploaded
  const [uploadedFiles, setUploadedFiles] = useState([]);
  // if there is an error when uploading files
  const [uploadError, setUploadError] = useState(false);
  // progres of the upload out of 100%
  const [uploadedProgress, setUploadedProgress] = useState(0);
  const { getRootProps, getInputProps, isDragAccept } = useDropzone({
    onDrop: (files) => {
      setUploadedProgress(0);
      setUploadedFiles(files);
      setUploadError(false);
    },
  });

  const theme = useTheme();
  const styles = ({
    title: {
      color: theme.palette.text.title2,
      fontSize: 18,
      borderBottom: "1px solid " + theme.palette.default.main,
      backgroundColor: theme.palette.secondary.main,
      height: "15%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "0 0 0 0",
    },
    iconButtons: {
      padding: "0 0 0 0",
      margin: "0 10px 0 0",
    },
    buttonIcons: {
      width: "25px",
      height: "25px",
      fill: theme.palette.primary.dark,
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    card: {
      display: "flex",
      flexDirection: "column",
      width: "40%",
      height: "45%",
    },
    uploadIcon: {
      width: "45px",
      height: "45px",
      color: "#949292",
    },
    fileItemIcons: {
      display: "flex",
      flexDirection: "row",
    },
    uploadArea: {
      width: "95%",
      height: "30%",
      margin: "20px auto 0 auto",
    },
    uploadAreaText: {
      color: "#949292",
      margin: "0 0 0 0",
    },
    fileDisplayArea: {
      overflowY: "auto",
      scrollbarWidth: "thin",
      height: "40%",
      width: "95%",
      margin: "20px auto 0 auto",
    },
    confirmationPanel: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "flex-end",
      width: "95%",
      height: "15%",
      margin: "0 auto 20px auto",
    },
    uploadStatusBar: {
      marginTop: "10px",
      width: "80%",
    },
    baseStyle: {
      height: "100%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      borderStyle: "dashed",
      borderWidth: "2px",
      borderRadius: "2px",
      borderColor: "#e0e0e0",
      backgroundColor: "#f3f2f1",
      transition: "border .24s ease-in-out",
    },
    acceptStyle: {
      borderColor: theme.palette.success.main,
    },
  });

  const uploadBoxStyled = useMemo(
    () => ({
      ...styles.baseStyle,
      ...(isDragAccept ? styles.acceptStyle : {}),
    }),
    [isDragAccept]
  );

  const uploadFiles = async () => {
    // this function sends files that the user uploaded to the backend
    // create formdata object that will contain files to upload
    const formData = new FormData();
    for (let i = 0; i < uploadedFiles.length; i++) {
      formData.append("uploadedFiles", uploadedFiles[i]);
    }

    setUploadedProgress(1);
    // a function to compute file upload progress
    const onUploadProgress = (event) => {
      const progressPercent = Math.floor((event.loaded * 100) / event.total);
      if (progressPercent < 100) {
        setUploadedProgress(progressPercent);
      }
    };

    let headers = { "Content-Type": "multipart/form-data" };

    const tokenStorage = JSON.parse(localStorage.getItem('okta-token-storage'));
    const accessToken = tokenStorage.accessToken.value || tokenStorage.accessToken.accessToken;
    headers = {
      ...headers,
      Authorization: `Bearer ${accessToken}`,
    };

    // upload files to backend
    // using axios instead of fetch because axios lets us track upload progress
    axios({
      method: "put",
      url: `${config.apiURL}/mdp/rawdata/files`,
      data: formData,
      headers,
      onUploadProgress,
    })
      .then((res) => {
        setUploadedProgress(100);
        setUploadError(false);
      })
      .catch((error) => {
        console.error(error);
        setUploadError(true);
        setUploadedProgress(0);
      });
  };

  return (
    <Modal
      sx={styles.modal}
      closeAfterTransition
      open={isUploadPanelOpen}
      onClose={() => setIsUploadPanelOpen(false)}
      BackdropProps={{
        timeout: 600,
      }}
    >
      <Slide in={isUploadPanelOpen} direction="down" mountOnEnter unmountOnExit>
        <Card elevation={3} sx={styles.card}>
          {/* Title bar with close button */}
          <CardContent sx={styles.title}>
            <div className="buttonBarTitle">Upload Raw Data Files</div>
            <div className="closePannelButton">
              <IconButton
                sx={styles.iconButtons}
                onClick={() => setIsUploadPanelOpen(false)}
                size="large">
                <CloseIcon sx={styles.buttonIcons} />
              </IconButton>
            </div>
          </CardContent>

          {/* Upload area */}
          <div style={styles.uploadArea}>
            <div {...getRootProps({ style: uploadBoxStyled })}>
              <input {...getInputProps()} />
              <p style={styles.uploadAreaText}>
                Drag and drop or click to browse files
              </p>
              <CloudUploadIcon sx={styles.uploadIcon} />
            </div>
          </div>

          {/* Uploaded file display area */}
          <div style={styles.fileDisplayArea}>
            {uploadedFiles.length > 0 && (
              <Grow in timeout={500}>
                <ListItem divider>
                  <ListItemIcon>
                    <InsertDriveFileIcon />
                  </ListItemIcon>

                  {/* Show sum of all file sizes in kilobytes */}
                  <ListItemText
                    primary={`${uploadedFiles.length} Files`}
                    secondary={
                      <div>
                        <p>
                          {`${Math.ceil(
                            uploadedFiles.reduce((a, b) => a + b.size, 0) / 1024
                          )} KB`}
                        </p>
                      </div>
                    }
                  />

                  {uploadError && (
                    <Typography
                      color="error"
                      style={{ paddingRight: "10px" }}
                      variant="subtitle2"
                    >
                      Upload Failed
                    </Typography>
                  )}

                  {uploadedProgress === 0 && (
                    <IconButton
                      sx={styles.iconButtons}
                      onClick={() => setUploadedFiles([])}
                      size="large">
                      <DeleteIcon sx={styles.buttonIcons} />
                    </IconButton>
                  )}
                  {uploadedProgress > 0 && uploadedProgress < 100 && (
                    <CircularProgress/>
                  )}

                  {uploadedProgress === 100 && !uploadError && (
                    <Fragment>
                      <Typography
                        style={{ paddingRight: "10px" }}
                        variant="subtitle2"
                      >
                        Uploaded
                      </Typography>
                      <Check />
                    </Fragment>
                  )}
                </ListItem>
              </Grow>
            )}
          </div>
          <div style={styles.confirmationPanel}>
            <Button
              disabled={uploadedProgress > 0 && uploadedProgress < 100}
              variant="outlined"
              color="primary"
              onClick={uploadFiles}
            >
              Upload
            </Button>
          </div>
        </Card>
      </Slide>
    </Modal>
  );
};
