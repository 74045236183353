import React, { Fragment, useState, useContext } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useTheme } from '@mui/material/styles';
import { ToggleGroup } from 'mdp/components/Miscellaneous/ToggleGroup';
import { apiFetcher } from 'utils/apiUtils';
import { getCurrentDate } from 'utils/utilFunctions';
import { config } from 'config/config';
import { MessageFiltersContext } from '../../../contexts/MessageFiltersContext';
import { formatFiltersState } from '../Filters/MessageFiltersPanel';

export const ReportsMenu = (props) => {
  /*
  Component representing the reports dropdown menu on the
  main mdc messages table

  @props
  <menuItems:fucntion>: takes in filterState and mutates it. Used to
  make some sort of change to the filterState before it is sent to the backend
  <menuAnchor: Object> the object to place the menu on. Basically the menu
  location
  <setMenuAnchor: function>: setter for menuAnchor
  */
  const theme = useTheme();
  const styles = ({
    formatSelector: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(1),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    backdropComponents: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
  });

  const [reportFilters, setReportFilters] = useState({ reportType: 'PDF' });
  const [isGeneratingReport, setIsGeneratingReport] = useState(false);
  const [showValidationPopup, setShowValidationPopup] = useState(false);
  const { messageFilterState, appliedMessageFilters } = useContext(
      MessageFiltersContext,
  );
  const { menuItems, menuAnchor, setMenuAnchor } = props;

  const handleClosePopup = (event, reason) => {
    // function for closing "need to perform a search first" popup
    if (reason === 'clickaway') {
      return;
    }
    setShowValidationPopup(false);
  };
  const handleClick = (item) => {
    if (!appliedMessageFilters && item.validateFilters) {
      setShowValidationPopup(true);
    } else {
      setIsGeneratingReport(true);
      // if formatFilters prop is provided then format the filters parameters
      // before sending the request to the backend
      let payload;
      if (formatFiltersState) {
        payload = formatFiltersState(appliedMessageFilters);
      } else {
        payload = appliedMessageFilters;
      }
      payload.dateFormat = messageFilterState.dateFormat;
      apiFetcher(
          `${config.apiURL
          }/mdp/reports/${item.endpoint
          }?reporttype=${reportFilters.reportType}`,
          'POST',
          payload,
          {
            'Content-Type': 'application/json',
          },
          false,
      )
          .then((response) => {
            setIsGeneratingReport(false);
            response.blob().then((blob) => {
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.href = url;
              a.download =
              item.label +
              ' ' +
              getCurrentDate() +
              '.' +
              reportFilters.reportType.toLowerCase();
              a.click();
            });
          })
          .catch((e) => {
            console.error(e);
            setIsGeneratingReport(false);
          });
      setMenuAnchor(null);
    }
  };

  return (
    <Fragment>
      <Menu
        anchorEl={menuAnchor}
        keepMounted
        open={Boolean(menuAnchor)}
        onClose={() => setMenuAnchor(null)}
      >
        <div>
          <div style={styles.formatSelector}>
            <Typography variant="body2">Export Format</Typography>
            <ToggleGroup
              exclusive
              nonEmpty
              selectOptions={['PDF', 'CSV', 'HTML']}
              filterState={reportFilters}
              setFilterState={setReportFilters}
              filterName="reportType"
            />
          </div>
          <Divider />
        </div>

        {menuItems.map((item) => {
          return (
            <MenuItem key={item.label} onClick={() => handleClick(item)}>
              {item.label}
            </MenuItem>
          );
        })}
      </Menu>

      <Backdrop sx={styles.backdrop} open={isGeneratingReport}>
        <div style={styles.backdropComponents}>
          <CircularProgress sx={styles.buttonIcons} />
          <Typography variant="body2" color="inherit">
            Generating Report...
          </Typography>
        </div>
      </Backdrop>
      <Snackbar
        open={showValidationPopup}
        autoHideDuration={5000}
        onClose={(event, reason) => handleClosePopup(event, reason)}
      >
        <MuiAlert
          onClose={(event, reason) => handleClosePopup(event, reason)}
          severity="error"
          elevation={6}
          variant="filled"
        >
          This report requires a search to be performed first
        </MuiAlert>
      </Snackbar>
    </Fragment>
  );
};
