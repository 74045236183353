import React from "react";
import 'chartjs-adapter-date-fns';
import { Line } from "react-chartjs-2";
import { registerables, Chart } from "chart.js";
import plane from "assets/orca_timeline_icon.png";

Chart.register(...registerables);

const getLineColour = (watchValue, splitValue) => {
  let lineColour = watchValue === "Y" ? "rgba(255, 215, 0, 1)" : "rgba(75, 192, 192, 1)"; // Gold if on watchlist, otherwise cyan.
  lineColour = splitValue ? "rgba(128, 0, 128, 1)" : lineColour; // Purple if row was split, otherwise it was the previous line colour.
  return lineColour;
}

export const TimelineGraph = (props) => {
  const { endDate, displayXAxis, startDate, canvasHeight, dates, watchValue, splitValue } = props;
  const xData = dates.map((label) => label.split(' ')[0]);

  const isWithin15Days = (start, end) => {
    const difference = Math.abs(new Date(end) - new Date(start));
    // convert ms to day
    const differenceInDays = Math.ceil(difference / (1000 * 60 * 60 * 24));
    return differenceInDays <= 15
  }

  const icon = new Image(12, 12);
  icon.src = plane;

  const data = {
    labels: xData,
    datasets: [
      {
        label: "data",
        data: Array.from({ length: dates.length }, () => 0),
        fill: false,
        borderColor: getLineColour(watchValue, splitValue),
        lineTension: 0.1,
        pointStyle: icon,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    scales: {
      x: {
        type: "time",
        display: displayXAxis,
        time: {
          parser: function (date) {
            return new Date(date);
          },
          unit: isWithin15Days(startDate, endDate) ? "day" : "month",
          unitStepSize: 1,
        },
        min: new Date(startDate),
        max: new Date(endDate).setDate(new Date(endDate).getDate() + 2), // add 2 days to account for spacing
        grid: {
          display: false,
        },
      },
      y: {
        display: false,
        ticks: {
          stepSize: 1,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: (tooltipItem) => {
            const date = tooltipItem[0].label.split(',')[0];
            const overlappingPoints = tooltipItem.length;
            return `${date}, Occurrences: ${overlappingPoints}`;
          },
        },
      },
    },
  };

  return (
    <div style={{ height: canvasHeight }}>
      <Line data={data} options={options} />
    </div>
  );
};
