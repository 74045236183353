import React, { Fragment, useLayoutEffect, useState, useEffect } from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableSortLabel,
  Checkbox,
  TablePagination
} from "@mui/material";
import { useTheme } from '@mui/material/styles'
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from '@mui/material/Alert';
import { useFetch, apiFetcher } from 'utils/apiUtils';
import { config } from "config/config";
import { LoadingIcon } from "orca/components/Miscellaneous/LoadingIcon";
import { DefaultText } from "orca/components/Miscellaneous/DefaultText";
import { CsvExportButton } from "orca/components/Miscellaneous/CsvExportButton";
import { MdpNavButton } from "orca/components/Miscellaneous/MdpNavButton";

export const SpltTable = (props) => {
  const {
    rowKey,
    columns,
    tableData,
    isLoading,
    error,
    curTablePage,
    setCurTablePage,
    rowsPerPage,
    setRowsPerPage,
    selectedRowIndex,
    setSelectedRowIndex,
    selectedRows,
    setSelectedRows,
    sortDirection,
    setSortDirection,
    sortCol,
    setSortCol,
  } = props;

  const theme = useTheme();

  const styles = ({
    tableHeader: {
      color: theme.palette.primary.main,
      backgroundColor: "white",
      fontWeight: "bold",
    },
    spinnerAndText: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      height: "88%",
    },
    container: {
      height: "93%",
      scrollbarWidth: "thin",
    },
    tableRow: {
      backgroundColor: "white",
      '&:hover': {
        backgroundColor: 'rgba(90, 129, 171, 0.14)',
      },
    },
    selected: {
      backgroundColor: 'rgba(90, 129, 171, 0.24)',
    },
    headerCell: {
      padding: "6px 8px 6px 8px",
      textAlign: "center",
      fontWeight: "bold",
    },
    tableCell: {
      fontSize: 11,
      padding: "6px 8px 6px 6px",
      textAlign: "center",
    },
    pagination: {
      backgroundColor: 'white',
    },
    paginationContainer: {
      height: "7%",
      display: "flex",
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '5px 0 10px 0',
      overflow: 'hidden',
    },
    snackBar: {
      vertical: "bottom",
      horizontal: "center",
    },
  });

  const [displayedTableData, setDisplayedTableData] = useState([]);

  useLayoutEffect(() => {
    setDisplayedTableData(
      tableData.data.slice(
        curTablePage * rowsPerPage,
        curTablePage * rowsPerPage + rowsPerPage
      )
    )
  }, [tableData.data, rowsPerPage, curTablePage, setCurTablePage]);

  // check if user has mdp access
  const [hasMdpAccess, setHasMdpAccess] = useState(null);
  useEffect(() => {
    const getAccess = async () => {
      await apiFetcher(`${config.apiURL}/mdp/checkAccess`, 'GET').then(
          (response) => {
            const access = response.data.map((item) =>
              item.COMPONENT).includes('MDP');
            setHasMdpAccess(access);
          },
      );
    };

    if (hasMdpAccess == null) {
      getAccess();
    }
  }, []);

  // fetch ata list used in mdp message filter
  const { data: ataList } = useFetch(
    `${config.apiURL}/mdp/filteroptions/ata`,
    "GET"
  );

  const { data: fleetList } = useFetch(
    `${config.apiURL}/orca/filteroptions/oprname`,
    "GET"
  );

  const sortTableData = (col) => {
    const sortOrder = sortDirection === 'asc' ? 'desc' : 'asc';
    const sortedData = tableData.data.sort((a, b) => {
      const isString = (typeof a[col] === 'string' && typeof b[col] === 'string');

      return sortOrder === "asc"
      ? (isString ? a[col].localeCompare(b[col]) : a[col] - b[col])
      : (isString ? b[col].localeCompare(a[col]) : b[col] - a[col]);
    });
    setDisplayedTableData(sortedData.slice(0, rowsPerPage));
    setSortDirection(sortOrder);
    setCurTablePage(0);
    setSelectedRowIndex(null);
  };


  const handleCheckBoxClick = (row) => {
    // if selected row is already checked then uncheck it
    if (selectedRows.map((item) => item[rowKey]).includes(row[rowKey])) {
      setSelectedRows(
        selectedRows.filter((item) => item[rowKey] !== row[rowKey])
      );
    } else {
      // if selected row is already unchecked then check it
      setSelectedRows([...selectedRows, row]);
    }
  };

  const handleSelectAllRows = (event) => {
    // if "select all" is unchecked then check all rows
    if (event.target.checked) {
      setSelectedRows(tableData.data);
    } else {
      // if "select all" is checked then uncheck all rows
      setSelectedRows([]);
    }
  };

  const handleChangePage = (event, newPage) => {
    setDisplayedTableData(
      tableData.data.slice(
        newPage * rowsPerPage,
        newPage * rowsPerPage + rowsPerPage
      )
    );
    setCurTablePage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurTablePage(0);
  };

  const [showAlertPopup, setShowAlertPopup] = useState(false);
  const [messageInfo, setMessageInfo] = useState(
    {
      message: "",
      severity: null,
    }
  )

  const handleAlertClosePopup = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowAlertPopup(false);
  };


  return (
    <Fragment>
      {/* If there's an error fetching the data */}
      {error && (
        <div style={styles.spinnerAndText}>
          <DefaultText text={error.toString()} />
        </div>
      )}

      {/* default message to show if the user hasnt made a query */}
      {!error && !tableData && !isLoading && (
        <div style={styles.spinnerAndText}>
          <DefaultText text="Apply some filters" />
        </div>
      )}

      {/* if the query is complete or query in progress show the table */}
      {!error && (tableData || isLoading) && (
        <Fragment>
          <TableContainer sx={styles.container}>
            {/* if query in progress show loading spinner */}
            {isLoading && (
              <div style={styles.spinnerAndText}>
                <LoadingIcon maxWidth={`250px`}></LoadingIcon>
              </div>
            )}
            {/* if the query completed but the table is empty */}
            {!isLoading && (displayedTableData[0] === null) && (
              <div style={styles.spinnerAndText}>
                <DefaultText text="Data Not Available" />
              </div>
            )}
            {/* if query completed and table is not empty */}
            {!isLoading && (displayedTableData[0] !== null) && (
              <Table stickyHeader aria-label="sticky table" size="small">
                <TableHead sx={styles.headerCell}>
                  <TableRow>
                    {displayedTableData?.length !== 0 && (
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          indeterminate={
                            selectedRows.length > 0 &&
                            selectedRows.length < tableData.data.length
                          }
                          checked={
                            tableData.data.length > 0 &&
                            selectedRows.length === tableData.data.length
                          }
                          onChange={handleSelectAllRows}
                        />
                      </TableCell>
                    )}
                    {columns.map((column) => (
                      <TableCell
                        sx={styles.headerCell}
                        key={column.key}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.sortable ? (
                          <TableSortLabel
                            active={sortCol === column.key}
                            direction={sortDirection}
                            onClick={() => {
                              sortTableData(column.key);
                              setSortCol(column.key);
                            }}
                          >
                            {column.label}
                          </TableSortLabel>
                        ) : (
                          <div>{column.label}</div>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {displayedTableData?.map((row) => {
                    return (
                      <TableRow
                        sx={selectedRowIndex === row[rowKey]
                          ? styles.selected : styles.tableRow}
                        key={row[rowKey]}
                        tabIndex={-1}
                        onClick={(event) => {
                          setSelectedRowIndex(row[rowKey]);
                        }}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={selectedRows
                              .map((item) => item[rowKey])
                              .includes(row[rowKey])}
                            onChange={() => {
                              handleCheckBoxClick(row);
                            }}
                          />
                        </TableCell>

                        {columns.map((column) => {
                          if (column.key === "mdc_linkage") {
                            return (
                              <TableCell
                                key={column.key}
                                align={column.align}
                                sx={styles.tableCell}
                              >
                                <MdpNavButton
                                  hasMdpAccess={hasMdpAccess}
                                  ataList={ataList}
                                  fleetList={fleetList}
                                  route={"/orca/Specialist"}
                                  data={row}
                                />
                              </TableCell>
                            );
                          }

                          const value = row[column.key];

                          return (
                            <TableCell
                              align={column.align}
                              sx={styles.tableCell}
                              key={column.key}
                            >
                              {value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            )}
          </TableContainer>

          <div style={styles.paginationContainer}>
            <div style={{ textAlign: "right", marginLeft: "15px" }}>
              <CsvExportButton
                tableData={selectedRows.map(
                    ({ id, operator_code, ...rest }) => rest)}
                hasTableData={tableData.data.length !== 0}
                fileName={"SpecialistDataExport"}
                setMessageInfo={setMessageInfo}
                setShowAlertPopup={setShowAlertPopup}
              />
            </div>

            <TablePagination
              sx={styles.pagination}
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              component="div"
              count={tableData.data?.length || 0}
              rowsPerPage={rowsPerPage}
              page={curTablePage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>

          <Snackbar
            anchorOrigin={styles.snackBar}
            open={showAlertPopup}
            autoHideDuration={3000}
            onClose={(event, reason) => handleAlertClosePopup(event, reason)}
          >
            <MuiAlert
              onClose={(event, reason) => handleAlertClosePopup(event, reason)}
              severity={messageInfo.severity}
              elevation={6}
              variant="filled"
            >
              {messageInfo.message}
            </MuiAlert>
          </Snackbar>
        </Fragment>
      )}
    </Fragment>
  );
};
