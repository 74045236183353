import React, { Fragment } from "react";
import { Tooltip, Button } from "@mui/material";

export const EntryReassignButton = (props) => {
  const {
    hasTableData,
    selectedRows,
    setShowAlertPopup,
    setMessageInfo,
    setIsPopupOpen,
  } = props;

  const styles = ({
    tableIconButton: {
      padding: "3 10 3 10",
      margin: "0 0 0 0",
      backgroundColor: "rgba(202, 204, 206, 0.38)",
      color: "black",
      "&:hover": {
        backgroundColor: "rgba(202, 204, 206)",
      },
    },
  });

  const handleClick = () => {
    // if no data is selected to download
    if (hasTableData && selectedRows.length !== 1) {
      setMessageInfo({
        message: "Have to select one row of data",
        severity: "error",
      });
      setShowAlertPopup(true);
      // if no search performed
    } else if (selectedRows.length !== 1) {
      setMessageInfo({
        message: "A valid search must be performed first",
        severity: "error",
      });
      setShowAlertPopup(true);
    } else {
      setIsPopupOpen(true);
    }
  };

  return (
    <Fragment>
      <Tooltip title="Reassign Entry">
        <Button
          sx={styles.tableIconButton}
          variant="contained"
          disableElevation
          size="small"
          onClick={handleClick}
        >
          Reassign
        </Button>
      </Tooltip>
    </Fragment>
  );
};
