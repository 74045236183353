import React from 'react';
import bgImage from 'assets/background.jpg';
import { apiFetcher } from "utils/apiUtils";
import { config } from "config/config";
import { SourceMapConsumer } from 'source-map-js';
import { Button } from '@mui/material';

// Initialize source map
let sourceMaps = {};
async function getSourceMapFromUri (uri) {
  if (sourceMaps[uri] !== undefined) {
    return sourceMaps[uri];
  }
  // Search URL with given URI within stack trace
  const uriQuery = new URL(uri).search;
  const currentScriptContent = await (await fetch(uri)).text();
  // Map the location of error with current url
  let mapUri = /\/\/# sourceMappingURL=(.*)/.exec(currentScriptContent)[1];
  mapUri = new URL(mapUri, uri).href + uriQuery;

  const map = await (await fetch(mapUri)).json();

  sourceMaps[uri] = map;

  return map;
}

async function mapStackTrace (stack) {
  const stackLines = stack.split('\n');
  const mappedStack = [];

  for (const line of stackLines) {
    const match = /(.*)(https?:\/\/.*):(\d+):(\d+)/.exec(line);
    if (match == null) {
      mappedStack.push(line);
      continue;
    }

    const uri = match[2];
    // Get original stack trace error location
    const consumer = new SourceMapConsumer(await getSourceMapFromUri(uri));

    // Provide line and column of error
    const originalPosition = consumer.originalPositionFor({
      line: parseInt(match[3]),
      column: parseInt(match[4]),
    });

    // If can not find original stack trace line, push the minified stace trace line
    if (originalPosition.source == null || originalPosition.line == null || originalPosition.column == null) {
      mappedStack.push(line);
      continue;
    }

    mappedStack.push(`${originalPosition.source}:${originalPosition.line}:${originalPosition.column + 1}`);
  }

  return mappedStack.join('\n');
}
function getStateInfo (stackTrace, componentStates) {
  let parentComponents = ["/TimelineTab/", "/AlertsTab/", "/ReviewTab/", "/SpltTab/", "/FleetTab/", "/MessagesTab/", "/EqIDLookupTab/", "/FlightDeckEffectsTab/", "/RawDataTab/"];
  // filter only parent components that are included in stack trace
  parentComponents = parentComponents.filter(str => stackTrace.includes(str));

  let msg = '';
  if (parentComponents.includes("/TimelineTab/")) {
    msg += 'timelineFilterState: ' + JSON.stringify(componentStates.timelineFilterState) + '\n--------------------------------------\n';
  }
  if (parentComponents.includes("/AlertsTab/")) {
    msg += 'alertsFilterState: ' + JSON.stringify(componentStates.alertsFilterState) + '\n--------------------------------------\n';
  }
  if (parentComponents.includes("/ReviewTab/")) {
    msg += 'reviewTableState: ' + JSON.stringify(componentStates.reviewTableState) + '\n--------------------------------------\n';
  }
  if (parentComponents.includes("/SpltTab/")) {
    msg += 'spltFilterState: ' + JSON.stringify(componentStates.spltFilterState) + '\n--------------------------------------\n';
  }
  if (parentComponents.includes("/FleetTab/")) {
    msg += 'messageFilterState: ' + JSON.stringify(componentStates.messageFilterState) + '\n--------------------------------------\n';
  }
  if (parentComponents.includes("/MessagesTab/")) {
    msg += 'messageFilterState: ' + JSON.stringify(componentStates.messageFilterState) + '\n--------------------------------------\n';
  }
  if (parentComponents.includes("/EqIDLookupTab/")) {
    msg += 'eqIDFilterState: ' + JSON.stringify(componentStates.eqIDFilterState) + '\n--------------------------------------\n';
  }
  if (parentComponents.includes("/FlightDeckEffectsTab/")) {
    msg += 'flightDeckEffectsFilterState: ' + JSON.stringify(componentStates.flightDeckEffectsFilterState) + '\n--------------------------------------\n';
  }
  if (parentComponents.includes("/RawDataTab/")) {
    msg += 'rawDataFilterState: ' + JSON.stringify(componentStates.rawDataFilterState) + '\n--------------------------------------\n';
  }

  return msg;
}


function getFormattedDate () {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const day = String(currentDate.getDate()).padStart(2, '0');
  const hours = String(currentDate.getHours()).padStart(2, '0');
  const minutes = String(currentDate.getMinutes()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}`;
}

export class ErrorBoundary extends React.Component {
  constructor (props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch (error, errorInfo) {
    this.setState({
      error,
      errorInfo
    });

    const { componentStates } = this.props;
    this.sendErrorEmail(error, errorInfo, componentStates);
  }

  sendErrorEmail = async (error, errorInfo, componentStates) => {
    // Format the minified stack trace to the original stack trace
    let mappedStackTrace;
    try {
      mappedStackTrace = await mapStackTrace(`${error.stack.toString()}` +
        `\n-----------------------------------------------------------` +
        `\nExtra Stack Information:` +
        `\n${errorInfo.componentStack.toString()}`);

        sourceMaps = {}
    } catch (e) {
      console.log("Could not format stack trace: ", e);
      mappedStackTrace = `${error.stack.toString}` +
        `\n-----------------------------------------------------------` +
        `\nExtra Stack Information:` +
        `\n${errorInfo.componentStack.toString()}`;
    }
    const formattedDate = getFormattedDate();
    // Send stack trace to backend
    apiFetcher(
      `${config.apiURL}/dna/ebemail`,
      "POST",
      {
        subject: this.props.returnName + " ErrorBoundary Caught - " + formattedDate,
        cc: "",
        date: "Date: " + formattedDate,
        stackTrace: mappedStackTrace + "\n=================================\n" + getStateInfo(mappedStackTrace, componentStates),
      },
      {
        "Content-Type": "application/json",
      }
    )
    .then((response) => {
      console.log("Error email sent successfully");
    })
    .catch((e) => {
      console.log("Error sending email:", e);
    });
  }

  render () {
    const styles = {
      container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundImage: `url(${bgImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      },
      info_box: {
        padding: '20px',
        borderRadius: '10px',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        maxWidth: '600px',
        textAlign: 'center',
      },
      heading: {
        fontSize: '24px',
        color: '#333',
      },
      paragraph: {
        fontSize: '16px',
        color: '#666',
      },
      button: {
        display: 'inline-block',
        padding: '10px 20px',
        borderRadius: '20px',
        backgroundColor: 'white',
        color: 'black',
        textDecoration: 'none',
        marginTop: '10px',
        border: '1px solid black',
      }
    };

    if (this.state.errorInfo) {
      // Error page
      return (
        <div style={styles.container}>
          <div style={styles.info_box}>
            <h1 style={styles.heading}>Oops! Something went wrong.</h1>
            <p style={styles.paragraph}>We apologize for the inconvenience. Our team has been notified.</p>
            <Button
              sx={styles.button}
              onClick= {() => { window.location.href = this.props.returnPath }}
            >{" Return to " + this.props.returnName + " "}
            </Button>
          </div>
        </div>
      );
    }
    // Otherwise, display children normally
    return this.props.children;
  }
}
