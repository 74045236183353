import React, { Fragment, useState, useContext, useEffect } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { config } from 'config/config';
import { useFetch } from 'utils/apiUtils';
import { SpltFiltersContext } from 'orca/contexts/SpltFiltersContext';
import { SpltDataContext } from 'orca/contexts/SpltDataContext';
import { FiltersPanel } from 'orca/components/Miscellaneous/FiltersPanel';
import { DateTimeSelect } from 'orca/components/Miscellaneous/DateTimeSelect';
import { SelectBox } from 'orca/components/Miscellaneous/SelectBox';
import { SelectBoxATA } from 'orca/components/Miscellaneous/SelectBoxATA';
import { CheckBoxSelect } from 'orca/components/Miscellaneous/CheckBoxSelect';


const formatFiltersState = (filterState) => {
  // apply these formatting before the search is sent to backend
  if (filterState.acModel.length) {
    return {
      ...filterState,
      acModel: filterState.acModel === config.CRJ ? 'CRJ' : 'CRJ700',
      acsn: filterState.acsn.map((i) => i.Value),
      actn: filterState.actn.map((i) => {
        // for SkyWest, add 'N' as prefix to tail numm
        if (i.OPERATOR_CODE === "SKW" && /^[0-9]{3}[A-Z]{2}$/.test(i.Value)) {
          return "N" + i.Value;
        }
        return i.Value;
      }),
      ata: filterState.ata.map((i) => i.Value),
      messageType: filterState.messageType.map((i) => i.Value),
      chronic: filterState.chronic.map((i) => i.Value),
      category: filterState.category.map((i) => i.Value),
      fleets: filterState.fleets.map(item => item.Value),
    };
  } else {
    return filterState;
  }
};


export const SpltFiltersPanel = (props) => {
  const styles = ({
    accordianRoot: {
      boxShadow: 'none',
    },
    accordianLabel: {
      fontSize: 16,
    },
  });

  const {
    spltFilterState,
    setSpltFilterState,
    setAppliedSpltFilters,
    defaultSpltFilterState,
    setCurTablePage,
    mimicApplyClick,
    setMimicApplyClick,
    setSelectedRows,
    setSelectedRowIndex,
    setSortDirection,
    setSortCol,
  } = useContext(SpltFiltersContext);

  const {
    setSpltTableState,
    isLoading,
    setIsLoading,
    setError,
  } = useContext(SpltDataContext);

  const [shouldValidateFilters, setShouldValidateFilters] = useState(false);

  // fetchs filter
  const { data: fleetList, error: fleetListError } = useFetch(
      `${config.apiURL}/orca/filteroptions/oprname`,
      'GET',
  );
  const { data: acsnList, error: acsnListError } = useFetch(
      `${config.apiURL}/orca/filteroptions/acsn`,
      'GET',
  );
  const { data: actnList, error: actnListError } = useFetch(
    `${config.apiURL}/orca/filteroptions/actn`,
    'GET',
);
  const { data: ataList, error: ataListError } = useFetch(
      `${config.apiURL}/orca/filteroptions/ata`,
      'GET',
  );
  const { data: messageTypeList, error: messageTypeListError } = useFetch(
      `${config.apiURL}/orca/filteroptions/messagetype`,
      'GET',
  );
  const chronicTypeList = [{ Value: 'Yes' }, { Value: 'No' }];
  const categoryList = [{ Value: 'WatchList' }, { Value: 'Non WatchList' }];

  // check filter loading status
  const isFiltersLoading = () => {
    const isLoading = Boolean(
        (!fleetList.oprData || !acsnList.data || !actnList.data ||
        !ataList.data || !messageTypeList) &&
              !fleetListError &&
              !acsnListError &&
              !actnListError &&
              !ataListError &&
              !messageTypeListError,
    );
    return isLoading;
  };

  const isFiltersFetchError = () => {
    return Boolean(fleetListError || acsnListError || actnListError ||
    ataListError || messageTypeListError);
  };

  const isFiltersValid = () => {
  // make sure the date/time and ac model filters arent empty
  // before starting a query
    return Boolean(
        spltFilterState.startDate &&
      spltFilterState.endDate &&
      spltFilterState.acModel.length,
    );
  };

  // hide fleet filter for external users
  const [showFleetTab, setShowFleetTab] = useState(false);
  useEffect(() => {
    if (fleetList.oprData &&
    Object.keys(fleetList.oprData).length === 1) {
      setShowFleetTab(false);
      // set fleet in filter state automatically
      setSpltFilterState({
        ...spltFilterState,
        fleets: Object.keys(fleetList.oprData),
      });
    } else {
      setShowFleetTab(true);
    }
  }, [fleetList.oprData]);

  const filterOptionsByACModel = (options) => {
    /*
    @props <options:list>: list of dropdown options
    [{value:"",AC_MODEL:""},{value:"",AC_MODEL:""},...]

    @return <list>: returns the options list filtered on the
    acmodel that the user currently has selected. Returns empty list
    if no ac model is selected
    */
    if (spltFilterState.acModel.length && options.data) {
      // our database uses CRJ and CRJ700 to identify CRJ200 and
      // CRJ700/900/1000 respectively, when displying models to the
      // user, make sure to use CRJ200 and CRJ700/900/1000

      return options.data.reduce(
          (i, j) => {
            if (j.AC_MODEL === (
              spltFilterState.acModel === config.CRJ ? 'CRJ' : 'CRJ700'
            )) {
              i.push({ ...j, AC_MODEL: spltFilterState.acModel });
            }
            return i
          }, []);
    } else {
      return [];
    }
  };

  const filterOptionsByFleet = (options, exclude) => {
    /*
    @props <options:list>: list of dropdown options
    [{ACSN/ACTN:"",AC_MODEL:"", OPERATOR_CODE:""},
    {ACSN/ACTN:"",AC_MODEL:"", OPERATOR_CODE:""},...]
    @props <exclude:boolean>: dictates if were excluding options by fleet

    @return <list>: returns the options list filtered on the
    fleets that the user currently has selected. Returns original list
    if no fleets is selected
    */
    if (spltFilterState.fleets.length && options) {
      const transformedFleetArray = spltFilterState.fleets.map(item => item.Value);
      const validOprcodes = transformedFleetArray.map(
        (oprname) => fleetList.oprData[oprname]
      );
      if (exclude) {
        options = options.filter(
          (option) => !validOprcodes.includes(option.OPERATOR_CODE)
        );
      } else {
        options = options.filter((option) =>
          validOprcodes.includes(option.OPERATOR_CODE)
        );
      }
    }
    return options;
  };

  const formatATA = (list) => {
    const comp = (a, b) => {
      if (a.Value < b.Value) {
        return -1;
      }
      if (a.Value > b.Value) {
        return 1;
      }
      return 0;
    };
    const compChapter = (a, b) => {
      if (a.chapter < b.chapter) {
        return -1;
      }
      if (a.chapter > b.chapter) {
        return 1;
      }
      return 0;
    };
    const ATAParentList = [];
    const newList = list.sort(comp).map((item) => {
      const chapter = item.Value.split('-')[0];
      if (!ATAParentList.includes(chapter)) {
        ATAParentList.push(chapter);
      }
      const newItem = item;
      if (item.DESCRIPTION && item.DESCRIPTION !== 'UNKNOWN') {
        newItem.label = `${item.Value}: ${item.DESCRIPTION || ''}`;
      } else {
        newItem.label = item.Value;
      }
      return { ...item, chapter };
    });

    const newATAParentList = ATAParentList.map((item) => {
      return {
        chapter: item,
        AC_MODEL: newList[0].AC_MODEL,
        wholeChapter: true,
        label: `Select all ${item}`,
      };
    });
    return [...newATAParentList, ...newList].sort(compChapter);
  };

  const transformFleetObject = (obj) => {
    return Object.keys(obj).map(key => ({
      Value: key
    }));
  };

  return (
    <FiltersPanel
      applyURL={`${config.apiURL}/orca/specialist`}
      applyCallback={() => {
        setSelectedRows([]);
        setSelectedRowIndex(null);
        setSortDirection("asc");
        setSortCol(null);
      }}
      filterStateName="spltFilterState"
      filterState={spltFilterState}
      setFilterState={setSpltFilterState}
      formatFilters={formatFiltersState}
      setAppliedFilters={setAppliedSpltFilters}
      defaultFilterState={defaultSpltFilterState}
      isFiltersLoading={isFiltersLoading()}
      isFiltersFetchError={isFiltersFetchError()}
      isFiltersValid={isFiltersValid()}
      isTableDataLoading={isLoading}
      setIsTableDataLoading={setIsLoading}
      setTableDataState={setSpltTableState}
      setError={setError}
      setCurTablePage={setCurTablePage}
      shouldValidateFilters={shouldValidateFilters}
      setShouldValidateFilters={setShouldValidateFilters}
      setMimicApplyClick={setMimicApplyClick}
      mimicApplyClick={mimicApplyClick}
      popupText="Date/Time and Aircraft Model filters cannot be empty!"
    >
      {!isFiltersLoading() && !isFiltersFetchError() && (
        <Fragment>
          <Accordion sx={styles.accordianRoot}>
            <AccordionSummary
              sx={styles.accordianLabel}
              expandIcon={<ExpandMoreIcon color="primary" />}
            >
              Date (UTC)
            </AccordionSummary>
            <AccordionDetails>
              <DateTimeSelect
                includeTime={false}
                filterState={spltFilterState}
                shouldValidate={false}
              ></DateTimeSelect>
            </AccordionDetails>
          </Accordion>

          <Accordion sx={styles.accordianRoot}>
            <AccordionSummary
              sx={styles.accordianLabel}
              expandIcon={<ExpandMoreIcon color="primary" />}>
              Aircraft Model
            </AccordionSummary>
            <AccordionDetails>
              <CheckBoxSelect
                filterState={spltFilterState}
                options={[config.CRJ, config.CRJ700]}
                filterName="acModel"
                shouldValidate={shouldValidateFilters}
              />
            </AccordionDetails>
          </Accordion>

          {showFleetTab && (<Accordion sx={styles.accordianRoot}>
            <AccordionSummary
              sx={styles.accordianLabel}
              expandIcon={<ExpandMoreIcon color="primary" />}>
              Fleet
            </AccordionSummary>
            <AccordionDetails>
              <SelectBox
                multiple
                selectOptions={transformFleetObject(fleetList.oprData)}
                filterState={spltFilterState}
                setFilterState={setSpltFilterState}
                filterName="fleets"
                excludeName="excludeFleets"
                setAllFilters={true}
              />
            </AccordionDetails>
          </Accordion>)}

          <Accordion sx={styles.accordianRoot}>
            <AccordionSummary
              sx={styles.accordianLabel}
              expandIcon={<ExpandMoreIcon color="primary" />}>
              ATA
            </AccordionSummary>
            <AccordionDetails>
              <SelectBoxATA
                multiple
                groupName="chapter"
                groupOptionLabel="label"
                selectAll="wholeChapter"
                noOptionsText={
                  spltFilterState.acModel.length
                  ? 'No options' : 'Select an AC Model first'
                }
                selectOptions={formatATA(filterOptionsByACModel(ataList))}
                filterState={spltFilterState}
                setFilterState={setSpltFilterState}
                filterName="ata"
                excludeName="excludeAta"
                setAllFilters={true}
              />
            </AccordionDetails>
          </Accordion>

          <Accordion sx={styles.accordianRoot}>
            <AccordionSummary
              sx={styles.accordianLabel}
              expandIcon={<ExpandMoreIcon color="primary" />}
            >
              Aircraft Serial Number
            </AccordionSummary>
            <AccordionDetails>
              {acsnList.data && (
                <SelectBox
                  multiple
                  groupName="AC_MODEL"
                  groupOptionLabel="Value"
                  noOptionsText={
                    spltFilterState.acModel.length
                      ? 'No options' : 'Select an AC Model first'
                  }
                  selectOptions={filterOptionsByFleet(
                    filterOptionsByACModel(acsnList),
                    spltFilterState.excludeFleets)}
                  filterState={spltFilterState}
                  setFilterState={setSpltFilterState}
                  filterName="acsn"
                  excludeName="excludeAcsn"
                  setAllFilters={true}
                />
              )}
            </AccordionDetails>
          </Accordion>

          <Accordion sx={styles.accordianRoot}>
            <AccordionSummary
              sx={styles.accordianLabel}
              expandIcon={<ExpandMoreIcon color="primary" />}
            >
              Aircraft Tail Number
            </AccordionSummary>
            <AccordionDetails>
              {actnList.data && (
                <SelectBox
                  multiple
                  groupName="AC_MODEL"
                  groupOptionLabel="Value"
                  noOptionsText={spltFilterState.acModel.length
                    ? "No options" : "Select an AC Model first"}
                  selectOptions={filterOptionsByFleet(
                    filterOptionsByACModel(actnList),
                    spltFilterState.excludeFleets,
                  )}
                  filterState={spltFilterState}
                  setFilterState={setSpltFilterState}
                  filterName="actn"
                  excludeName="excludeActn"
                  setAllFilters={true}
                />
              )}
            </AccordionDetails>
          </Accordion>

          <Accordion sx={styles.accordianRoot}>
            <AccordionSummary
              sx={styles.accordianLabel}
              expandIcon={<ExpandMoreIcon color="primary" />}
            >
              Report Type
            </AccordionSummary>
            <AccordionDetails>
              <SelectBox
                multiple
                groupName="AC_MODEL"
                groupOptionLabel="Value"
                noOptionsText={spltFilterState.acModel.length
                  ? 'No options' : 'Select an AC Model first'
                }
                selectOptions={filterOptionsByACModel(messageTypeList)}
                filterState={spltFilterState}
                setFilterState={setSpltFilterState}
                filterName="messageType"
                excludeName="excludeMessageTypes"
                setAllFilters={true}
              />
            </AccordionDetails>
          </Accordion>

          <Accordion sx={styles.accordianRoot}>
            <AccordionSummary
              sx={styles.accordianLabel}
              expandIcon={<ExpandMoreIcon color="primary" />}
            >
              Repetitive Snags
            </AccordionSummary>
            <AccordionDetails>
              <SelectBox
                multiple
                groupName="AC_MODEL"
                groupOptionLabel="Value"
                selectOptions={chronicTypeList}
                filterState={spltFilterState}
                setFilterState={setSpltFilterState}
                filterName="chronic"
                excludeName="excludeChronic"
              />
            </AccordionDetails>
          </Accordion>

          <Accordion sx={styles.accordianRoot}>
            <AccordionSummary
              sx={styles.accordianLabel}
              expandIcon={<ExpandMoreIcon color="primary" />}
            >
              Category
            </AccordionSummary>
            <AccordionDetails>
              <SelectBox
                multiple
                groupName="AC_MODEL"
                groupOptionLabel="Value"
                selectOptions={categoryList}
                filterState={spltFilterState}
                setFilterState={setSpltFilterState}
                filterName="category"
                excludeName="excludeCategory"
              />
            </AccordionDetails>
          </Accordion>
        </Fragment>
      )}
    </FiltersPanel>
  );
};
