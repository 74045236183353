import React, { Component } from 'react';
import { BrowserRouter as Router, withRouter, Route, Switch } from 'react-router-dom';
import { Security, LoginCallback, SecureRoute } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { config } from 'config/config';
import { AppRouter } from 'AppRouter';


class App extends Component {
  constructor (props) {
    super(props);

    this.oktaAuth = new OktaAuth({
      issuer: config.issuer,
      clientId: config.clientId,
      redirectUri: `${window.location.origin}/login/callback`,
    });

    this.restoreOriginalUri = async (_oktaAuth, originalUri) => {
      props.history.replace(toRelativeUrl(originalUri, window.location.origin));
    };
  }

  render () {
    return (
      <Security
        oktaAuth={this.oktaAuth}
        restoreOriginalUri={this.restoreOriginalUri}
      >
        <Switch>
          <Route path="/login/callback" component={LoginCallback} />
          <SecureRoute path="/" component={AppRouter} />
        </Switch>
      </Security>
    );
  }
}

const AppWithRouterAccess = withRouter(App);

const RouterApp = () => {
  return (
    <Router>
      <AppWithRouterAccess />
    </Router>
  );
};

export default RouterApp;
