/* eslint-disable max-len */
import React from 'react';
import Card from '@mui/material/Card';
import { FlightDeckEffectsTablePanel } from 'mdp/components/FlightDeckEffectsTab/FlightDeckEffectsTable/FlightDeckEffectsTablePanel';
import { FlightDeckEffectsFiltersPanel } from 'mdp/components/FlightDeckEffectsTab/Filters/FlightDeckEffectsFiltersPanel';
import { FlightDeckEffectsDetails } from 'mdp/components/FlightDeckEffectsTab/FlightDeckEffectsDetails/FlightDeckEffectsDetails';

export const FlightDeckEffectsTab = (props) => {
  return (
    <div className="threePanelTab">
      <div className="filtersPanel">
        <Card className='threePanelTabCard'>
          <FlightDeckEffectsFiltersPanel />
        </Card>
      </div>
      <div className="tablePanel">
        <Card className='threePanelTabCard'>
          <FlightDeckEffectsTablePanel />
        </Card>
      </div>
      <div className={'detailsPanel'}>
        <Card className='threePanelTabCard'>
          <FlightDeckEffectsDetails />
        </Card>
      </div>
    </div>
  );
};
