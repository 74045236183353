import React, { Fragment, useContext, useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { CircularProgress, TextField, IconButton } from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { StaticTextField } from "mdp/components/Miscellaneous/StaticTextField";
import { DefaultText } from "mdp/components/Miscellaneous/DefaultText";
import { MessageFiltersContext } from "mdp/contexts/MessageFiltersContext";
import { apiFetcher } from "utils/apiUtils";
import { config } from "config/config";

export const DetailsPanelDetailsTab = (props) => {
  const {
    panelState,
    selectedEQID,
    setSelectedEQID,
    isMessageDetailsLoading,
    messageDetailsError,
  } = props;

  const styles = {
    iconButtons: {
      padding: '0 0 0 0',
      margin: '0 10px 0 10px',
      height: '100%',
    },
  }

  const { appliedMessageFilters } = useContext(MessageFiltersContext);

  const [eicas, setEicas] = useState();
  const [originalEicas, setOriginalEicas] = useState();
  const [isEicasUpdating, setIsEicasUpdating] = useState(false);

  const handleEdit = (event) => {
    setEicas(event.target.value);
  }

  const onResetClick = () => {
    setEicas(originalEicas);
  };

  const onSaveClick = () => {
    setIsEicasUpdating(true)
    apiFetcher(
      `${config.apiURL}/mdp/specialistInput`,
      "POST",
      {
        eicas,
        selectedEQID,
      },
      { "Content-Type": "application/json" }
    ).then(() => {
      setIsEicasUpdating(false)
    });
    setOriginalEicas(eicas);
  };

  useEffect(() => {
    setEicas(panelState.msg_status ||
      panelState?.EICAS_MESSAGE?.filter(
        (i) => i.EQ_ID === selectedEQID
      )[0]?.MESSAGE ||
      "");
    setOriginalEicas(panelState.msg_status ||
      panelState?.EICAS_MESSAGE?.filter(
        (i) => i.EQ_ID === selectedEQID
      )[0]?.MESSAGE ||
      "");
  }, [panelState?.EICAS_MESSAGE]);

  // const [isEmailPopupExpanded, setIsEmailPopupExpanded] = useState(false)

  return (
    <Fragment>
      {/* If there's an error fetching the data */}
      {messageDetailsError ? (
        <div>
          <DefaultText text={messageDetailsError.toString()} />
        </div>
      ) : null}
      {/* if data is loading show spinner */}
      {isMessageDetailsLoading ? (
        <div>
          <CircularProgress color="primary" />
        </div>
      ) : null}
      {/* show data if not error and not loading */}
      {!isMessageDetailsLoading && !messageDetailsError ? (
        <div>
          <Grid container sx={{ marginBottom: '7px' }} spacing={3}>
            <Grid item xs={3}>
              <StaticTextField
                fieldLabel="Aircraft"
                fieldValue={panelState.AC_SN || ""}
              />
            </Grid>
            <Grid item xs={4}>
              <StaticTextField
                fieldLabel="Fleet"
                fieldValue={panelState.OPERATOR || ""}
              />
            </Grid>
            <Grid item xs>
              <StaticTextField
                fieldLabel="Timestamp"
                fieldValue={panelState.MSG_Date || ""}
              />
            </Grid>
          </Grid>
          <Grid container sx={{ marginBottom: '7px' }} spacing={3}>
            <Grid item xs={4}>
              <StaticTextField
                fieldLabel="Flight Leg"
                fieldValue={panelState.FLIGHT_LEG || ""}
                labelSmall
              />
            </Grid>
            {appliedMessageFilters?.acModel === "CRJ700/900/1000" && (
              <Grid item xs>
                <StaticTextField
                  fieldLabel="Flight Number"
                  fieldValue={panelState.FLIGHT_NUM || ""}
                  labelSmall
                />
              </Grid>
            )}
            {appliedMessageFilters?.acModel === "CRJ700/900/1000" && (
              <Grid item xs={4}>
                <StaticTextField
                  fieldLabel="Flight Phase"
                  fieldValue={panelState.FLIGHT_PHASE || ""}
                  labelSmall
                />
              </Grid>
            )}
          </Grid>
          <Grid container sx={{ marginBottom: '7px' }} spacing={3}>
            <Grid item xs>
              <StaticTextField
                fieldLabel="Message"
                fieldValue={panelState.MSG_TXT || ""}
              />
            </Grid>
            <Grid item xs={4}>
              <StaticTextField
                fieldLabel="Message Type"
                fieldValue={panelState.MSG_TYPE || ""}
                labelSmall
              />
            </Grid>
          </Grid>
          <Grid container sx={{ marginBottom: '7px' }} spacing={3} alignItems={"center"}>
            <Grid item xs>
              <TextField
                sx={{ width: '100%' }}
                variant="standard"
                label="EICAS Message"
                value={eicas}
                onChange={panelState?.IS_SPECIALIST ? handleEdit : null}
              />
            </Grid>
            {panelState?.IS_SPECIALIST ? (
              <Grid item xl>
                <span style={{ display: 'flex', alignItems: 'center', height: '100%', justifyContent: 'flex-end' }}>
                  { isEicasUpdating ? <CircularProgress sx={styles.iconButtons}/>
                    : <>
                      <IconButton sx={styles.iconButtons}
                      disabled={isEicasUpdating}
                      onClick={() => onResetClick()}
                      size="large">
                        <RestartAltIcon/>
                      </IconButton>
                      <IconButton sx={styles.iconButtons}
                      disabled={isEicasUpdating}
                      onClick={() => onSaveClick()}
                        size="large">
                        <SaveIcon/>
                      </IconButton>
                    </>
                  }
                </span>
              </Grid>
            ) : null}
          </Grid>
          <Grid container sx={{ marginBottom: '7px' }} spacing={3}>
            <Grid item xs={2}>
              <StaticTextField
                fieldLabel="ATA"
                fieldValue={panelState.ATA || ""}
              />
            </Grid>
            <Grid item xs>
              <StaticTextField
                fieldLabel="LRU"
                fieldValue={panelState.LRU || ""}
              />
            </Grid>
            <Grid item xs={3}>
              <StaticTextField
                fieldLabel="Intermittent"
                fieldValue={panelState.INTERMITNT || ""}
                labelSmall
              />
            </Grid>
          </Grid>
          <Grid container sx={{ marginBottom: '7px' }} spacing={3}>
            <Grid item xs>
              <StaticTextField
                fieldLabel="Equation ID"
                sx={{ fontSize: '11px' }}
                fieldValue={selectedEQID}
                onSelect={(e) => setSelectedEQID(e.target.value)}
                options={panelState?.EQ_INFO?.map((i) => i.EQ_ID) || []}
                dropDown
                labelSmall
              />
            </Grid>
            {appliedMessageFilters?.acModel === "CRJ700" && (
              <Grid item xs={4}>
                <StaticTextField
                  fieldLabel="Component ID"
                  fieldValue={panelState.COMP_ID || ""}
                  labelSmall
                />
              </Grid>
            )}
            <Grid item xs={3}>
              <StaticTextField
                fieldLabel="Timer"
                fieldValue={
                  panelState?.EQ_INFO?.filter(
                    (i) => i.EQ_ID === selectedEQID
                  )[0]?.TIMER || ""
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={5}>
              <StaticTextField
                fieldLabel="Status"
                fieldValue={
                  panelState.msg_status ||
                  panelState?.EQ_INFO?.filter(
                    (i) => i.EQ_ID === selectedEQID
                  )[0]?.STATUS ||
                  ""
                }
              />
            </Grid>
          </Grid>
        </div>
      ) : null}
    </Fragment>
  );
};
