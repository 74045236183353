/* Root component representing the Filter panel */
import React, { Fragment, useState, useContext, useEffect } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SelectBox } from '../../Miscellaneous/SelectBox';
import { CheckBoxSelect } from '../../Miscellaneous/CheckBoxSelect';
import { FiltersPanel } from '../../Miscellaneous/FiltersPanel';
import { FlightDeckEffectsDataContext } from '../../../contexts/FlightDeckEffectsDataContext';
import { FlightDeckEffectsFiltersContext } from '../../../contexts/FlightDeckEffectsFiltersContext';
import { useFetch } from 'utils/apiUtils';
import { config } from 'config/config';

export const formatFiltersState = (filterState) => {
  // apply these formatting before the search is sent to backend
  if (filterState.acModel.length) {
    return {
      ...filterState,
      acModel: filterState.acModel === config.CRJ ? 'CRJ' : 'CRJ700',
      mainAta: filterState.mainAta.map((i) => i.MAIN_ATA),
      subAta: filterState.subAta.map((i) => i.SUB_ATA),
    };
  } else {
    return filterState;
  }
};

export const FlightDeckEffectsFiltersPanel = (props) => {
  const { specialistEditMode } = props;
  const styles = ({
    accordianRoot: {
      boxShadow: 'none',
    },
    accordianLabel: {
      fontSize: 16,
    },
    searchBar: {
      marginTop: '30px',
      width: '100%',
    },
    searchBarText: {
      fontSize: 12,
    },
  });
  // whether filter components should check if their value is empty
  const [shouldValidateFilters, setShouldValidateFilters] = useState(false);
  const {
    setMimicApplyClick,
    mimicApplyClick,
    flightDeckEffectsFilterState,
    setFlightDeckEffectsFilterState,
    setAppliedFlightDeckEffectsFilters,
    defaultFlightDeckEffectsFilterState,
    setCurTablePage,
  } = useContext(FlightDeckEffectsFiltersContext);
  const {
    setFlightDeckEffectsTableState,
    isLoading: isFlightDeckEffectsLoading,
    setIsLoading: setIsFlightDeckEffectsLoading,
    setError,
  } = useContext(FlightDeckEffectsDataContext);

  // {data: LIST({
  //    MAIN_ATA: INT,
  //    MAIN_ATA_DESC: STRING,
  //    AC_MODEL: ENUM(CRJ700, CRJ200)
  //    SUB_ATA_LIST: STRING}
  // )}
  const { data: mainAtaData, error: mainAtaDataError } = useFetch(
      `${config.apiURL}/mdp/flightDeckEffects/ata`,
      'GET',
  );

  // {data: Data({
  //    SUB_ATA: INT,
  //    SUB_ATA_DESC: STRING,
  //    AC_MODEL: ENUM(CRJ700, CRJ200)
  // )}
  const { data: subAtaData, error: subAtaDataError } = useFetch(
      `${config.apiURL}/mdp/flightDeckEffects/subAta`,
      'GET',
  );

  const isFiltersLoading = () => {
    return Boolean(!mainAtaData.data || !subAtaData.data);
  };

  const isFiltersFetchError = () => {
    return Boolean(mainAtaDataError || subAtaDataError);
  };

  const isFiltersValid = () => {
    return Boolean(flightDeckEffectsFilterState.acModel.length);
  };

  const [mainAtaList, setMainAtaList] = useState({ data: [] });
  const [subAtaList, setSubAtaList] = useState({ data: [] });

  useEffect(() => {
    if (mainAtaData && mainAtaData.data) {
      for (let index = 0; index < mainAtaData.data.length; index++) {
        mainAtaData.data[index].SUB_ATA_LIST =
          mainAtaData.data[index].SUB_ATA_LIST.split(',');
        mainAtaData.data[
            index
        // eslint-disable-next-line max-len
        ].Value = `${mainAtaData.data[index].MAIN_ATA} - ${mainAtaData.data[index].MAIN_ATA_DESC}`;
      }
      setMainAtaList(mainAtaData);
    }
  }, [mainAtaData]);

  useEffect(() => {
    if (subAtaData && subAtaData.data) {
      for (let index = 0; index < subAtaData.data.length; index++) {
        subAtaData.data[
            index
        // eslint-disable-next-line max-len
        ].Value = `${subAtaData.data[index].SUB_ATA} - ${subAtaData.data[index].SUB_ATA_DESC}`;
      }
      setSubAtaList(subAtaData);
    }
  }, [subAtaData]);

  const filterSubATAsByMainAta = (options) => {
    if (flightDeckEffectsFilterState.mainAta.length === 0) {
      return options;
    } else {
      const allSubAtaText = flightDeckEffectsFilterState.mainAta.flatMap(
          (ata) => ata.SUB_ATA_LIST,
      );
      if (flightDeckEffectsFilterState.excludeMainAta) {
        options = options.filter(
            (option) => !allSubAtaText.includes(option.SUB_ATA_DESC),
        );
      } else {
        options = options.filter((option) =>
          allSubAtaText.includes(option.SUB_ATA_DESC),
        );
      }
      return options;
    }
  };

  return (
    <FiltersPanel
      applyURL={`${config.apiURL}/mdp/flightDeckEffects?offset=0&limit=${config.initialFetchLimit}`}
      filterStateName="FlightDeckEffectsFilterState"
      filterState={flightDeckEffectsFilterState}
      setFilterState={setFlightDeckEffectsFilterState}
      formatFilters={formatFiltersState}
      setAppliedFilters={setAppliedFlightDeckEffectsFilters}
      defaultFilterState={defaultFlightDeckEffectsFilterState}
      isFiltersLoading={isFiltersLoading()}
      isFiltersFetchError={isFiltersFetchError()}
      isFiltersValid={isFiltersValid()}
      isTableDataLoading={isFlightDeckEffectsLoading}
      setIsTableDataLoading={setIsFlightDeckEffectsLoading}
      setTableDataState={setFlightDeckEffectsTableState}
      setError={setError}
      setCurTablePage={setCurTablePage}
      shouldValidateFilters={shouldValidateFilters}
      setShouldValidateFilters={setShouldValidateFilters}
      setMimicApplyClick={setMimicApplyClick}
      mimicApplyClick={mimicApplyClick}
      popupText="Aircraft Model filters cannot be empty!"
      specialistEditMode={specialistEditMode}
    >
      {!isFiltersLoading() && !isFiltersFetchError() && (
        <Fragment>
          <Accordion sx={styles.accordianRoot}>
            <AccordionSummary
              sx={styles.accordianLabel}
              expandIcon={<ExpandMoreIcon color="primary" />}
            >
              Aircraft Model
            </AccordionSummary>
            <AccordionDetails>
              <CheckBoxSelect
                filterState={flightDeckEffectsFilterState}
                setFilterState={setFlightDeckEffectsFilterState}
                options={[config.CRJ700]}
                filterName="acModel"
                shouldValidate={shouldValidateFilters}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion sx={styles.accordianRoot}>
            <AccordionSummary
              sx={styles.accordianLabel}
              expandIcon={<ExpandMoreIcon color="primary" />}
            >
              Main ATA
            </AccordionSummary>
            <AccordionDetails>
              <SelectBox
                multiple
                groupName="AC_MODEL"
                groupOptionLabel="Value"
                noOptionsText={'Select an AC Model first'}
                selectOptions={mainAtaList ? mainAtaList.data : []}
                filterState={flightDeckEffectsFilterState}
                setFilterState={setFlightDeckEffectsFilterState}
                filterName="mainAta"
                excludeName="excludeMainAta"
              />
            </AccordionDetails>
          </Accordion>
          <Accordion sx={styles.accordianRoot}>
            <AccordionSummary
              sx={styles.accordianLabel}
              expandIcon={<ExpandMoreIcon color="primary" />}
            >
              Sub ATA
            </AccordionSummary>
            <AccordionDetails>
              <SelectBox
                multiple
                groupName="AC_MODEL"
                groupOptionLabel="Value"
                noOptionsText={'Select an AC Model first'}
                selectOptions={
                  subAtaList ? filterSubATAsByMainAta(subAtaList.data) : []
                }
                filterState={flightDeckEffectsFilterState}
                setFilterState={setFlightDeckEffectsFilterState}
                filterName="subAta"
              />
            </AccordionDetails>
          </Accordion>
        </Fragment>
      )}
    </FiltersPanel>
  );
};
