import React, { useState, createContext } from 'react';

export const MessageDataContext = createContext();

export const MessageDataContextProvider = (props) => {
  // state used to store data from MDC Messages Table
  const [messageTableState, setMessageTableState] = useState(null);
  // if the MDC Messages table data is loading
  const [isLoading, setIsLoading] = useState(false);
  // if theres an error when querying MDC Messages table data
  const [error, setError] = useState(null);

  return (
    <MessageDataContext.Provider
      value={{
        messageTableState,
        setMessageTableState,
        isLoading,
        setIsLoading,
        error,
        setError,
      }}
    >
      {props.children}
    </MessageDataContext.Provider>
  );
};

export default MessageDataContextProvider;
