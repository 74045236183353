import React, { useState, createContext } from 'react';

export const FlightDeckEffectsFiltersContext = createContext();

export const FlightDeckEffectsFiltersContextProvider = (props) => {
  const defaultFlightDeckEffectsFilterState = {
    name: 'FlightDeckEffectsFiltersState',
    acModel: ['CRJ700/900/1000'],
    mainAta: [],
    subAta: [],
    excludeMainAta: false,
    orderbyCol: 'MAIN_ATA',
    orderbyDir: 'asc',
  };
  // state of the filters panel in the FlightDeckEffectsLookup tab
  const [flightDeckEffectsFilterState, setFlightDeckEffectsFilterState] =
  useState(
      defaultFlightDeckEffectsFilterState,
  );
  // copy of the filters that were applied in the current search
  const [appliedFlightDeckEffectsFilters, setAppliedFlightDeckEffectsFilters] =
  useState(null);
  // number of rows to display per page in the Equations table
  const [rowsPerPage, setRowsPerPage] = useState(100);
  // page number the user is on
  const [curTablePage, setCurTablePage] = useState(0);
  // row that the user has selected in the Equations table
  const [selectedRow, setSelectedRow] = useState([]);
  // bool that dictates whether should simulate a user clickin on the
  // apply button to trigger a search
  const [mimicApplyClick, setMimicApplyClick] = useState(false);
  return (
    <FlightDeckEffectsFiltersContext.Provider
      value={{
        flightDeckEffectsFilterState,
        setFlightDeckEffectsFilterState,
        appliedFlightDeckEffectsFilters,
        setAppliedFlightDeckEffectsFilters,
        defaultFlightDeckEffectsFilterState,
        rowsPerPage,
        setRowsPerPage,
        curTablePage,
        setCurTablePage,
        selectedRow,
        setSelectedRow,
        mimicApplyClick,
        setMimicApplyClick,
      }}
    >
      {props.children}
    </FlightDeckEffectsFiltersContext.Provider>
  );
};

export default FlightDeckEffectsFiltersContextProvider;
