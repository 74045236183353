import React, { Fragment, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import BarChartIcon from '@mui/icons-material/BarChart';
import CalendarToday from '@mui/icons-material/CalendarToday';
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from '@mui/material/styles';
import { ReportsMenu } from './ReportsMenu';

import {
  CsvExportButton,
} from 'mdp/components/MessagesTab/MessagesTable/CsvExportButton';
import {
  DateFormatMenu,
} from 'mdp/components/MessagesTab/MessagesTable/DateFormatMenu';

export const reportsList = [
  {
    label: 'Top 25 Aircraft',
    endpoint: 'top25aircraft',
    validateFilters: true,
  },
  {
    label: 'Top 25 Equations',
    endpoint: 'top25equations',
    validateFilters: true,
  },
  {
    label: 'Top 25 Messages',
    endpoint: 'top25messages',
    validateFilters: true,
  },
  {
    label: 'Top 25 ATAs',
    endpoint: 'top25ata',
    validateFilters: true,
  },
  {
    label: 'Top 25 LRUs',
    endpoint: 'top25lru',
    validateFilters: true,
  },
  {
    label: 'Top 25 Fleets',
    endpoint: 'top25fleets',
    validateFilters: true,
  },
  {
    label: 'Message Trend',
    endpoint: 'messagetrend',
    validateFilters: true,
  },
  {
    label: 'ATA Messages by Count',
    endpoint: 'atacount',
    validateFilters: true,
  },
  {
    label: 'Aircraft Needing MDC Downloads',
    endpoint: 'neededdonwloads',
    validateFilters: false,
  },
  {
    label: 'Imported Files',
    endpoint: 'importedfiles',
    validateFilters: true,
  },
  {
    label: 'MDC Detail',
    endpoint: 'mdcdetails',
    validateFilters: true,
  },
  {
    label: 'Data Analysis',
    endpoint: 'dataanalysis',
    validateFilters: true,
  },
  {
    label: 'MDC Fault Message History - Rate Per Month',
    endpoint: 'faulthistory',
    validateFilters: true,
  },
];

export const MessagesPanelButtons = () => {
  const theme = useTheme();
  const styles = ({
    tableIconButton: {
      padding: '0 0 0 0',
      margin: '0 10px 0 0',
    },
    buttonIcons: {
      width: '25px',
      height: '25px',
      fill: theme.palette.primary.dark,
    },
  });
  const [reportMenuAnchor, setReportMenuAnchor] = useState(null);
  const [
    dateFormatMenuAnchor, setDateFormatMenuAnchor,
  ] = useState(null);


  return (
    <Fragment>
      <Tooltip title="Select Date Format">
        <IconButton
          sx={styles.tableIconButton}
          onClick={(e) => setDateFormatMenuAnchor(e.currentTarget)}
          size="large">
          <CalendarToday sx={styles.buttonIcons} />
        </IconButton>
      </Tooltip>
      <DateFormatMenu
        menuAnchor={dateFormatMenuAnchor}
        setMenuAnchor={setDateFormatMenuAnchor}
      />

      <CsvExportButton />

      <Tooltip title="Generate Reports">
        <IconButton
          sx={styles.tableIconButton}
          onClick={(e) => setReportMenuAnchor(e.currentTarget)}
          size="large">
          <BarChartIcon sx={styles.buttonIcons} />
        </IconButton>
      </Tooltip>
      <ReportsMenu
        menuItems={reportsList}
        menuAnchor={reportMenuAnchor}
        setMenuAnchor={setReportMenuAnchor}
      />
    </Fragment>
  );
};
