import React from "react";
import Card from "@mui/material/Card";
import "orca/components/SpltTab/SpltTab.css";
import { SpltFiltersPanel } from "orca/components/SpltTab/SpltFiltersPanel";
import { SpltTablePanel } from "orca/components/SpltTab/SpltTablePanel";

export const SpltTab = (props) => {
  const { setCurTab } = props;
  const styles = ({
    root: {
      height: "100%",
      marginRight: "10px",
      borderTop: "1px solid rgba(0,0,0,0.2)",
      borderLeft: "1px solid rgba(0,0,0,0.2)",
      borderRight: "1px solid rgba(0,0,0,0.2)",
      borderBottom: "1px solid rgba(0,0,0,0.2)",
    },
  });

  return (
    <div className="spltTab">
      <div className="spltTabFiltersPane">
        <Card sx={styles.root}>
          <SpltFiltersPanel></SpltFiltersPanel>
        </Card>
      </div>
      <div className="spltTabDataPane">
        <Card sx={styles.root}>
          <SpltTablePanel setCurTab={setCurTab}></SpltTablePanel>
        </Card>
      </div>
    </div>
  );
};
