import React, { useState, createContext } from 'react';

export const MessageFiltersContext = createContext();

export const MessageFiltersContextProvider = (props) => {
  const getDateString = (offset) => {
    const newDate = new Date();
    newDate.setDate(newDate.getDate() + offset);
    const tzOffset = newDate.getTimezoneOffset();
    const newDateWithOffset = new Date(
        newDate.getTime() - tzOffset * 60 * 1000);
    return newDateWithOffset.toISOString().split('T')[0];
  };

  const defaultMessageFilterState = {
    name: 'MessageFiltersState',
    startDate: getDateString(-10),
    endDate: getDateString(0),
    startTime: '00:00',
    endTime: '23:59',
    endDateOffset: 10,
    acModel: [],
    fleets: [],
    acsn: [],
    actn: [],
    eqIDs: [],
    ata: [],
    lru: [],
    eicas: [],
    excludeFleets: false,
    excludeAcsn: false,
    excludeActn: false,
    excludeEqIDs: false,
    excludeAta: false,
    excludeLru: false,
    excludeEicas: false,
    markedEvent: [],
    eventNotes: [],
    falseMessages: [],
    messageType: [],
    flightPhase: [],
    flightNum: [],
    flightLeg: [],
    messageText: [],
    excludeMsgType: false,
    excludeFltPhase: false,
    excludeFlightNum: false,
    excludeFlightLeg: false,
    excludeMessageText: false,
    dateFormat: 'YYYY/MM/DD',
    orderbyCol: 'MSG_Date',
    orderbyDir: 'desc',
  };
  // state of the filters panel in the Messages tab
  const [messageFilterState, setMessageFilterState] = useState(
      defaultMessageFilterState,
  );
  // copy of the filters that were applied in the current search
  const [appliedMessageFilters, setAppliedMessageFilters] = useState(null);
  // whether user clicked on a tile in the fleet tab
  const [mimicApplyClick, setMimicApplyClick] = useState(false);
  // number of rows to display per page in the mdc messages table
  const [rowsPerPage, setRowsPerPage] = useState(100);
  // page number the user is on in the mdc messages table
  const [curTablePage, setCurTablePage] = useState(0);
  // row that the user has selected in the mdc messages table
  const [selectedRow, setSelectedRow] = useState(null);


  return (
    <MessageFiltersContext.Provider
      value={{
        messageFilterState,
        setMessageFilterState,
        appliedMessageFilters,
        setAppliedMessageFilters,
        defaultMessageFilterState,
        rowsPerPage,
        setRowsPerPage,
        curTablePage,
        setCurTablePage,
        selectedRow,
        setSelectedRow,
        mimicApplyClick,
        setMimicApplyClick,
      }}
    >
      {props.children}
    </MessageFiltersContext.Provider>
  );
};
export default MessageFiltersContextProvider;
