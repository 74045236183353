/* Component representing the expanded popup version of the "Details" dropdown
tab in the message details panel */
import React, { Fragment, useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { DefaultText } from 'mdp/components/Miscellaneous/DefaultText';
import 'mdp/components/MessagesTab/MessageDetails/MessageDetailsPanel.css';
import { LinkTable } from 'mdp/components/EqIDLookupTab/EqIDDetails/LinkTable';
import { apiFetcher } from 'utils/apiUtils';
import { config } from 'config/config';

export const DetailsPanelFimLinks = (props) => {
  const { panelState, isEqDetailsLoading, eqDetailsError, inExpanded } = props;
  const [selectedRow, setSelectedRow] = useState({});
  const FimLinksColumns = [{ id: 'LINK', minWidth: '100%', align: 'left' }];
  const [isAvailableFimLoading, setIsAvailableFimLoading] = useState(false);
  const [allFimLinksAvailable, setAllFimLinksAvailable] = useState([]);

  const getJustNum = (val) => {
    // removed the "TASK" from the front of a link num
    let foundFirstNum = false;
    let linkNum = '';

    for (let i = 0; i < val.length; i++) {
      const c = val[i];
      if (!foundFirstNum && c >= '0' && c <= '9') {
        foundFirstNum = true;
      }
      if (foundFirstNum) {
        linkNum = linkNum.concat(c);
      }
    }
    return linkNum;
  };

  const generateFileNumsArray = () => {
    const fimLinks = panelState.FIM_LINKS;
    const fileNumsArray = [];
    for (let i = 0; i < fimLinks.length; i++) {
      fileNumsArray.push(getJustNum(fimLinks[i].LINK));
    }
    return JSON.stringify(fileNumsArray);
  };

  const createLinkObjArrayFromArray = (a) => {
    const result = [];
    for (let i = 0; i < a.length; i++) {
      const obj = {};
      obj.ID = i;
      obj.LINK = a[i];
      result.push(obj);
    }
    return result;
  };

  const getAvailableFims = async () => {
    setIsAvailableFimLoading(true);
    await apiFetcher(
        `${config.apiURL}/mdp/availablefims?`,
        'POST',
        { fileNums: generateFileNumsArray(), acModel: panelState.AC_MODEL },
        { 'Content-Type': 'application/json' },
        true,
    ).then((response) => {
      setAllFimLinksAvailable(
          createLinkObjArrayFromArray(JSON.parse(response.data)),
      );
      setIsAvailableFimLoading(false);
    });
  };

  useEffect(() => {
    // in this useEffect we will run endpoint 1 with panelState.FIM_LINKS
    // as the argument. It will return all names of all available configs in the
    // format of an array [{EQ_ID: "123", FIM_LINK: "1-2-3-A01"}]. We will set
    // the state allFimLinksAvailable to this array, and then use this state as
    // the data field in linkTable component

    if (panelState.FIM_LINKS) {
      getAvailableFims();
    }
  }, [panelState.FIM_LINKS]);

  return (
    <Fragment>
      {/* If there's an error fetching the data */}
      {eqDetailsError ? (
        <div style={{ width: '100%', marginLeft: inExpanded !== undefined ? '45%' : '0%' }}>
          <DefaultText text={eqDetailsError.toString()} />
        </div>
      ) : null}
      {/* if data is loading show spinner */}
      {isEqDetailsLoading || isAvailableFimLoading ? (
        <div>
          <CircularProgress color="primary" />
        </div>
      ) : null}
      {/* show data if not error and not loading */}
      {!isAvailableFimLoading &&
      !isEqDetailsLoading &&
      !eqDetailsError &&
      panelState &&
      panelState.FIM_LINKS &&
      panelState.AC_MODEL ? (
        <div className="detailsPanelDataTable">
          <div>
            <LinkTable
              maxHeight="102px"
              fieldLabel="FIM Task Link(s)"
              data={allFimLinksAvailable}
              columns={FimLinksColumns}
              selectedRow={selectedRow}
              setSelectedRow={setSelectedRow}
              rowKey="LINK"
              panelState={panelState}
            />
          </div>
        </div>
      ) : null}
    </Fragment>
  );
};
