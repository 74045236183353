/* Component representing the button bar above the Equation ID lookup table */
import React, { Fragment, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import BarChartIcon from '@mui/icons-material/BarChart';
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '@mui/material/styles';


export const EqIDTablePanelButtons = (props) => {
  const theme = useTheme();
  const styles = ({
    iconButtons: {
      padding: '0 0 0 0',
      margin: '0 10px 0 0',
    },
    buttonIcons: {
      width: '25px',
      height: '25px',
      fill: theme.palette.primary.dark,
    },
  });
  const [menuAnchor, setMenuAnchor] = useState(null);

  const handleOpen = (event) => {
    /*
    @param event: the button location where the dropdown menu will open
    */
    // set the anchor to the location of the button that was clicked
    setMenuAnchor(event.currentTarget);
  };
  const handleClose = () => {
    // remove the anchor, closing the dropdown menu
    setMenuAnchor(null);
  };

  return (
    <Fragment>
      <Tooltip title="Generate Reports">
        <IconButton sx={styles.iconButtons} onClick={handleOpen} size="large">
          <BarChartIcon sx={styles.buttonIcons} />
        </IconButton>
      </Tooltip>
      <Menu
        id="simple-menu"
        anchorEl={menuAnchor}
        keepMounted
        open={Boolean(menuAnchor)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>MDC Top known msgs (CRJ700)</MenuItem>
      </Menu>
    </Fragment>
  );
};
