import React, { Fragment } from "react";
import { useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableSortLabel from "@mui/material/TableSortLabel";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import CircularProgress from "@mui/material/CircularProgress";
import { DefaultText } from "mdp/components/Miscellaneous/DefaultText";
import { apiFetcher } from "utils/apiUtils";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";

export const RawDataTable = (props) => {
  /*
  Component representing main tables used in the application

  @props <url:string>: backend url to query rows from
  @props <columns:list>: list of columns in the table e.g.
  [{key: "AC_SN",label: "Serial Number", minWidth: 50,}]
  @props <rowKey:string>: column name that acts as a unique identifier for
  each row e.g. MDC messages data from the backend has a MSG_ID column
  @props <tableData:list>: state that holds the table data
  @props <setTableData: setter for tableData state
  @props isLoading: state that represents if a query is running
  @props setIsLoading: setter for isLoading sate
  @props error: state that holds errors when fetching data from backend
  @props setError: setter for error state
  @props appliedFilters: state stores copy of the filters that were applied
  @props rowsPerPage: state stores number or rows per table page
  @props setRowsPerPage: setter for rowsPerPage state
  @props curTablePage: state stores current table page user is on
  @props setCurTablePage: setter for curTablePage state
  @props selectedRows: state stores row that user has currently selected
  @props setSelectedRows: setter for selectedRow state
  @props setMimicApplyClick: state setter for whether to trigger a mimicked
  "Apply" button click
  @props <rawDataFilterState:object>: state of the filters panel
  @props <setRawDataFilterState>: setter for rawDataFilterState
  @props <formatFilters:fucntion>: takes in filterState and mutates it. Used to
  make some sort of change to the filterState before it is sent to the backend
  */
  const {
    setCurTablePage,
    setIsLoading,
    setTableData,
    rowsPerPage,
    setMimicApplyClick,
    tableData,
    setError,
    setRowsPerPage,
    selectedRows,
    url,
    setRawDataFilterState,
    appliedFilters,
    columns,
    isLoading,
    formatFilters,
    rowKey,
    curTablePage,
    setSelectedRows,
    error,
    rawDataFilterState,
  } = props;

  const theme = useTheme();
  const styles = ({
    tableHeader: {
      color: theme.palette.primary.main,
      backgroundColor: "white",
      fontWeight: "bold",
    },
    spinnerAndText: {
      marginTop: "30%",
      marginBottom: "auto",
    },
    container: {
      height: "88%",
      scrollbarWidth: "thin",
    },
    tableRow: {
      "&$selected, &$selected:hover": {
        backgroundColor: theme.palette.secondary.main,
      },
    },
    headerCell: {
      padding: "6px 0 6px 8px",
    },
    tableCell: {
      fontSize: 11,
      padding: "6px 0 6px 8px",
    },
    selected: {},
  });

  const handleChangePage = (event, newPage) => {
    /*
    Changes the page in the MDC messages table

    @param event: dummy variable
    @param newPage: integer containing the new page number the user clicked
    */
    // if there isn't enough cached data to show the next page then
    // request more rows from the backend and append them to the table state
    if (newPage * rowsPerPage >= tableData.data.length) {
      setIsLoading(true);
      // if formatFilters prop is provided then format the filters parameters
      // before sending the request to the backend
      let payload;
      if (formatFilters) {
        payload = formatFilters(appliedFilters);
      } else {
        payload = appliedFilters;
      }
      apiFetcher(
        `${url}?offset=${tableData.data.length}&limit=${rowsPerPage}`,
        "POST",
        payload,
        { "Content-Type": "application/json" }
      )
        .then((response) => {
          setTableData({
            ...tableData,
            data: [...tableData.data, ...response.data],
          });
          setCurTablePage(newPage);
          setIsLoading(false);
        })
        .catch((e) => {
          setError(e);
        });
    } else {
      setCurTablePage(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    /*
    Changes the number of rows to show per page

    @param event: string containg the number of rows per page set by the user
    */

    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage); // update rows per page
    setCurTablePage(0); // reset to page 0
    // if there isnt enough cached data to show the first page then request
    // it from the backend append them to the table state
    if (newRowsPerPage > tableData.data.length) {
      setIsLoading(true);
      // if formatFilters prop is provided then format the filters parameters
      // before sending the request to the backend
      let payload;
      if (formatFilters) {
        payload = formatFilters(appliedFilters);
      } else {
        payload = appliedFilters;
      }
      apiFetcher(
        `${url}?offset=${tableData.data.length}&limit=${rowsPerPage}`,
        "POST",
        payload,
        { "Content-Type": "application/json" }
      )
        .then((response) => {
          setTableData({
            ...tableData,
            data: [...tableData.data, ...response.data],
          });

          setIsLoading(false);
        })
        .catch((e) => {
          setError(e);
        });
    }
  };

  const handleRowClick = (row) => {
    // if selected row is already checked then uncheck it
    if (selectedRows.map((item) => item[rowKey]).includes(row[rowKey])) {
      setSelectedRows(
        selectedRows.filter((item) => item[rowKey] !== row[rowKey])
      );
    } else {
      // if selected row is already unchecked then check it
      setSelectedRows([...selectedRows, row]);
    }
  };

  const handelSelectAllRows = (event) => {
    // if "select all" is unchecked then check all rows
    if (event.target.checked) {
      // if not all rows have been requested from the backend
      // get all rows from backend
      if (tableData.data.length < tableData.totalRows) {
        setIsLoading(true);
        // if formatFilters prop is provided then format the filters parameters
        // before sending the request to the backend
        let payload;
        if (formatFilters) {
          payload = formatFilters(appliedFilters);
        } else {
          payload = appliedFilters;
        }
        apiFetcher(
          `${url}?offset=0&limit=${tableData.totalRows}`,
          "POST",
          payload,
          {
            "Content-Type": "application/json",
          }
        )
          .then((response) => {
            setTableData(response);
            setSelectedRows(response.data);
            setIsLoading(false);
          })
          .catch((e) => {
            setError(e);
          });

        // if all rows have been requested from backend then set those
        // rows as selected
      } else {
        setSelectedRows(tableData.data);
      }
    } else {
      // if "select all" is checked then uncheck all rows
      setSelectedRows([]);
    }
  };

  return (
    <Fragment>
      {/* If there's an error fetching the data */}
      {error && (
        <div style={styles.spinnerAndText}>
          <DefaultText text={error.toString()} />
        </div>
      )}
      {/* default message to show if the user hasnt made a query */}
      {!error && !tableData && !isLoading && (
        <div style={styles.spinnerAndText}>
          <DefaultText text="Apply some filters" />
        </div>
      )}
      {/* if the query is complete or query in progress show the table */}
      {!error && (tableData || isLoading) && (
        <Fragment>
          <TableContainer sx={styles.container}>
            {/* if query in progress show loading spinner */}
            {isLoading && (
              <div style={styles.spinnerAndText}>
                <CircularProgress color="primary" />
              </div>
            )}
            {/* if the query completed but the table is empty */}
            {!isLoading && !tableData.data.length && (
              <div style={styles.spinnerAndText}>
                <DefaultText text="No records found" />
              </div>
            )}
            {/* if query completed and table is not empty */}
            {!isLoading && tableData.data.length ? (
              <Table stickyHeader size="small">
                {/* Column headers of the table */}
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        indeterminate={
                          selectedRows.length > 0 &&
                          selectedRows.length < tableData?.totalRows
                        }
                        checked={
                          tableData?.totalRows > 0 &&
                          selectedRows.length === tableData?.totalRows
                        }
                        onChange={handelSelectAllRows}
                      />
                    </TableCell>
                    {columns.map((column) => (
                      <TableCell
                        sx={styles.headerCell}
                        key={column.key}
                        title={column.tooltip}
                        align={column.align}
                        style={{
                          ...styles.tableHeader,
                          minWidth: column.minWidth,
                        }}
                      >
                        {/* for sortable columns render sorting icons. if the
                        user clicks on a column header to sort it then update
                        orderbyCol and orderbyDir in the message filters
                        context. This will trigger a new search to the backend
                        essentially mimicking the user clicking the "apply"
                        button */}
                        {column.sortable ? (
                          <TableSortLabel
                            active={
                              rawDataFilterState.orderbyCol === column.key
                            }
                            direction={rawDataFilterState.orderbyDir}
                            onClick={() => {
                              setRawDataFilterState({
                                ...rawDataFilterState,
                                orderbyCol: column.key,
                                orderbyDir:
                                  rawDataFilterState.orderbyDir === "asc"
                                    ? "desc"
                                    : "asc",
                              });
                              setMimicApplyClick(true);
                            }}
                          >
                            {column.label}
                          </TableSortLabel>
                        ) : (
                          column.label
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                {/* main body of the table */}
                <TableBody>
                  {/* render a table row for each MDC message based on number of
                  message per page and the current page number */}
                  {tableData.data
                    .slice(
                      curTablePage * rowsPerPage,
                      curTablePage * rowsPerPage + rowsPerPage
                    )
                    .map((row, index) => {
                      return (
                        <TableRow
                          hover
                          tabIndex={-1}
                          key={row[rowKey]}
                          sx={styles.tableRow}
                          classes={{ selected: styles.selected }}
                          onClick={(event) => handleRowClick(row)}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={selectedRows
                                .map((item) => item[rowKey])
                                .includes(row[rowKey])}
                            />
                          </TableCell>
                          {columns.map((column) => {
                            const value = row[column.key];
                            return (
                              <TableCell
                                key={column.key}
                                align={column.align}
                                sx={styles.tableCell}
                              >
                                {column.truncate ? (
                                  value?.length > column.truncate ? (
                                    <Tooltip title={value}>
                                      <div>
                                        {value.substr(0, column.truncate - 1) +
                                          "..."}
                                      </div>
                                    </Tooltip>
                                  ) : (
                                    value
                                  )
                                ) : (
                                  value
                                )}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            ) : null}
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[50, 100, 200, 300]}
            component="div"
            count={tableData?.totalRows || 0}
            rowsPerPage={rowsPerPage}
            page={curTablePage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Fragment>
      )}
    </Fragment>
  );
};
