/* Root component of the Eq ID lookup tab */
import React, { useState } from 'react';
import Card from '@mui/material/Card';
// eslint-disable-next-line max-len
import { EqIDFiltersPanel } from 'mdp/components/EqIDLookupTab/Filters/EqIDFiltersPanel';
// eslint-disable-next-line max-len
import { EqIDDetailsPanel } from 'mdp/components/EqIDLookupTab/EqIDDetails/EqIDDetailsPanel';
// eslint-disable-next-line max-len
import { EqIDTablePanel } from 'mdp/components/EqIDLookupTab/EqIDTable/EqIDTablePanel';
import PropTypes from 'prop-types';

export const EqIDLookupTab = (props) => {
  const { setSpecialistEditMode, specialistEditMode } = props;

  const [isDetailsPanelExpand, setIsDetailsPanelExpand] = useState(false);
  const [hideDetails, setHideDetails] = useState(false);
  const [hideFilters, setHideFilters] = useState(false);

  const getMessagePaneClass = () => {
    // return the appropriate css class depending on which panels are hidden
    let classname;
    if (hideDetails && !hideFilters) {
      classname = 'tablePanel expandRight';
    } else if (hideFilters && !hideDetails) {
      classname = 'tablePanel expandLeft';
    } else if (hideFilters && hideDetails) {
      classname = 'tablePanel expandFull';
    } else {
      classname = 'tablePanel';
    }
    return classname;
  };

  return (
    <div className="threePanelTab">
      {/* filters panel */}
      <div className={'filtersPanel'.concat(hideFilters ? ' hide' : '')}>
        <Card className="threePanelTabCard">
          <EqIDFiltersPanel specialistEditMode={specialistEditMode} />
        </Card>
      </div>
      {/* Equation ID lookup table */}
      <div className={getMessagePaneClass()}>
        <Card className="threePanelTabCard">
          <EqIDTablePanel
            hideDetails={hideDetails}
            hideFilters={hideFilters}
            setHideDetails={setHideDetails}
            setHideFilters={setHideFilters}
            isDetailsPanelExpand={isDetailsPanelExpand}
            setIsDetailsPanelExpand={setIsDetailsPanelExpand}
            specialistEditMode={specialistEditMode}
          />
        </Card>
      </div>
      {/* Details panel */}
      <div className={'detailsPanel'.concat(hideDetails ? ' hide' : '')}>
        <Card className="threePanelTabCard">
          <EqIDDetailsPanel
            isDetailsPanelExpand={isDetailsPanelExpand}
            setIsDetailsPanelExpand={setIsDetailsPanelExpand}
            specialistEditMode={specialistEditMode}
            setSpecialistEditMode={setSpecialistEditMode}
          />
        </Card>
      </div>
    </div>
  );
};

EqIDLookupTab.propTypes = {
  specialistEditMode: PropTypes.bool.isRequired,
  setSpecialistEditMode: PropTypes.func.isRequired,
};
