/* eslint-disable max-len */
import React, { Fragment, useState, useEffect, useContext } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DetailsPanel } from 'mdp/components/Miscellaneous/DetailsPanel';
import { EqIDDetailsPanelExpanded } from 'mdp/components/EqIDLookupTab/EqIDDetails/EqIDDetailsPanelExpanded';
import { DetailsPanelDetailsTab } from 'mdp/components/EqIDLookupTab/EqIDDetails/DetailsPanelDetailsTab';
import { DetailsPanelLogicTab } from 'mdp/components/EqIDLookupTab/EqIDDetails/DetailsPanelLogicTab';
import { DetailsPanelSpecialistInputTab } from 'mdp/components/MessagesTab/MessageDetails/SpecialistInput/DetailsPanelSpecialistInputTab.js';
// import { DetailsPanelMMELTab } from 'mdp/components/EqIDLookupTab/EqIDDetails/DetailsPanelMMELTab';
import { EqIDFiltersContext } from 'mdp/contexts/EqIDFiltersContext';
import { DetailsPanelFimLinks } from 'mdp/components/EqIDLookupTab/EqIDDetails/DetailsPanelFimLinks';
import { apiFetcher } from 'utils/apiUtils';
import { config } from 'config/config';
import 'mdp/components/MessagesTab/MessageDetails/MessageDetailsPanel.css';

export const EqIDDetailsPanel = (props) => {
  const {
    specialistEditMode,
    setSpecialistEditMode,
    isDetailsPanelExpand,
    setIsDetailsPanelExpand,
  } = props;

  const styles = ({
    accordianRoot: {
      boxShadow: 'none',
    },
    accordianLabel: {
      fontSize: 16,
    },
  });

  // detailed data about a select row fetched from backend
  const [eqDetails, setEqDetails] = useState(null);
  const [isEqDetailsLoading, setIsEqDetailsLoading] = useState(false);
  const [eqDetailsError, setEqDetailsError] = useState(null);
  // row that user selected in Equation ID lookup table
  const { selectedRow } = useContext(EqIDFiltersContext);

  const getEqDetails = () => {
    setIsEqDetailsLoading(true);
    setEqDetailsError(null);
    setEqDetails(null);
    apiFetcher(
        `${config.apiURL}/mdp/equationids/details?acmodel=${
          selectedRow.AC_MODEL
        }&eqid=${selectedRow.EQ_ID}&mdtversion=${selectedRow.MDT_VERSION.split(
            ' ',
        ).join('+')}`,
        'GET',
    )
        .then((response) => {
          setEqDetails({
            ...response.data,
            IS_SPECIALIST: response.data.IS_SPECIALIST === "True"
          });
          setIsEqDetailsLoading(false);
        })
        .catch((e) => {
          setEqDetailsError(e);
          setIsEqDetailsLoading(false);
        });
  };

  useEffect(() => {
    if (selectedRow) {
      getEqDetails();
    }
  }, [selectedRow]);

  return (
    <Fragment>
      <DetailsPanel
        title="Equation Details"
        setIsDetailsPanelExpand={setIsDetailsPanelExpand}
        specialistEditMode={specialistEditMode}
        isDetailsPanelExpand={isDetailsPanelExpand}
      >
        <Accordion sx={styles.accordianRoot}>
          <AccordionSummary
            sx={styles.accordianLabel}
            expandIcon={<ExpandMoreIcon color="primary" />}
          >
            Details
          </AccordionSummary>
          <AccordionDetails>
            <DetailsPanelDetailsTab
              panelState={{
                ...selectedRow,
                ...(eqDetails || {}),
              }}
              isEqDetailsLoading={isEqDetailsLoading}
              eqDetailsError={eqDetailsError}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion sx={styles.accordianRoot}>
          <AccordionSummary
            sx={styles.accordianLabel}
            expandIcon={<ExpandMoreIcon color="primary" />}
          >
            Logic
          </AccordionSummary>
          <AccordionDetails>
            <DetailsPanelLogicTab
              panelState={{
                ...selectedRow,
                ...(eqDetails || {}),
              }}
              isEqDetailsLoading={isEqDetailsLoading}
              eqDetailsError={eqDetailsError}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion sx={styles.accordianRoot}>
          <AccordionSummary
            sx={styles.accordianLabel}
            expandIcon={<ExpandMoreIcon color="primary" />}
          >
            Fault Isolation Manuals
          </AccordionSummary>
          <AccordionDetails>
            <DetailsPanelFimLinks
              panelState={{
                ...selectedRow,
                ...(eqDetails || {}),
              }}
              isEqDetailsLoading={isEqDetailsLoading}
              eqDetailsError={eqDetailsError}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion sx={styles.accordianRoot}>
          <AccordionSummary
            sx={styles.accordianLabel}
            expandIcon={<ExpandMoreIcon color="primary" />}
          >
            Specialist Input
          </AccordionSummary>
          <AccordionDetails>
            <DetailsPanelSpecialistInputTab
              panelState={{
                ...selectedRow,
                ...(eqDetails || {}),
              }}
              isMessageDetailsLoading={isEqDetailsLoading}
              selectedEQID={selectedRow?.EQ_ID}
              specialistEditMode={specialistEditMode}
              setSpecialistEditMode={setSpecialistEditMode}
              messageDetailsError={eqDetailsError}
            />
          </AccordionDetails>
        </Accordion>
        {/* <Accordion sx={styles.accordianRoot}>
          <AccordionSummary
            sx={styles.accordianLabel}
            expandIcon={<ExpandMoreIcon color="primary" />}
          >
            MMEL
          </AccordionSummary>
          <AccordionDetails>
            <DetailsPanelMMELTab
              panelState={{
                ...selectedRow,
                ...(eqDetails || {}),
              }}
              isEqDetailsLoading={isEqDetailsLoading}
              eqDetailsError={eqDetailsError}
              specialistEditMode={specialistEditMode}
              setSpecialistEditMode={setSpecialistEditMode}
            />
          </AccordionDetails>
        </Accordion> */}
      </DetailsPanel>
      <EqIDDetailsPanelExpanded
        isDetailsPanelExpand={isDetailsPanelExpand}
        setIsDetailsPanelExpand={setIsDetailsPanelExpand}
        panelState={{
          ...selectedRow,
          ...(eqDetails || {}),
        }}
        isEqDetailsLoading={isEqDetailsLoading}
        eqDetailsError={eqDetailsError}
        specialistEditMode={specialistEditMode}
        setSpecialistEditMode={setSpecialistEditMode}
      />
    </Fragment>
  );
};
