/* Entry point to the application */
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import 'bootstrap/dist/css/bootstrap.min.css';
import { ThemeProvider, StyledEngineProvider, createTheme, adaptV4Theme } from '@mui/material/styles';
import App from './App';
import GlobalContextProvider from './GlobalProviderComposer';
import './index.css';

// This color scheme is used through out the app
const theme = createTheme(adaptV4Theme({
  palette: {
    primary: {
      main: '#044269',
      light: '#416d97',
      dark: '#001c3e',
    },
    secondary: {
      main: '#e0e0e0', // #c5d3e0 old color
      light: '#d8e4f0',
    },
    default: {
      light: '#f3f2f1',
      main: '#e0e0e0',
      dark: '#92A0AD',
    },
    success: {
      main: '#044269',
    },
    text: {
      primary: '#272727',
      secondary: '#757575',
      title1: '#044269',
      title2: '#2c3942',
    },
    background: {
      tabContent: '#f3f2f1',
    },
  },
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '12px',
      },
    },
  },
}));
const history = createBrowserHistory();

const root = createRoot(document.getElementById('root'));

root.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <Router history={history}>
        <GlobalContextProvider>
          <App />
        </GlobalContextProvider>
      </Router>
    </ThemeProvider>
  </StyledEngineProvider>
);

