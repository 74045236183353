/* Root component of the fleet tab */
import React, { useState, useEffect, useContext } from 'react';
import Switch from '@mui/material/Switch';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import CircularProgress from '@mui/material/CircularProgress';
import { Grid as MUIGrid } from '@mui/material';
import { FixedSizeList as List } from 'react-window';
import Tooltip from '@mui/material/Tooltip';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { config } from 'config/config';
import { useFetch, apiFetcher } from 'utils/apiUtils';
import { DefaultText } from 'mdp/components/Miscellaneous/DefaultText';
import { MessageFiltersContext } from 'mdp/contexts/MessageFiltersContext';
import { FleetTabFiltersContext } from 'mdp/contexts/FleetTabFiltersContext';
import { ACTile } from 'mdp/components/FleetTab/FleetTabTiles';
import 'mdp/components/FleetTab/FleetTab.css';
import image from 'assets/mhirj_logo.png';
import { useHistory } from 'react-router-dom';
import {
  getTileClickDate,
  getNumPadders,
  getMaxColumns,
} from 'mdp/components/FleetTab/fleetTabFunctions';

export const FleetTab = (props) => {
  // const {setCurTab} = props;
  const history = useHistory();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const {
    setMimicApplyClick,
    setMessageFilterState,
    defaultMessageFilterState,
  } = useContext(MessageFiltersContext);

  const {
    searchInput,
    setSearchInput,
    sortBy,
    setSortBy,
    filterOPR,
    setFilterOPR,
    filterAC,
    setFilterAC,
    showRegistrationNumber,
    setShowRegistrationNumber,
  } = useContext(FleetTabFiltersContext);
  // fetch a list of acsn from the backend api
  const { data: acsnList, error: acsnListError } = useFetch(
    `${config.apiURL}/mdp/fleetTab`
  );

  const OPRNames = [
    ...new Set(acsnList.data?.map((item) => item.OPERATOR_CODE)),
    'All',
  ];

  // filter based on user's search input
  let filteredAcsnList = acsnList.data?.filter((ac) => {
    // Gets all acNames that are true and adds the model names to an array
    const filteredOutAC = Object.entries(filterAC).reduce(
      (filteredList, acModel) => {
        if (acModel[1]) {
          filteredList.push(acModel[0]);
        }
        return filteredList;
      },
      []
    );
    const includesNumber = showRegistrationNumber
      ? ac.AC_TN.includes(searchInput)
      : ac.AC_SN.includes(searchInput);
    const filteredAC = filteredOutAC.some((acModel) => ac.AC_MODEL === acModel);
    return includesNumber && filteredAC;
  });

  // Filters By OPR
  if (
    filterOPR.length &&
    filteredAcsnList &&
    filteredAcsnList.length &&
    filterOPR !== 'All'
  ) {
    filteredAcsnList = filteredAcsnList.filter((ac) => {
      return ac.OPERATOR_CODE === filterOPR;
    });
  }

  filteredAcsnList &&
    filteredAcsnList.sort((a, b) => {
      let aAttribute = a[sortBy];
      let bAttribute = b[sortBy];
      // Convert data type from string to correct format
      if (sortBy === 'LAST_DOWNLOAD_DATE') {
        aAttribute = new Date(a[sortBy]);
        bAttribute = new Date(b[sortBy]);
      } else if (sortBy === 'AC_SN') {
        aAttribute = Number(a[sortBy]);
        bAttribute = Number(b[sortBy]);
      }
      if (aAttribute < bAttribute) {
        return 1;
      }
      if (aAttribute > bAttribute) {
        return -1;
      }
      return 0;
    });
  let acTilesList = null;

  // calc grid dimensions based on window size and number of aircraft tiles
  // basically we always want 15px of spacing between tiles
  const tilePadding = 15;
  const acTileHeight = 120;
  const maxColumns = getMaxColumns(windowWidth);
  const maxRows = Math.floor(
    (windowHeight * 0.72) / (acTileHeight + tilePadding)
  );
  const numRows = Math.ceil(filteredAcsnList?.length / maxColumns);
  const gridWidth = windowWidth * 0.9;
  const gridHeight = windowHeight * 0.72;

  const acTileWidth = 0.95 * (gridWidth - maxColumns * tilePadding) / maxColumns;
  const handleTileClick = (acsn, oprCode) => {
    /*
    When a tile is clicked pull acModel and operator info from backend,
    update the filter state of the Message tab with the new info and
    then switch to the Messages tab

  @param <acsn:string>: Aircraft serial number that was clicked
  */
    apiFetcher(`${config.apiURL}/mdp/aircraft/${oprCode}/${acsn}`, 'GET')
      .then((response) => {
        const dateFilters = getTileClickDate(response.data);
        setMessageFilterState({
          ...defaultMessageFilterState,
          acsn: [
            {
              Value: response.data.AC_SN,
              AC_MODEL:
                response.data.AC_MODEL === 'CRJ' ? config.CRJ : config.CRJ700,
            },
          ],
          acModel:
            response.data.AC_MODEL === 'CRJ' ? config.CRJ : config.CRJ700,
          fleets: [response.data.OPERATOR_NAME],
          startDate: dateFilters.startDate,
          startTime: dateFilters.startTime,
          endDate: dateFilters.endDate,
          endTime: dateFilters.endTime,
        });
        setMimicApplyClick(true);
      })
      .catch((e) => {
        console.error(e);
      });
    // When a tile is clicked swtich to the Messages tab
    // setCurTab("Messages");
    history.push('Messages');
  };

  const handelSearchInput = (event) => {
    setSearchInput(event.target.value);
  };

  // create list of aircraft tile objects from filtered ascsn list
  if (acsnList.data) {
    acTilesList = filteredAcsnList.map((ac) => (
      <ACTile
        showRegistrationNumber={showRegistrationNumber}
        ac={ac}
        key={ac.AC_SN}
        acTileWidth={acTileWidth}
        acTileHeight={acTileHeight}
        handleTileClick={handleTileClick}
      />
    ));
    // add "All aircraft" tile and pad end of list so odd list length
    // doesnt affect tile spacing
    acTilesList = [
      ...acTilesList,
      ...Array(
        getNumPadders(maxColumns, maxRows, numRows, filteredAcsnList.length + 1)
      ).fill(null),
    ];
  }

  useEffect(() => {
    // event listener for getting window width
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  const handleChangeFilter = (event) => {
    const acSeries = event.target.name;
    setFilterAC({
      ...filterAC,
      [acSeries]: event.target.checked,
    });
  };

  const Row = ({ index, style }) => {
    return (
      <div style={{ ...style, display: 'flex', height: 145, overflowX: 'hidden' }}>
          {acTilesList.slice(index * maxColumns, index * maxColumns + maxColumns).map((component, colIndex) => (
            <div key={`${index}-${colIndex}`} style={{ flexGrow: 1, flexBasis: `${100 / maxColumns}%` }}>
              {component}
             </div>
          ))}
      </div>
    )
  }

  return (
    <div className="fleetTab">
      <header className='header'>
        <img className="mhiLogo" src={image} alt={'MHIRJ Logo'} />
        <div className="title">
          <h1 className="titleText">Maintenance Diagnostic Platform</h1>
          <h1 className="titleText">(MDP)</h1>
          <p className="subtitleText">Version 3.0</p>
        </div>
        <div className='mhiLogo' />
      </header>
      <MUIGrid style={{ width: gridWidth, margin: 'auto' }} container>
        <MUIGrid item xs={12} md={6} lg={4}>
          <Paper component="span" className="searchBar">
            <SearchIcon className="searchIcon" />
            <InputBase
              value={searchInput}
              className="searchBarText"
              placeholder={
                showRegistrationNumber
                  ? 'Search Registration Number'
                  : 'Search Serial Number'
              }
              onChange={handelSearchInput}
            />
            {searchInput && (
              <Tooltip title="Clear Search">
                <IconButton
                  className="clearSearchIconButton clearSearchIcon"
                  onClick={() => setSearchInput('')}
                  size="large">
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            )}
          </Paper>
        </MUIGrid>
      </MUIGrid>
      <MUIGrid style={{ width: gridWidth, margin: 'auto' }} container>
        <MUIGrid item xs={12} md={10}>
          <FormControl variant="standard" style={{ paddingRight: '1rem' }}>
            <FormLabel className="formLabel" component="legend">
              Sort By:
            </FormLabel>
            <RadioGroup
              row
              aria-label="sortBy"
              name="sortBy"
              value={sortBy}
              onChange={(event) => setSortBy(event.target.value)}
            >
              <FormControlLabel
                value="LAST_DOWNLOAD_DATE"
                control={<Radio color="primary" />}
                label={'Last Downloaded (GMT)'}
              />
              <FormControlLabel
                value={showRegistrationNumber ? 'AC_TN' : 'AC_SN'}
                control={<Radio color="primary" />}
                label={
                  showRegistrationNumber
                    ? 'Registration Number'
                    : 'Serial Number'
                }
              />
              <FormControlLabel
                value="FAULT"
                control={<Radio color="primary" />}
                label={'Fault'}
              />
            </RadioGroup>
          </FormControl>
          {OPRNames.length > 2 && (
            <FormControl variant="standard" style={{ paddingRight: '1rem' }}>
              <FormGroup row>
                <FormLabel className="formLabel" component="legend">
                  Filter By OPR:
                </FormLabel>

                <FormControlLabel
                  control={
                    <Select
                      variant="standard"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={filterOPR}
                      label="OPR"
                      onChange={(e) => setFilterOPR(e.target.value)}
                      style={{ width: '100%' }}>
                      {OPRNames.map((name, index) => {
                        return (
                          <MenuItem key={index} value={name}>
                            {name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  }
                  style={{ margin: 0, width: '100%' }}
                />
              </FormGroup>
            </FormControl>
          )}
          <FormControl variant="standard" component="fieldset">
            <FormGroup row>
              <FormLabel className="formLabel" component="legend">
                Filter By:
              </FormLabel>

              {Object.entries(filterAC).map((acModel) => {
                const modelName = acModel[0];
                let modelLabel;
                if (acModel[0] === 'CRJ') {
                  modelLabel = 'CRJ100/200/400';
                }
                if (acModel[0] === 'CRJ700') {
                  modelLabel = 'CRJ700/900/1000';
                }
                // const checked = acModel[1];
                return (
                  <FormControlLabel
                    key={acModel[0]}
                    control={
                      <Checkbox
                        checked={filterAC[modelName]}
                        onChange={handleChangeFilter}
                        name={modelName}
                        color="primary"
                      />
                    }
                    title={modelLabel}
                    label={modelLabel}
                    style={{ marginBottom: 0 }}
                  />
                );
              })}
            </FormGroup>
          </FormControl>
        </MUIGrid>
        <MUIGrid item xs={12} md={2} className={'switch'}>
          <FormControlLabel
            control={
              <Switch
                checked={showRegistrationNumber}
                onChange={(event) =>
                  setShowRegistrationNumber(event.target.checked)
                }
                color="primary"
                name="checkedRegistrationNumber"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            }
            label={`
            Switch Aircraft Identifier\n (${
              showRegistrationNumber ? 'Registration' : 'MSN'
            })`}
            labelPlacement="top"
          />
        </MUIGrid>
      </MUIGrid>
      <div className="acGrid">
        {!acsnList.data && !acsnListError && (
          <CircularProgress color="primary" />
        )}
        {acsnList.data && !acsnListError && (
           <div className="virtual-grid">
            <List
              height={gridHeight}
              itemCount={Math.ceil(acTilesList.length / maxColumns) }
              itemSize={145} // Set the height of the visible area
              width={gridWidth} // Set the width of the visible area
            >
              {Row}
            </List>
         </div>
        )}
        {acsnListError && <DefaultText text="Fed to fetch data" />}
      </div>
    </div>
  );
};
