import React from "react";
import { useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";

export const DataTable = (props) => {
  const {
    setSelectedRow,
    fieldLabel,
    data,
    maxHeight,
    columns,
    rowKey,
    unselectable,
    tableHeader,
    selectedRow,
  } = props;
  const theme = useTheme();
  const styles = ({
    container: {
      maxHeight,
      width: "100%",
      scrollbarWidth: "thin",
    },
    tableRow: {
      "&$selected, &$selected:hover": {
        backgroundColor: theme.palette.secondary.main,
      },
    },
    outlinedDiv: {
      width: "100%",
    },
    cell: {
      fontSize: 11,
      padding: "6px 6px 6px 6px",
    },
    selected: {},
  });

  // eslint-disable-next-line react/display-name
  const InputComponent = React.forwardRef((props, ref) =>
    <div ref={ref} {...props} />);

  const OutlinedDiv = ({ children, label }) => {
    return (
      <TextField
        sx={styles.outlinedDiv}
        variant="outlined"
        label={label}
        multiline
        InputLabelProps={{ shrink: true }}
        InputProps={{
          inputComponent: InputComponent,
        }}
        inputProps={{ children }}
      />
    );
  };

  return (
    <OutlinedDiv label={fieldLabel}>
      <TableContainer sx={styles.container}>
        <Table size="small">
          {tableHeader ? (
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    sx={ styles.cell }
                    size="small"
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
          ) : null}
          <TableBody>
            {data.map((row, rowIndex) => {
              return (
                <TableRow
                  hover
                  tabIndex={-1}
                  key={`row_${rowIndex}`}
                  sx={styles.tableRow}
                  classes={{ selected: styles.selected }}
                  selected={
                    unselectable
                      ? false
                      : row[rowKey] === (selectedRow ? selectedRow[rowKey] : "")
                  }
                  onClick={unselectable ? null : () => setSelectedRow(row)}
                >
                  {columns.map((column, colIndex) => {
                    const value = row[column.id];
                    return (
                      <TableCell
                        sx={ styles.cell }
                        size="small"
                        key={`row_${rowIndex}_column_${colIndex}`}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {value?.trim()}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </OutlinedDiv>
  );
};
