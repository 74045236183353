/* eslint-disable max-len */
/* Root component representing the Details panel */
import React, { Fragment, useContext } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DetailsPanel } from 'mdp/components/Miscellaneous/DetailsPanel';
import { FlightDeckEffectsFiltersContext } from 'mdp/contexts/FlightDeckEffectsFiltersContext';
import 'mdp/components/FlightDeckEffectsTab/FlightDeckEffectsDetails/FlightDeckEffectsDetails.css';
import { DetailsPanelFimLinks } from 'mdp/components/FlightDeckEffectsTab/FlightDeckEffectsDetails/FIM/DetailsPanelFimLinks';

export const FlightDeckEffectsDetails = (props) => {
  const styles = ({
    accordianRoot: {
      boxShadow: 'none',
    },
    accordianLabel: {
      fontSize: 16,
    },
  });

  const { selectedRow } = useContext(FlightDeckEffectsFiltersContext);

  return (
    <>
      <Fragment>
        <DetailsPanel title="Fault Isolation" hideIcon={true}>
          <Accordion sx={styles.accordianRoot}>
            <AccordionSummary
              sx={styles.accordianLabel}
              expandIcon={<ExpandMoreIcon color="primary" />}
            >
              FIM Links
            </AccordionSummary>
            <AccordionDetails>
              <DetailsPanelFimLinks selectedRow={selectedRow} />
            </AccordionDetails>
          </Accordion>
        </DetailsPanel>
      </Fragment>
    </>
  );
};
