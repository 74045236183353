import React, { Fragment } from "react";
import { Tooltip, Button } from "@mui/material/";
import { apiFetcher } from "utils/apiUtils";
import { config } from "config/config";


export const EntryCloseButton = (props) => {
  const {
    hasTableData,
    selectedRows,
    setShowAlertPopup,
    setMessageInfo,
    reFetchTableData,
  } = props;

  const styles = ({
    tableIconButton: {
      padding: "3 10 3 10",
      margin: "0 0 0 0",
      backgroundColor: "rgba(202, 204, 206, 0.38)",
      color: "black",
      "&:hover": {
        backgroundColor: "rgba(202, 204, 206)",
      },
    },
  });

  const handleClick = () => {
    // if no data is selected to download
    if (hasTableData && selectedRows.length === 0) {
      setMessageInfo({
        message: "No data is selected",
        severity: "error",
      });
      setShowAlertPopup(true);
    // if no search performed
    } else if (selectedRows.length === 0) {
      setMessageInfo({
        message: "A valid search must be performed first",
        severity: "error",
      });
      setShowAlertPopup(true);
    } else {
      // list of defect IDs that are going to be updated
      const defectIdList = selectedRows.flatMap(group => group.chronics?.map(row => row.defect_id.toString()));
      console.log(defectIdList);

      apiFetcher(
        `${config.apiURL}/orca/orca/entryupdates/close`,
        "POST",
        { defectid: defectIdList },
        { "Content-Type": "application/json" }
      )
      .then((response) => {
        setMessageInfo({
          message: "The selected entry(ies) is updated successfully",
          severity: "info",
        });
        setShowAlertPopup(true);
        reFetchTableData();
      })
      .catch((e) => {
        setMessageInfo({
          message: "Error",
          severity: "error",
        });
        setShowAlertPopup(true);
      });
    }
  };

  return (
    <Fragment>
      <Tooltip title="Remove Entry">
        <Button
          sx={styles.tableIconButton}
          variant="contained"
          disableElevation
          size="small"
          onClick={handleClick}
        >
          Close Item
        </Button>
      </Tooltip>
    </Fragment>
  );
};
