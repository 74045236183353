import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "orca/components/TabNav.css";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import FindInPageIcon from '@mui/icons-material/FindInPage';
import { ArrowBack } from "@mui/icons-material";
import orcaLogo from "assets/orca_logo.png";

const styles = ({
  icon: {
    paddingRight: "10px",
    width: "30px",
    height: "30px",
  },
  arrowBack: {
    transform: "rotate(90deg)",
  },
});

export const TabNav = (props) => {
  const history = useHistory();
  const location = useLocation();
  const [curTab, setCurTab] = useState("Timeline");

  // Returns active if the given tabname = curTab
  const isTabActive = (tabName) => {
    return curTab.split("/").slice(-1)[0] === tabName ? "active" : "";
  };

  // Update curTab upon route change
  useEffect(() => {
    setCurTab(location.pathname);
  }, [location]);

  const handleClick = (tabName) => {
    history.push(tabName);
  };

  return (
    <>
      <div className="tabBar">
        <ul className="nav">
          <li
            className={"tab " + isTabActive("Review")}
            onClick={() => {
              handleClick("Review");
            }}
          >
            <FindInPageIcon sx={styles.icon} color="primary" />
            <p className="tabLabel">Review</p>
          </li>
          <li
            className={"tab " + isTabActive("Alerts")}
            onClick={() => {
              handleClick("Alerts");
            }}
          >
            <NotificationsActiveIcon sx={styles.icon} color="primary" />
            <p className="tabLabelSmall">Special Alerts</p>
          </li>
          <li
            className={"tab " + isTabActive("Specialist")}
            onClick={() => {
              handleClick("Specialist");
            }}
          >
            <InsertChartIcon sx={styles.icon} color="primary" />
            <p className="tabLabel">SPLT</p>
          </li>
          <li
            className={"tab " + isTabActive("Timeline")}
            onClick={() => {
              handleClick("Timeline");
            }}
          >
            <img
              src={orcaLogo}
              style={styles.icon}
              color="primary"
              alt="orca logo" />
            <p className="tabLabel">ORCA</p>
          </li>
          <li
            className={"tab backTab"}
            onClick={() => {
              handleClick("/");
            }}
          >
            <ArrowBack sx={styles.arrowBack} color="white" />
          </li>
        </ul>
      </div>
    </>
  );
};
