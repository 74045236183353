/* Component representing the expanded popup version of the "Details" dropdown
tab in the message details panel */
import React, { Fragment, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { DefaultText } from "mdp/components/Miscellaneous/DefaultText";
import "mdp/components/MessagesTab/MessageDetails/MessageDetailsPanel.css";
import { LinkTable } from "mdp/components/MessagesTab/MessageDetails/FIM/LinkTable";

export const DetailsPanelFimLinks = (props) => {
  const {
    panelState,
    selectedEQID,
    isMessageDetailsLoading,
    messageDetailsError,
  } = props;
  const [selectedRow, setSelectedRow] = useState({});
  const FimLinksColumns = [{ id: "LINK", minWidth: "100%", align: "left" }];

  return (
    <Fragment>
      {/* If there's an error fetching the data */}
      {messageDetailsError ? (
        <div>
          <DefaultText text={messageDetailsError.toString()} />
        </div>
      ) : null}
      {/* if data is loading show spinner */}
      {isMessageDetailsLoading ? (
        <div>
          <CircularProgress color="primary" />
        </div>
      ) : null}
      {/* show data if not error and not loading */}
      {!isMessageDetailsLoading &&
      !messageDetailsError &&
      panelState &&
      panelState.FIM_LINKS ? (
        // <div>
        //   <Grid container spacing={3}>
        //     <Grid item xs>
        //       <p>Links to FIM PDFs</p>
        //       <List>
        //         {panelState?.FIM_LINKS?.filter(
        //             (i) => i.EQ_ID === selectedEQID
        //           ).map((value) => {
        //           return <ListItem><a href="https://www.mhi.com/" target="_blank">{value.LINK}</a></ListItem>;
        //         })}
        //       </List>
        //     </Grid>
        //   </Grid>
        // </div>
        <div className="detailsPanelDataTable">
          <div>
            <LinkTable
              maxHeight="102px"
              fieldLabel="FIM Task Link(s)"
              data={panelState?.FIM_LINKS?.filter(
                (i) => i.EQ_ID === selectedEQID
              )}
              columns={FimLinksColumns}
              selectedRow={selectedRow}
              setSelectedRow={setSelectedRow}
              rowKey="LINK"
              AC_SN={panelState?.AC_SN}
            />
          </div>
        </div>
      ) : null}
    </Fragment>
  );
};
