import React, { useState, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import DnaHomepage from 'dna/dna_homepage';
import { TabNavigator } from 'mdp/TabNavigator';
import { OrcaTabNavigator } from 'orca/OrcaTabNavigator';
import { ErrorPopup } from 'dna/ErrorPopup';
import { apiFetcher } from 'utils/apiUtils';
import { config } from 'config/config';

const appComponents = {
  mdp: TabNavigator,
  orca: OrcaTabNavigator,
  trendAnalysis: DnaHomepage,
};

const checkAccess = (component, apps) => {
  if (Array.isArray(apps) && apps.includes(component)) {
    return appComponents[component];
  } else {
    return ErrorPopup;
  }
};

export const AppRouter = () => {
  const [apps, setApps] = useState(null);
  const [isAccessLoading, setIsAccessLoading] = useState(true);

  useEffect(() => {
    const getAccess = async () => {
      await apiFetcher(`${config.apiURL}/mdp/checkAccess`, 'GET').then(
          (response) => {
            const componentData = response.data.map((item) =>
              item.COMPONENT.toLowerCase(),
            );
            setApps(componentData);
            setIsAccessLoading(false);
          },
      );
    };

    if (apps == null) {
      getAccess();
    }
  }, []);

  if (isAccessLoading) {
    return <div style={{ width: '100%', height: '100vh' }}>
      <CircularProgress style={{ marginTop: '50vh', marginLeft: '50vw' }}/>
    </div>;
  }

  return (
    <Switch>
      <Route exact path="/" component={DnaHomepage} />
      <Route path="/mdp" component={checkAccess('mdp', apps)} />
      <Route path="/orca" component={checkAccess('orca', apps)} />
      <Route
        path="/trendAnalysis"
        component={checkAccess('trendAnalysis', apps)}
      />
      <Route path="*" component={ErrorPopup} />
    </Switch>
  );
};
