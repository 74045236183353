import { useState, useEffect } from 'react';

export const getAuthInfo = async () => {
  /**
   * Fetches authentication info provided by Azure authN/authZ (easy auth)
   */
  const options = { method: 'GET' };
  try {
    const response = await fetch('/.auth/me', options);
    const authInfo = await response.json();
    return authInfo;
  } catch (error) {
    console.error('Failed to get auth info', error);
    return false;
  }
};

export const apiFetcher = async (
    url,
    method,
    body,
    headers,
    parseJSON = true,
) => {
  const tokenStorage = JSON.parse(localStorage.getItem('okta-token-storage'));
  const accessToken = tokenStorage.accessToken.value || tokenStorage.accessToken.accessToken;
  /*
  An async function for making requests to a backend API.
  Adds Azure Easy Auth's access token of the signed-in user
  to the authorization header of each request to the backend
  API

  @param <url:string>: The api endpoint
  @param <method:string>: HTTP method e.g. "GET", "POST"
  @param <body:JSON>: Body of the request
  @param <headers:JSON>: HTTP headers of the request

  @return <JSON>: The JSON object returned from the API
  */

  try {
    headers = {
      ...headers,
      Authorization: `Bearer ${accessToken}`,
    };
  } catch (e) {
    return 'No access token detected. Please sign in or refresh the page';
  }
  const options = {
    headers,
    method,
    body: JSON.stringify(body),
  };

  let response = await fetch(url, options);
  if (!response.ok) {
    const error = new Error(response.statusText);
    error.response = response;
    error.status = response.status;
    throw error;
  } else if (parseJSON) {
    response = await response.json();
  }
  return response;
};

export const useFetch = (url, method, body, headers) => {
  /*
  A custom hook for fetching data from the backend API

  @param <url:string>: The api endpoint

  @return <JSON>: The JSON object returned from the API
  */

  const [data, setData] = useState({});
  const [error, setError] = useState(null);

  useEffect(() => {
    apiFetcher(url, method, body, headers)
        .then((response) => setData(response))
        .catch((e) => setError(e));
  }, [url]);

  return { data, error };
};
