/* eslint-disable max-len */
import React, { useContext } from 'react';
import { EqIDTablePanelButtons } from 'mdp/components/EqIDLookupTab/EqIDTable/EqIDTablePanelButtons';
import { TablePanel } from 'mdp/components/Miscellaneous/TablePanel';
import { MainDataTable } from 'mdp/components/Miscellaneous/MainDataTable';
import { EqIDDataContext } from 'mdp/contexts/EqIDDataContext';
import { EqIDFiltersContext } from 'mdp/contexts/EqIDFiltersContext';
import { formatFiltersState } from 'mdp/components/EqIDLookupTab/Filters/EqIDFiltersPanel';
import { config } from 'config/config';

const columns = [
  {
    key: 'AC_MODEL',
    label: 'AC Model',
    minWidth: 115,
    sortable: true,
  },
  {
    key: 'EQ_ID',
    label: 'Equation ID',
    minWidth: 160,
    sortable: true,
  },
  {
    key: 'MSG_TXT',
    label: 'Message',
    minWidth: 250,
    sortable: true,
  },
  {
    key: 'EICAS',
    label: 'EICAS',
    minWidth: 250,
    sortable: true,
  },
  {
    key: 'LRU_NAME',
    label: 'LRU',
    minWidth: 160,
    sortable: true,
  },
  {
    key: 'ATA',
    label: 'ATA',
    minWidth: 100,
    sortable: true,
  },
  {
    key: 'MDT_VERSION',
    label: 'MDT Version',
    minWidth: 180,
    sortable: true,
  },
];

export const EqIDTablePanel = (props) => {
  /*
  @props isDetailsPanelExpand:bool: is the details panel expanded modal open
  @props setIsDetailsPanelExpand: setter for isDetailsPanelExpand
  @props isDetailsPanelExpand:bool: is the details panel expanded modal open
  @props setIsDetailsPanelExpand: setter for isDetailsPanelExpand
  @props hideDetails:bool: is the Details panel hidden
  @props setHideDetails: setter for setHideDetails
  @props hideFilters:bool: is the Filters panel hidden
  @props setHideFilters: setter for setHideFilters
  @props <rawDataFilterState:object>: state of the filters panel
  @props <setRawDataFilterState>: setter for rawDataFilterState
  */
  const {
    setIsDetailsPanelExpand,
    specialistEditMode,
    setHideFilters,
    hideDetails,
    isDetailsPanelExpand,
    setHideDetails,
    hideFilters,
  } = props;

  // column definitions of the equation ID lookup table
  const {
    eqIDFilterState,
    setEqIDFilterState,
    appliedEqIDFilters,
    rowsPerPage,
    setRowsPerPage,
    curTablePage,
    setCurTablePage,
    selectedRow,
    setSelectedRow,
    setMimicApplyClick,
  } = useContext(EqIDFiltersContext);
  const {
    eqIDTableState,
    setEqIDTableState,
    isLoading,
    setIsLoading,
    error,
    setError,
  } = useContext(EqIDDataContext);

  return (
    <TablePanel
      title="MDC Troubleshooting"
      headerButtons={<EqIDTablePanelButtons />}
      hideDetails={hideDetails}
      hideFilters={hideFilters}
      setHideDetails={setHideDetails}
      setHideFilters={setHideFilters}
    >
      <MainDataTable
        url={`${config.apiURL}/mdp/equationids`}
        columns={columns}
        rowKey="RECORD_ID"
        tableData={eqIDTableState}
        setTableData={setEqIDTableState}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        error={error}
        setError={setError}
        appliedFilters={appliedEqIDFilters}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        curTablePage={curTablePage}
        setCurTablePage={setCurTablePage}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        isDetailsPanelExpand={isDetailsPanelExpand}
        setIsDetailsPanelExpand={setIsDetailsPanelExpand}
        eqIDTableState={eqIDTableState}
        setEqIDTableState={setEqIDTableState}
        setMimicApplyClick={setMimicApplyClick}
        filterState={eqIDFilterState}
        setFilterState={setEqIDFilterState}
        formatFilters={formatFiltersState}
        specialistEditMode={specialistEditMode}
      />
    </TablePanel>
  );
};
