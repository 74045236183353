/* Root component representing the Filter panel */
import React, { Fragment, useState, useContext } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SelectBox } from 'mdp/components/Miscellaneous/SelectBox';
import { CheckBoxSelect } from 'mdp/components/Miscellaneous/CheckBoxSelect';
import { FiltersPanel } from 'mdp/components/Miscellaneous/FiltersPanel';
import { EqIDDataContext } from 'mdp/contexts/EqIDDataContext';
import { EqIDFiltersContext } from 'mdp/contexts/EqIDFiltersContext';
import { useFetch } from 'utils/apiUtils';
import { config } from 'config/config';
import { PropTypes } from 'prop-types';


export const formatFiltersState = (filterState) => {
  // apply these formatting before the search is sent to backend
  if (filterState.acModel.length) {
    return {
      ...filterState,
      acModel: filterState.acModel === config.CRJ ? 'CRJ' : 'CRJ700',
      eqIDs: filterState.eqIDs.map((i) => i.Value),
      ata: filterState.ata.map((i) => i.Value),
      lru: filterState.lru.map((i) => i.Value),
      messages: filterState.messages.map((i) => i.Value),
      mdtVersion: filterState.mdtVersion.map((i) => i.Value),
      eicas: filterState.eicas.map((i) => i.Value),
    };
  } else {
    return filterState;
  }
};

export const EqIDFiltersPanel = (props) => {
  const { specialistEditMode } = props;
  const styles = ({
    accordianRoot: {
      boxShadow: 'none',
    },
    accordianLabel: {
      fontSize: 16,
    },
    searchBar: {
      marginTop: '30px',
      width: '100%',
    },
    searchBarText: {
      fontSize: 12,
    },
  });

  // whether filter components should check if their value is empty
  const [shouldValidateFilters, setShouldValidateFilters] = useState(false);
  const {
    setMimicApplyClick,
    mimicApplyClick,
    eqIDFilterState,
    setEqIDFilterState,
    setAppliedEqIDFilters,
    defaultEqIDFilterState,
    setCurTablePage,
  } = useContext(EqIDFiltersContext);
  const {
    setEqIDTableState,
    isLoading: isEqIDTabLoading,
    setIsLoading: setIsEqIDTabLoading,
    setError,
  } = useContext(EqIDDataContext);

  // fetch filter dropdown options
  const { data: eqidList, error: eqidListError } = useFetch(
      `${config.apiURL}/mdp/filteroptions/eqid`,
      'GET',
  );
  const { data: msgTxtList, error: msgTxtListError } = useFetch(
      `${config.apiURL}/mdp/filteroptions/messagetext`,
      'GET',
  );
  const { data: mdtVersionList, error: mdtVersionListError } = useFetch(
      `${config.apiURL}/mdp/filteroptions/mdtversion`,
      'GET',
  );
  const { data: ataList, error: ataListError } = useFetch(
      `${config.apiURL}/mdp/filteroptions/ata`,
      'GET',
  );
  const { data: lruList, error: lruListError } = useFetch(
      `${config.apiURL}/mdp/filteroptions/lru`,
      'GET',
  );

  const { data: eicasMsgList, error: eicasMsgListError } = useFetch(
    `${config.apiURL}/mdp/filteroptions/eicas`,
    "GET"
  );

  const isFiltersLoading = () => {
    return Boolean(
        (!ataList.data ||
        !eqidList.data ||
        !lruList.data ||
        !mdtVersionList.data ||
        !msgTxtList.data ||
        !eicasMsgList.data) &&
        !ataListError &&
        !eqidListError &&
        !lruListError &&
        !msgTxtListError &&
        !mdtVersionListError,
    );
  };
  const isFiltersFetchError = () => {
    return Boolean(
        ataListError ||
        eqidListError ||
        lruListError ||
        msgTxtListError ||
        mdtVersionListError ||
        eicasMsgListError,
    );
  };
  const isFiltersValid = () => {
    // make sure ac model filters isnt empty before starting a query
    return Boolean(eqIDFilterState.acModel.length);
  };
  const filterOptionsByACModel = (options) => {
    /*
    @props <options:list>: list of dropdown options
    [{value:"",AC_MODEL:""},{value:"",AC_MODEL:""},...]

    @return <list>: returns the options list filtered on the
    acmodel that the user currently has selected. Returns empty list
    if no ac model is selected
    */
    if (eqIDFilterState.acModel.length) {
      // our database uses CRJ and CRJ700 to identify CRJ200 and
      // CRJ700/900/1000 respectively, when displying models to the
      // user, make sure to use CRJ200 and CRJ700/900/1000

      return options.data.reduce(
        (i, j) => {
          if (j.AC_MODEL === (
            eqIDFilterState.acModel === config.CRJ ? 'CRJ' : 'CRJ700'
          )) {
            i.push({ ...j, AC_MODEL: eqIDFilterState.acModel });
          }
          return i
        }, []);
    } else {
      return [];
    }
  };

  EqIDFiltersPanel.propTypes = {
    specialistEditMode: PropTypes.bool.isRequired,
  };

  return (
    <FiltersPanel
      applyURL={`
      ${config.apiURL}/mdp/equationids?offset=0&limit=${config.initialFetchLimit}`}
      filterStateName="EqIdFiltersState"
      filterState={eqIDFilterState}
      setFilterState={setEqIDFilterState}
      formatFilters={formatFiltersState}
      setAppliedFilters={setAppliedEqIDFilters}
      defaultFilterState={defaultEqIDFilterState}
      isFiltersLoading={isFiltersLoading()}
      isFiltersFetchError={isFiltersFetchError()}
      isFiltersValid={isFiltersValid()}
      isTableDataLoading={isEqIDTabLoading}
      setIsTableDataLoading={setIsEqIDTabLoading}
      setTableDataState={setEqIDTableState}
      setError={setError}
      setCurTablePage={setCurTablePage}
      shouldValidateFilters={shouldValidateFilters}
      setShouldValidateFilters={setShouldValidateFilters}
      setMimicApplyClick={setMimicApplyClick}
      mimicApplyClick={mimicApplyClick}
      popupText="Aircraft Model filters cannot be empty!"
      specialistEditMode={specialistEditMode}
    >
      {!isFiltersLoading() && !isFiltersFetchError() && (
        <Fragment>
          <Accordion sx={styles.accordianRoot}>
            <AccordionSummary
              sx={styles.accordianLabel}
              expandIcon={<ExpandMoreIcon color="primary" />}
            >
              Aircraft Model
            </AccordionSummary>
            <AccordionDetails>
              <CheckBoxSelect
                filterState={eqIDFilterState}
                setFilterState={setEqIDFilterState}
                options={[config.CRJ, config.CRJ700]}
                filterName="acModel"
                shouldValidate={shouldValidateFilters}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion sx={styles.accordianRoot}>
            <AccordionSummary
              sx={styles.accordianLabel}
              expandIcon={<ExpandMoreIcon color="primary" />}
            >
              Equation ID
            </AccordionSummary>
            <AccordionDetails>
              <SelectBox
                multiple
                groupName="AC_MODEL"
                groupOptionLabel="Value"
                noOptionsText={'Select an AC Model first'}
                selectOptions={filterOptionsByACModel(eqidList)}
                filterState={eqIDFilterState}
                setFilterState={setEqIDFilterState}
                filterName="eqIDs"
                excludeName="excludeEqIDs"
              />
            </AccordionDetails>
          </Accordion>
          <Accordion sx={styles.accordianRoot}>
            <AccordionSummary
              sx={styles.accordianLabel}
              expandIcon={<ExpandMoreIcon color="primary" />}
            >
              Message Text
            </AccordionSummary>
            <AccordionDetails>
              <SelectBox
                multiple
                groupName="AC_MODEL"
                groupOptionLabel="Value"
                noOptionsText={'Select an AC Model first'}
                selectOptions={filterOptionsByACModel(msgTxtList)}
                filterState={eqIDFilterState}
                setFilterState={setEqIDFilterState}
                filterName="messages"
                excludeName="excludeMessages"
              />
            </AccordionDetails>
          </Accordion>
          <Accordion sx={styles.accordianRoot}>
            <AccordionSummary
              sx={styles.accordianLabel}
              expandIcon={<ExpandMoreIcon color="primary" />}
            >
              LRU
            </AccordionSummary>
            <AccordionDetails>
              <SelectBox
                multiple
                groupName="AC_MODEL"
                groupOptionLabel="Value"
                noOptionsText={'Select an AC Model first'}
                selectOptions={filterOptionsByACModel(lruList)}
                filterState={eqIDFilterState}
                setFilterState={setEqIDFilterState}
                filterName="lru"
                excludeName="excludeLru"
              />
            </AccordionDetails>
          </Accordion>
          <Accordion sx={styles.accordianRoot}>
            <AccordionSummary
              sx={styles.accordianLabel}
              expandIcon={<ExpandMoreIcon color="primary" />}
            >
              ATA
            </AccordionSummary>
            <AccordionDetails>
              <SelectBox
                multiple
                groupName="AC_MODEL"
                groupOptionLabel="Value"
                noOptionsText={'Select an AC Model first'}
                selectOptions={filterOptionsByACModel(ataList)}
                filterState={eqIDFilterState}
                setFilterState={setEqIDFilterState}
                filterName="ata"
                excludeName="excludeAta"
              />
            </AccordionDetails>
          </Accordion>
          <Accordion sx={styles.accordianRoot}>
            <AccordionSummary
              sx={styles.accordianLabel}
              expandIcon={<ExpandMoreIcon color="primary" />}
            >
              MDT Version
            </AccordionSummary>
            <AccordionDetails>
              <SelectBox
                multiple
                groupName="AC_MODEL"
                groupOptionLabel="Value"
                noOptionsText={'Select an AC Model first'}
                selectOptions={filterOptionsByACModel(mdtVersionList)}
                filterState={eqIDFilterState}
                setFilterState={setEqIDFilterState}
                filterName="mdtVersion"
                excludeName="excludeMdtVersion"
              />
            </AccordionDetails>
          </Accordion>
          {eqIDFilterState.acModel !== "CRJ100/200/400" && (
              <Accordion sx={styles.accordianRoot}>
                <AccordionSummary
                  sx={styles.accordianLabel}
                  expandIcon={<ExpandMoreIcon color="primary" />}
                >
                  EICAS
                </AccordionSummary>
                <AccordionDetails>
                  <SelectBox
                    multiple
                    groupName="AC_MODEL"
                    groupOptionLabel="Value"
                    noOptionsText={eqIDFilterState.acModel.length
                      ? "Subscription Required" : "Select an AC Model first"}
                    selectOptions={filterOptionsByACModel(eicasMsgList)}
                    filterState={eqIDFilterState}
                    setFilterState={setEqIDFilterState}
                    filterName="eicas"
                    excludeName="excludeEicas"
                    limit={eicasMsgList?.data?.length ?? 100}
                  />
                </AccordionDetails>
              </Accordion>
            )}
        </Fragment>
      )}
    </FiltersPanel>
  );
};
