import React, { useContext, useEffect } from "react";
import { MessagesPanelButtons } from "mdp/components/MessagesTab/MessagesTable/MessagesPanelButtons";
import { MainDataTable } from "mdp/components/MessagesTab/MessagesTable/Table";
import { TablePanel } from "mdp/components/Miscellaneous/TablePanel";
import { MessageDataContext } from "mdp/contexts/MessageDataContext";
import { MessageFiltersContext } from "mdp/contexts/MessageFiltersContext";
import { formatFiltersState } from "mdp/components/MessagesTab/Filters/MessageFiltersPanel";
import { config } from "config/config";

// column definitions of the main table
const columnsCRJ700 = [
  {
    key: "AC_SN",
    label: "SN",
    minWidth: 60,
    sortable: true,
  },
  {
    key: "AC_TN",
    label: "TN",
    minWidth: 60,
    sortable: true,
  },
  {
    key: "MSG_Date",
    label: "Date",
    minWidth: 150,
    sortable: true,
  },
  {
    key: "MSG_TXT",
    label: "Message Text",
    minWidth: 190,
    sortable: true,
  },
  {
    key: "EICAS",
    label: "EICAS",
    minWidth: 190,
    sortable: true,
  },
  {
    key: "ATA",
    label: "ATA",
    minWidth: 50,
    sortable: true,
  },
  {
    key: "LRU",
    label: "LRU",
    minWidth: 160,
    sortable: true,
  },
  {
    key: "EQ_ID",
    label: "Eq. ID",
    minWidth: 120,
    sortable: true,
  },
  {
    key: "FLIGHT_PHASE",
    label: "PoF",
    minWidth: 80,
    sortable: true,
  },
  {
    key: "MSG_TYPE",
    label: "Type",
    minWidth: 180,
    sortable: true,
  },
  {
    key: "FLIGHT_NUM",
    label: "Flt Num",
    minWidth: 100,
    sortable: true,
  },
  {
    key: "FLIGHT_LEG",
    label: "Flt Leg",
    minWidth: 100,
    sortable: true,
  },
];
const columnsCRJ = [
  {
    key: "AC_SN",
    label: "SN",
    minWidth: 50,
    sortable: true,
  },
  {
    key: "AC_TN",
    label: "TN",
    minWidth: 60,
    sortable: true,
  },
  {
    key: "MSG_Date",
    label: "Date",
    minWidth: 150,
    sortable: true,
  },
  {
    key: "MSG_TXT",
    label: "Message Text",
    minWidth: 190,
    sortable: true,
  },
  {
    key: "ATA",
    label: "ATA",
    minWidth: 50,
    sortable: true,
  },
  {
    key: "LRU",
    label: "LRU",
    minWidth: 160,
    sortable: true,
  },
  {
    key: "EQ_ID",
    label: "Eq. ID",
    minWidth: 120,
    sortable: true,
  },
  {
    key: "FLIGHT_LEG",
    label: "Flt Leg",
    minWidth: 100,
    sortable: true,
  },
];

/* eslint-disable max-len */
export const reformatMsgDates = (dateFormat, tableData) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  if (tableData && tableData.data) {
    const newTable = tableData;
    for (let index = 0, l = tableData.data.length; index < l; index += 1) {
      const entry = tableData.data[index];
      const msgDate = new Date(entry.MSG_Date);
      const date = msgDate.getDate().toString().padStart(2, "0");
      const month = msgDate.getMonth();
      const year = msgDate.getFullYear().toString();
      const minutes = msgDate.getMinutes().toString().padStart(2, "0");
      const hours = msgDate.getHours().toString().padStart(2, "0");

      if (dateFormat === "DD/MM/YYYY") {
        // DD-month abbreviated-year HH:SS
        entry.MSG_Date = `${date}-${months[month]}-${year} ${hours}:${minutes}`;
      } else if (dateFormat === "MM/DD/YYYY") {
        // Month abbreviated-DD-YY HH:SS
        entry.MSG_Date = `${months[month]}-${date}-${year} ${hours}:${minutes}`;
      } else {
        // Default to the original format YYYY/MM/DD H:SS
        entry.MSG_Date = `${year}-${months[month]}-${date} ${hours}:${minutes}`;
      }
    }
    return newTable;
  }
  return tableData;
};

/* eslint-enable max-len */
export const MessageTablePanel = (props) => {
  /*
  @props isDetailsPanelExpand:bool: is the details panel expanded modal open
  @props setIsDetailsPanelExpand: setter for isDetailsPanelExpand
  @props isDetailsPanelExpand:bool: is the details panel expanded modal open
  @props setIsDetailsPanelExpand: setter for isDetailsPanelExpand
  @props hideDetails:bool: is the Details panel hidden
  @props setHideDetails: setter for setHideDetails
  @props hideFilters:bool: is the Filters panel hidden
  @props setHideFilters: setter for setHideFilters
  */
  const {
    hideFilters,
    specialistEditMode,
    setHideFilters,
    isDetailsPanelExpand,
    setHideDetails,
    setIsDetailsPanelExpand,
    hideDetails,
  } = props;
  const {
    messageTableState,
    setMessageTableState,
    isLoading,
    setIsLoading,
    error,
    setError,
  } = useContext(MessageDataContext);
  const {
    messageFilterState,
    setMessageFilterState,
    appliedMessageFilters,
    rowsPerPage,
    setRowsPerPage,
    curTablePage,
    setCurTablePage,
    selectedRow,
    setSelectedRow,
    setMimicApplyClick,
  } = useContext(MessageFiltersContext);

  useEffect(() => {
    setMessageTableState(reformatMsgDates(
      messageFilterState.dateFormat,
      messageTableState
    ));
  }, [messageFilterState.dateFormat])

  return (
    <TablePanel
      title="MDC Messages"
      headerButtons={<MessagesPanelButtons />}
      hideDetails={hideDetails}
      hideFilters={hideFilters}
      setHideDetails={setHideDetails}
      setHideFilters={setHideFilters}
    >
      <MainDataTable
        url={`${config.apiURL}/mdp/mdcmessages`}
        columns={
          appliedMessageFilters?.acModel === config.CRJ
            ? columnsCRJ
            : columnsCRJ700
        }
        rowKey="MSG_ID"
        tableData={reformatMsgDates(
          messageFilterState.dateFormat,
          messageTableState
        )}
        setTableData={setMessageTableState}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        error={error}
        setError={setError}
        messageFilterState={messageFilterState}
        setMessageFilterState={setMessageFilterState}
        appliedFilters={appliedMessageFilters}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        curTablePage={curTablePage}
        setCurTablePage={setCurTablePage}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        isDetailsPanelExpand={isDetailsPanelExpand}
        setIsDetailsPanelExpand={setIsDetailsPanelExpand}
        setMimicApplyClick={setMimicApplyClick}
        formatFilters={formatFiltersState}
        specialistEditMode={specialistEditMode}
      />
    </TablePanel>
  );
};
