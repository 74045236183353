import React, { useState, useContext } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Button } from "@mui/material";
import { DefaultText } from "mdp/components/Miscellaneous/DefaultText";
import { MessageFiltersContext } from "mdp/contexts/MessageFiltersContext";
import { config } from "config/config";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import "mdp/components/MessagesTab/MessageDetails/MessageDetailsPanel.css";
import {
  SurroundingMessagesTable,
  // eslint-disable-next-line max-len
} from "mdp/components/MessagesTab/MessageDetails/SurroundingMessages/surroundingMessagesTable";

export const DetailsPanelSurroundingMessagesTab = (props) => {
  const {
    panelState,
    selectedRow,
    surroundingMessages,
    isSurroundingMessagesLoading,
    surroundingMessagesError,
    setIsDetailsPanelExpand,
    specialistEditMode,
    inExpanded,
  } = props;
  const {
    setMimicApplyClick,
    setMessageFilterState,
    defaultMessageFilterState,
    messageFilterState,
  } = useContext(MessageFiltersContext);

  const [openConfirmation, setOpenConfirmation] = useState(false);

  const getSurroundingDates = (date) => {
    // converting to date so adding minutes goes past midnight to the next day.
    const dateObj = new Date(date);
    const startDateObj = new Date(dateObj.getTime() - 3 * 60000);
    const endDateObj = new Date(dateObj.getTime() + 3 * 60000);

    const startYear = startDateObj.getFullYear();
    const startMonth = (startDateObj.getMonth() + 1)
      .toString()
      .padStart(2, "0");
    const startDay = startDateObj.getDate().toString().padStart(2, "0");
    const startHour = startDateObj.getHours().toString().padStart(2, "0");
    const startMinute = startDateObj.getMinutes().toString().padStart(2, "0");

    const endYear = endDateObj.getFullYear();
    const endMonth = (endDateObj.getMonth() + 1).toString().padStart(2, "0");
    const endDay = endDateObj.getDate().toString().padStart(2, "0");
    const endHour = endDateObj.getHours().toString().padStart(2, "0");
    const endMinute = endDateObj.getMinutes().toString().padStart(2, "0");

    const startDate = `${startYear}-${startMonth}-${startDay}`;
    const endDate = `${endYear}-${endMonth}-${endDay}`;
    const startTime = `${startHour}:${startMinute}`;
    const endTime = `${endHour}:${endMinute}`;

    return { startDate, startTime, endDate, endTime };
  };

  const onButtonClick = () => {
    setOpenConfirmation(true);
  };

  const handleDiscard = () => {
    const dateFilters = getSurroundingDates(panelState.MSG_Date);
    setMessageFilterState({
      ...defaultMessageFilterState,
      acsn: [
        {
          Value: panelState.AC_SN,
          AC_MODEL: panelState.AC_MODEL === "CRJ" ? config.CRJ : config.CRJ700,
        },
      ],
      acModel: panelState.AC_MODEL === "CRJ" ? config.CRJ : config.CRJ700,
      startDate: dateFilters.startDate,
      startTime: dateFilters.startTime,
      endDate: dateFilters.endDate,
      endTime: dateFilters.endTime,
      dateFormat: messageFilterState.dateFormat,
    });
    setMimicApplyClick(true);
    setOpenConfirmation(false);
    if (specialistEditMode !== true && setIsDetailsPanelExpand) {
      setIsDetailsPanelExpand(false);
    }
  };

  const handleClose = () => {
    setOpenConfirmation(false);
  };

  // selected row in the "Data logic" table
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div
          className="detailsPanelDataTable"
          style={{ margin: "0", width: "100%" }}
        >
          {/* If there's an error fetching the data */}
          {surroundingMessagesError ? (
            <div>
              <DefaultText text={surroundingMessagesError.toString()} />
            </div>
          ) : null}
          {/* if data is loading show spinner */}
          {isSurroundingMessagesLoading ? (
            <div>
              <CircularProgress color="primary" />
            </div>
          ) : null}
          {/* show data if not error and not loading */}
          {!isSurroundingMessagesLoading &&
          !surroundingMessagesError &&
          surroundingMessages ? (
            <SurroundingMessagesTable
              tableHeader
              maxHeight="150px"
              fieldLabel={`${
                inExpanded !== undefined
                  ? "Surrounding Messages (+/- 3 Minutes)"
                  : ""
              }`}
              data={surroundingMessages}
              columns={[
                {
                  id: "MSG_TXT",
                  minWidth: "100%",
                  align: "left",
                  label: "Message Text",
                },
                {
                  id: "EQ_ID",
                  minWidth: "100%",
                  align: "left",
                  label: "Eq. ID",
                },
                {
                  id: "MSG_Date",
                  minWidth: "100%",
                  align: "left",
                  label: "Date",
                },
                {
                  id: "MSG_TYPE",
                  minWidth: "100%",
                  align: "left",
                  label: "Type",
                },
                { id: "ATA", minWidth: "100%", align: "left", label: "ATA" },
              ]}
              rowKey="LINK"
              AC_SN={panelState?.AC_SN}
              selectedRow={selectedRow}
            />
          ) : null}
        </div>
        <div>
          {selectedRow && (
            <Button
              sx={{ px: 20, padding: '5px 15px' }}
              variant="outlined"
              color="primary"
              onClick={() => onButtonClick()}
            >
              Query all Surrounding Messages
            </Button>
          )}
        </div>
      </div>

      <Dialog maxWidth="xs" open={openConfirmation}>
        <DialogTitle>Discard Current Filter Settings?</DialogTitle>
        <DialogContent>
          <p>
            Continuing will create a new message query for surrounding messages.
          </p>
        </DialogContent>
        <DialogActions>
          <Button autoFocus color="primary" onClick={handleClose}>
            Cancel
          </Button>

          <Button color="error" onClick={handleDiscard}>
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
