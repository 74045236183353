import React, { Fragment, useEffect, useState } from "react";
import { StaticTextBox } from "mdp/components/Miscellaneous/StaticTextBox";
import CircularProgress from "@mui/material/CircularProgress";
import { DefaultText } from "mdp/components/Miscellaneous/DefaultText";
import { Button, Box } from "@mui/material";
import { apiFetcher } from "utils/apiUtils";
import { config } from "config/config";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";

import "mdp/components/MessagesTab/MessageDetails/MessageDetailsPanel.css";

export const DetailsPanelSpecialistInputTab = (props) => {
  const {
    panelState,
    selectedEQID,
    specialistEditMode,
    setSpecialistEditMode,
    isMessageDetailsLoading,
    messageDetailsError,
    inExpanded,
  } = props;
  const styles = ({
    buttonBox: {
      justifyContent: "space-between",
      display: "flex",
    },
  });
  const [openConfirmation, setOpenConfirmation] = useState(false);

  // Whats displayed on the tab
  const [currentRecommendedAction, setCurrentRecommendedAction] = useState();
  const [currentInputs, setCurrentInputs] = useState();
  const [currentAdditionalComments, setCurrentAdditionalComments] = useState();
  const [currentMMEL, setCurrentMMEL] = useState();
  // const [currentEicas, setCurrentEicas] = useState();

  // The unedited values. Used to restore the display values
  const [recommendedAction, setRecommendedAction] = useState();
  const [inputs, setInputs] = useState();
  const [additionalComments, setAdditionalComments] = useState();
  const [MMEL, setMMEL] = useState();
  // const [eicas, setEicas] = useState();


  useEffect(() => {
    setRecommendedAction(
      panelState?.SPECIALIST_INPUT?.filter(() => true)[0]?.RECOMMENDED_ACTION ||
        ""
    );
    setInputs(
      panelState?.SPECIALIST_INPUT?.filter(() => true)[0]?.INPUTS || ""
    );
    setAdditionalComments(
      panelState?.SPECIALIST_INPUT?.filter(() => true)[0]
        ?.ADDITIONAL_COMMENTS || ""
    );
    setMMEL(
      panelState?.MMEL?.filter(() => true)[0]?.MMEL ||
        ""
    );
    // setEicas(
    //   panelState?.EICAS_MESSAGE == null
    //                   ? ''
    //                   : panelState.EICAS_MESSAGE[0].MESSAGE
    // );
    setCurrentRecommendedAction(
      panelState?.SPECIALIST_INPUT?.filter(() => true)[0]?.RECOMMENDED_ACTION ||
        ""
    );
    setCurrentInputs(
      panelState?.SPECIALIST_INPUT?.filter(() => true)[0]?.INPUTS || ""
    );
    setCurrentAdditionalComments(
      panelState?.SPECIALIST_INPUT?.filter(() => true)[0]
        ?.ADDITIONAL_COMMENTS || ""
    );
    setCurrentMMEL(
      panelState?.MMEL?.filter(() => true)[0]?.MMEL ||
        ""
    );
    // setCurrentEicas(
    //   panelState?.EICAS_MESSAGE == null
    //                   ? ''
    //                   : panelState.EICAS_MESSAGE[0].MESSAGE
    // );
  }, [panelState?.SPECIALIST_INPUT]);

  const onEditClick = () => {
    setSpecialistEditMode(true);
  };

  const resetFrontendSpecialistInputs = () => {
    setCurrentRecommendedAction(recommendedAction);
    setCurrentInputs(inputs);
    setCurrentAdditionalComments(additionalComments);
    setCurrentMMEL(MMEL);
    // setCurrentEicas(eicas)
  };

  const onCancelClick = () => {
    setOpenConfirmation(true);
  };

  // WasEdited prevents use effect from refilling the specialistInput when its
  // deleted with the edit feature
  const onSaveClick = () => {
    apiFetcher(
      `${config.apiURL}/mdp/specialistInput`,
      "POST",
      {
        recommendedAction: currentRecommendedAction,
        inputs: currentInputs,
        additionalComments: currentAdditionalComments,
        MMEL: currentMMEL,
        // eicas: currentEicas,
        selectedEQID,
      },
      { "Content-Type": "application/json" }
    );
    setRecommendedAction(currentRecommendedAction);
    setInputs(currentInputs);
    setAdditionalComments(currentAdditionalComments);
    setMMEL(currentMMEL);
    // setEicas(currentEicas)
    setSpecialistEditMode(false);
    // trigger endpoint and send the three textfields as arguments to update
    // them in the db no matter whether they changed or not
  };

  const handleDiscard = () => {
    setOpenConfirmation(false);
    resetFrontendSpecialistInputs();
    setSpecialistEditMode(false);
  };

  const handleCancelDiscard = () => {
    setOpenConfirmation(false);
  };

  // selected row in the "Data logic" table
  return (
    <Fragment>
      {/* If there's an error fetching the data */}
      {messageDetailsError ? (
        <div>
          <DefaultText text={messageDetailsError.toString()} />
        </div>
      ) : null}
      {/* if data is loading show spinner */}
      {isMessageDetailsLoading ? (
        <div>
          <CircularProgress color="primary" />
        </div>
      ) : null}
      {/* show data if not error and not loading */}
      {!isMessageDetailsLoading && !messageDetailsError ? (
        <div className="detailsPanelDataTabContainer">
          {inExpanded !== true && (
            <>
              <div>
                <StaticTextBox
                  editable={specialistEditMode}
                  maxRows={7}
                  fieldLabel="Recommended Action"
                  fieldValue={currentRecommendedAction}
                  setValue={setCurrentRecommendedAction}
                />
              </div>
              <div>
                <StaticTextBox
                  editable={specialistEditMode}
                  maxRows={7}
                  fieldLabel="Specialist Input"
                  fieldValue={currentInputs}
                  setValue={setCurrentInputs}
                />
              </div>
              <div>
                <StaticTextBox
                  editable={specialistEditMode}
                  maxRows={7}
                  fieldLabel="Additional Comments"
                  fieldValue={currentAdditionalComments}
                  setValue={setCurrentAdditionalComments}
                />
              </div>
              <div>
                <StaticTextBox
                  editable={specialistEditMode}
                  maxRows={7}
                  fieldLabel="MMEL"
                  fieldValue={currentMMEL}
                  setValue={setCurrentMMEL}
                />
              </div>
              {/* <div>
                <StaticTextBox
                  editable={specialistEditMode}
                  maxRows={7}
                  fieldLabel="EICAS"
                  fieldValue={currentEicas}
                  setValue={setCurrentEicas}
                />
              </div> */}
            </>
          )}

          {inExpanded === true && (
            <Grid container spacing={3}>
              <Grid item xs>
                <StaticTextBox
                  editable={specialistEditMode}
                  maxRows={7}
                  fieldLabel="Recommended Action"
                  fieldValue={currentRecommendedAction}
                  setValue={setCurrentRecommendedAction}
                />
              </Grid>
              <Grid item xs>
                <StaticTextBox
                  editable={specialistEditMode}
                  maxRows={7}
                  fieldLabel="Specialist Input"
                  fieldValue={currentInputs}
                  setValue={setCurrentInputs}
                />
              </Grid>
              <Grid item xs>
                <StaticTextBox
                  editable={specialistEditMode}
                  maxRows={7}
                  fieldLabel="Additional Comments"
                  fieldValue={currentAdditionalComments}
                  setValue={setCurrentAdditionalComments}
                />
              </Grid>
              <Grid item xs>
                <StaticTextBox
                  editable={specialistEditMode}
                  maxRows={7}
                  fieldLabel="MMEL"
                  fieldValue={currentMMEL}
                  setValue={setCurrentMMEL}
                />
              </Grid>
              {/* <Grid item xs>
                <StaticTextBox
                  editable={specialistEditMode}
                  maxRows={7}
                  fieldLabel="EICAS"
                  fieldValue={currentEicas}
                  setValue={setCurrentEicas}
                />
              </Grid> */}
            </Grid>
          )}
          {panelState?.IS_SPECIALIST ? (
            <>
              {specialistEditMode ? (
                <Box className="buttonBox">
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => onSaveClick()}
                  >
                    Save
                  </Button>
                  <Button
                    style={{ marginLeft: "20px" }}
                    variant="outlined"
                    color="primary"
                    onClick={() => onCancelClick()}
                  >
                    Cancel
                  </Button>
                </Box>
              ) : (
                <Button
                  sx={{ px: 20 }}
                  variant="outlined"
                  color="primary"
                  onClick={() => onEditClick()}
                >
                  Edit
                </Button>
              )}
            </>
          ) : null}
        </div>
      ) : null}

      <Dialog maxWidth="xs" open={openConfirmation}>
        <DialogTitle>Discard Specialist Input Changes?</DialogTitle>
        <DialogActions>
          <Button
            color="error"
            sx={styles.buttonDiscard}
            onClick={handleDiscard}
          >
            Discard Changes
          </Button>
          <Button
            color="primary"
            sx={styles.buttonDiscard}
            onClick={handleCancelDiscard}
          >
            Continue Editing
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};
