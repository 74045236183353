export const getTileClickDate = (ac) => {
  /*
    When a user clicks the fleet tile we show messages for past 24hours.
    This function computes the start datetime and end datetime in UTC
    timezone
    */
  if (ac.LAST_DOWNLOAD_DATE && ac.LAST_DOWNLOAD_DATE.length > 0) {
    const dateParser = ac.LAST_DOWNLOAD_DATE.split(' ');
    const startDate = getDateStringWithOffset(ac.LAST_DOWNLOAD_DATE, -10);
    const endDate = getDateStringWithOffset(ac.LAST_DOWNLOAD_DATE, 0);
    const time = dateParser[1].split(':');
    const endTime = `${time[0]}:${time[1]}`;
    const startTime = `${time[0]}:${time[1]}`;
    return { startDate, startTime, endDate, endTime };
  } else {
    const today = new Date();
    const startDate = getDateStringWithOffset(today, -10);
    const endDate = getDateStringWithOffset(today, 0);
    // get date in YYYY-MM-DD format and time in HH:MM format

    const endTime = `${('0' + today.getUTCHours()).slice(-2)}:${(
      '0' + today.getUTCMinutes()
    ).slice(-2)}`;

    // get datetime 24 hours ago from current datetime
    today.setUTCHours(today.getUTCHours() - 24);
    const startTime = `${('0' + today.getUTCHours()).slice(-2)}:${(
      '0' + today.getUTCMinutes()
    ).slice(-2)}`;

    return { startDate, startTime, endDate, endTime };
  }
};

export const getDateStringWithOffset = (startDate, offset) => {
  const newDate = new Date(startDate);
  newDate.setDate(newDate.getDate() + offset);
  const tzOffset = newDate.getTimezoneOffset();
  const newDateWithOffset = new Date(newDate.getTime() - tzOffset * 60 * 1000);
  return newDateWithOffset.toISOString().split('T')[0];
};

export const getNumPadders = (maxColumns, maxRows, numRows, numTiles) => {
  /*
  A function for calculating the number of invisible padding tiles to
  add to acTilesLists to ensure the grid is always full so that tile
  spacing remains consistent

  @param <maxColumns:int>: max number of columns for current screen
  @param <maxRows:int>: max number of rows for current screen
  @param <numRows:int>: total number of rows in the grid
  @param <numTiles:int>: total number of tiles
  */
  const horizontalPadders = (maxColumns - (numTiles % maxColumns)) % maxColumns;
  const verticalPadders =
    maxRows > numRows ? (maxRows - numRows) * maxColumns : 0;

  return horizontalPadders + verticalPadders;
};

export const getMaxColumns = (windowWidth) => {
  let maxColumns = 2;
  if (windowWidth > 1750) {
    maxColumns = 7;
  } else if (windowWidth > 1550) {
    maxColumns = 6;
  } else if (windowWidth > 1350) {
    maxColumns = 5;
  } else if (windowWidth > 1150) {
    maxColumns = 4;
  } else if (windowWidth > 950) {
    maxColumns = 3;
  }
  return maxColumns;
};
