/* eslint-disable max-len */
import React, { useState } from 'react';
import { FleetTab } from 'mdp/components/FleetTab/FleetTab';
import { MessagesTab } from 'mdp/components/MessagesTab/MessagesTab';
import { RawDataTab } from 'mdp/components/RawDataTab/RawDataTab';
import { EqIDLookupTab } from 'mdp/components/EqIDLookupTab/EqIDLookupTab';
import { TabNav } from 'mdp/components/TabNav';
import { FlightDeckEffectsTab } from 'mdp/components/FlightDeckEffectsTab/FlightDeckEffectsTab';
import { ErrorBoundary } from 'dna/dnaErrorCaughtPage';
import { ContextConsumer } from 'dna/ContextConsumer';
import 'mdp/TabNavigator.css';
import 'mdp/ThreePanelTab.css';
import {
  Redirect,
  Route,
  useRouteMatch,
  Switch,
} from 'react-router-dom/cjs/react-router-dom.min';
/* eslint-enable max-len */

export const TabNavigator = () => {
  const { path } = useRouteMatch();
  // const {path} = useRouteMatch();
  // stores the current tab the user is on
  const [curTab, setCurTab] = useState('Fleet');
  const [specialistEditMode, setSpecialistEditMode] = useState(false);

  return (
    <ContextConsumer>
      {componentStates => (
        <ErrorBoundary returnPath = {"/mdp/Fleet"} returnName = {"MDP"} componentStates={componentStates}>
          <div className="main">
            <div className="tabNav">
              <TabNav
                curTab={curTab}
                setCurTab={setCurTab}
                specialistEditMode={specialistEditMode}
              />
            </div>
            <div className="tabContent">
              {/* Routes */}
              <Switch>
                <Route path={`${path}/Fleet`} component={FleetTab} />
                <Route path={`${path}/Messages`}>
                  <MessagesTab
                    specialistEditMode={specialistEditMode}
                    setSpecialistEditMode={setSpecialistEditMode}
                  />
                </Route>
                <Route path={`${path}/Rawdata`} component={RawDataTab} />
                <Route path={`${path}/EqIDLookup`}>
                  <EqIDLookupTab
                    specialistEditMode={specialistEditMode}
                    setSpecialistEditMode={setSpecialistEditMode}
                  />
                  ;
                </Route>
                <Route
                  path={`${path}/FlightDeckEffects`}
                  component={FlightDeckEffectsTab}
                />
                {/* Redirect to fleet for an unknown tab */}
                <Route path="*"><Redirect to={`${path}/Fleet`} /></Route>
              </Switch>
            </div>
          </div>
        </ErrorBoundary>
      )}
    </ContextConsumer>
  );
};
