import React from "react";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

const styles = ({
  otherFiltersToggle: {
    fontSize: 10,
    height: "12px",
    color: "black",
  },
});

export const ToggleGroup = (props) => {
  const {
    nonEmpty,
    setFilterState,
    filterName,
    filterState,
    exclusive,
    selectOptions,
  } = props;

  const handleButtonClick = (event, newState) => {
    if (nonEmpty) {
      // can the user select no options?
      newState
        ? setFilterState({
            ...filterState,
            [filterName]: newState,
          }) // dont change filterState if user tries to unselect all options
        : setFilterState(filterState);
    } else {
      setFilterState({
        ...filterState,
        [filterName]: newState,
      });
    }
  };

  return (
    <ToggleButtonGroup
      exclusive={exclusive}
      value={filterState[filterName]}
      onChange={handleButtonClick}
    >
      {selectOptions.map((item) => (
        <ToggleButton
          key={item}
          sx={styles.otherFiltersToggle}
          value={item}
          size="small"
        >
          {item}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
