import React, { useState, useEffect } from 'react';
import icon from 'assets/orca_logo.png';

export const LoadingIcon = (props) => {
  const [scale, setScale] = useState(1);

  const iconStyle = {
    transform: `scale(${scale})`,
    transition: `transform 1s ease-in-out`,
    maxWidth: props.maxWidth,
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setScale((prevScale) => prevScale === 1 ? 1.2 : 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <img src={icon} style={iconStyle} alt={"orca logo"} />
  );
};
