import React, { useState, createContext } from 'react';

export const EqIDFiltersContext = createContext();

export const EqIDFiltersContextProvider = (props) => {
  const defaultEqIDFilterState = {
    name: 'EqIdFiltersState',
    acModel: [],
    eqIDs: [],
    messages: [],
    lru: [],
    ata: [],
    mdtVersion: [],
    eicas: [],
    excludeEqIDs: false,
    excludeMessages: false,
    excludeLru: false,
    excludeAta: false,
    excludeMdtVersion: false,
    excludeEicas: false,
    orderbyCol: 'EQ_ID',
    orderbyDir: 'asc',
  };

  // state of the filters panel in the EqIDLookup tab
  const [eqIDFilterState, setEqIDFilterState] = useState(
      defaultEqIDFilterState,
  );
  // copy of the filters that were applied in the current search
  const [appliedEqIDFilters, setAppliedEqIDFilters] = useState(null);
  // number of rows to display per page in the Equations table
  const [rowsPerPage, setRowsPerPage] = useState(100);
  // page number the user is on
  const [curTablePage, setCurTablePage] = useState(0);
  // row that the user has selected in the Equations table
  const [selectedRow, setSelectedRow] = useState(null);
  // bool that dictates whether should simulate a user clickin on the
  // apply button to trigger a search
  const [mimicApplyClick, setMimicApplyClick] = useState(false);
  return (
    <EqIDFiltersContext.Provider
      value={{
        eqIDFilterState,
        setEqIDFilterState,
        appliedEqIDFilters,
        setAppliedEqIDFilters,
        defaultEqIDFilterState,
        rowsPerPage,
        setRowsPerPage,
        curTablePage,
        setCurTablePage,
        selectedRow,
        setSelectedRow,
        mimicApplyClick,
        setMimicApplyClick,
      }}
    >
      {props.children}
    </EqIDFiltersContext.Provider>
  );
};
export default EqIDFiltersContextProvider;
