import React, { useState, createContext } from 'react';

export const FlightDeckEffectsDataContext = createContext();

export const FlightDeckEffectsDataContextProvider = (props) => {
  // state used to store data from Equations Table
  const [flightDeckEffectsTableState, setFlightDeckEffectsTableState] =
  useState(null);
  // if the Equations table data is loading
  const [isLoading, setIsLoading] = useState(false);
  // if theres an error when querying Equations table data
  const [error, setError] = useState(null);

  return (
    <FlightDeckEffectsDataContext.Provider
      value={{
        flightDeckEffectsTableState,
        setFlightDeckEffectsTableState,
        isLoading,
        setIsLoading,
        error,
        setError,
      }}
    >
      {props.children}
    </FlightDeckEffectsDataContext.Provider>
  );
};

export default FlightDeckEffectsDataContextProvider;
