import React from 'react';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import { config } from 'config/config';
import { apiFetcher } from 'utils/apiUtils';

export const LinkTable = (props) => {
  const {
    fieldLabel,
    setSelectedRow,
    maxHeight,
    unselectable,
    selectedRow,
    rowKey,
    panelState,
    tableHeader,
    data,
    columns,
  } = props;
  const theme = useTheme();
  const styles = ({
    container: {
      maxHeight,
      width: '100%',
      scrollbarWidth: 'thin',
    },
    tableRow: {
      '&$selected, &$selected:hover': {
        backgroundColor: theme.palette.secondary.main,
      },
      cursor: 'pointer'
    },
    outlinedDiv: {
      width: '100%',
    },
    cell: {
      fontSize: 11,
      padding: '6px 6px 6px 6px',
    },
    selected: {},
  });
  const buildLink = (val) => {
    let foundFirstNum = false;
    let linkNum = '';

    for (let i = 0; i < val.length; i++) {
      const c = val[i];
      if (!foundFirstNum && c >= '0' && c <= '9') {
        foundFirstNum = true;
      }
      if (foundFirstNum) {
        linkNum = linkNum.concat(c);
      }
    }
    // return "FIM".concat((linkNum).concat(".pdf"))
    return linkNum;
  };

  const handleClick = async (linkNum, model) => {
    await apiFetcher(
        `${config.apiURL}/mdp/troubleshootingfimpdfs?`,
        'POST',
        { fileNum: linkNum, model },
        { 'Content-Type': 'application/json' },
        false,
    )
        .then((response) =>
          response.blob().then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'FIM' + linkNum + '.pdf';
            a.click();
          }),
        )
        .catch((e) => {
          console.error(e);
        });
  };
  // eslint-disable-next-line react/display-name
  const InputComponent = React.forwardRef((props, ref) =>
    <div ref={ref} {...props} />);

  const OutlinedDiv = ({ children, label }) => {
    return (
      <TextField
        sx={styles.outlinedDiv}
        variant="outlined"
        label={label}
        multiline
        InputLabelProps={{ shrink: true }}
        InputProps={{
          inputComponent: InputComponent,
        }}
        inputProps={{ children }}
      />
    );
  };

  return (
    <OutlinedDiv label={fieldLabel}>
      <TableContainer sx={styles.container}>
        <Table size="small">
          {tableHeader ? (
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    sx={styles.cell}
                    size="small"
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
          ) : null}
          <TableBody>
            {data.map((row, index) => {
              return (
                <TableRow
                  hover
                  tabIndex={-1}
                  key={row[rowKey]}
                  sx={styles.tableRow}
                  classes={{ selected: styles.selected }}
                  selected={
                    unselectable
                      ? false
                      : row[rowKey] === (selectedRow ? selectedRow[rowKey] : '')
                  }
                  onClick={unselectable ? null : () => setSelectedRow(row)}
                >
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell
                        sx={ styles.cell }
                        size="small"
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                        onClick={() =>
                          handleClick(buildLink(value), panelState?.AC_MODEL)
                        }
                      >
                        {value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </OutlinedDiv>
  );
};
