import React, { useState, createContext } from 'react';

export const SpltDataContext = createContext();

export const SpltDataContextProvider = (props) => {
  // if the Splt Data table data is loading
  const [isLoading, setIsLoading] = useState(false);
  // if theres an error when querying Splt Data table data
  const [error, setError] = useState(null);
  // state used to store data from Splt Data Table
  const [spltTableState, setSpltTableState] = useState(null);

  return (
    <SpltDataContext.Provider
      value={{
        spltTableState,
        setSpltTableState,
        isLoading,
        setIsLoading,
        error,
        setError,
      }}
    >
      {props.children}
    </SpltDataContext.Provider>
  );
};
export default SpltDataContextProvider;
