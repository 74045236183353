import React, { useState, useEffect } from "react";
import CardContent from "@mui/material/CardContent";
import { useTheme } from "@mui/material/styles";
import Modal from "@mui/material/Modal";
import Slide from "@mui/material/Slide";
import Card from "@mui/material/Card";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { TextField, Button, Box } from "@mui/material";
import { apiFetcher } from "utils/apiUtils";
import { config } from "config/config";
import FormControlLabel from "@mui/material/FormControlLabel";
import CircularProgress from "@mui/material/CircularProgress";

export const EmailPopup = (props) => {
  const {
    selectedEQID,
    isDetailsPanelExpand,
    setIsDetailsPanelExpand,
    panelState,
    userEmail,
  } = props;

  const [height, setHeight] = useState(window.innerHeight);
  const [isLoading, setIsLoading] = useState(false);
  const [subjectText, setSubjectText] = useState("");
  const [ccText, setCcText] = useState("");
  const [briefDescText, setBriefDescText] = useState("");
  const [infoText, setInfoText] = useState("");
  const [alertLevel, setAlertLevel] = useState("Routine");

  useEffect(() => {
    const handleWindowResize = () => setHeight(window.innerHeight);
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  useEffect(() => {
    setCcText(userEmail);
    setInfoText(
      "Alert Level: " +
        alertLevel +
        "\n-----------------------------------------\nOperator: " +
        panelState.OPERATOR +
        "\nEquation ID: " +
        panelState.EQ_ID +
        "\nMessage Text: " +
        panelState.MSG_TXT +
        "\nMessage Date: " +
        panelState.MSG_Date +
        "\nAircraft Serial Number: " +
        panelState.AC_SN
    );
    setSubjectText(
      "MDP Generated Alert: " + panelState.OPERATOR + " Requesting Assistance"
    );
  }, [selectedEQID, alertLevel]);

  const sendEmail = () => {
    setIsLoading(true);
    apiFetcher(
      `${config.apiURL}/mdp/sendemail`,
      "POST",
      {
        subject: subjectText,
        cc: ccText,
        info: infoText,
        briefDesc: briefDescText,
      },
      {
        "Content-Type": "application/json",
      }
    )
      .then((response) => {
        setIsLoading(false);
        setIsDetailsPanelExpand(false);
      })
      .catch((e) => {
        setIsLoading(false);
        console.log(e);
        setIsDetailsPanelExpand(false);
      });
  };

  const theme = useTheme();
  const styles = ({
    title: {
      color: theme.palette.text.title2,
      fontSize: 18,
      borderBottom: "1px solid " + theme.palette.default.main,
      backgroundColor: theme.palette.secondary.main,
      height: "5%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "0 0 0 0",
    },
    detailsPanelTitle: {
      position: "absolute",
    },
    detailsPanelButtons: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      position: "relative",
      marginLeft: "auto",
      marginRight: "5px",
    },
    binhexSelectExpanded: {
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    iconButtons: {
      padding: "0 0 0 0",
      margin: "0 10px 0 10px",
    },
    buttonIcons: {
      width: "25px",
      height: "25px",
      fill: theme.palette.primary.dark,
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    card: {
      display: "flex",
      flexDirection: "column",
      width: "75%",
      height: height > 905 ? "78%" : "95%",
    },
    subject: {
      gridArea: "subject",
    },
    cc: {
      gridArea: "cc",
    },
    info: {
      gridArea: "info",
    },
    briefDesc: {
      gridArea: "briefDesc",
      paddingBottom: "15px",
    },
    checkBox: {
      gridArea: "checkBox",
    },
    specialistInputSection: {
      gridArea: "specialistInput",
    },
    logicSection: {
      gridArea: "logic",
    },
    detailsArea: {
      display: "grid",
      gridTemplateAreas:
        // eslint-disable-next-line max-len
        " 'subject subject subject' 'cc cc cc' 'checkBox checkBox checkBox' 'info info info' 'briefDesc briefDesc briefDesc' ",
      gridGap: "20px",
      padding: "20px",
      height: "95%",
      overflow: "scroll",
    },
  });

  return (
    <Modal
      sx={styles.modal}
      closeAfterTransition
      open={isDetailsPanelExpand}
      onClose={() => setIsDetailsPanelExpand(false)}
      BackdropProps={{
        timeout: 600,
      }}
    >
      <Slide
        in={isDetailsPanelExpand}
        direction="down"
        mountOnEnter
        unmountOnExit
      >
        <Card elevation={3} sx={styles.card}>
          {/* Title bar with close button */}

          <CardContent sx={styles.title}>
            <div style={styles.detailsPanelTitle}>Send Email to CRC</div>
            <div style={styles.detailsPanelButtons}>
              <IconButton
                sx={styles.iconButtons}
                onClick={() => setIsDetailsPanelExpand(false)}
                size="large">
                <CloseIcon sx={styles.buttonIcons} />
              </IconButton>
            </div>
          </CardContent>

          {/* Message Details Area */}
          <div style={styles.detailsArea}>
            <div style={styles.subject}>
              <TextField
                minRows={2}
                multiline
                variant="outlined"
                label="Subject"
                fullWidth
                inputProps={{ style: { fontSize: 11 } }}
                value={subjectText}
                onChange={(event) => {
                  setSubjectText(event.target.value);
                }}
              />
            </div>
            <div style={styles.cc}>
              <TextField
                minRows={1}
                multiline
                variant="outlined"
                label="CC"
                fullWidth
                inputProps={{ style: { fontSize: 11 } }}
                value={ccText}
                onChange={() => {}}
              />
            </div>
            <div style={styles.checkBox}>
              {/* <FormGroup style={{paddingLeft: "20px"}}>
              <FormControlLabel control={<Checkbox color="primary" onChange={
                  () => {setIsAOG(!isAOG)}
                } />} label="Is Aircraft AOG?" />
              </FormGroup> */}
              <FormControl variant="standard" style={{ paddingLeft: "15px" }}>
                <FormLabel id="alert-level">Alert Level</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="alert-level"
                  name="row-radio-buttons-group"
                  value={alertLevel}
                  onChange={(event) => {
                    setAlertLevel(event.target.value);
                  }}
                >
                  <FormControlLabel
                    value="Routine"
                    control={<Radio color="primary" />}
                    label="Routine"
                  />
                  <FormControlLabel
                    value="Urgent"
                    control={<Radio color="primary" />}
                    label="Urgent"
                  />
                  <FormControlLabel
                    value="AOG"
                    control={<Radio color="primary" />}
                    label="AOG"
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <div style={styles.info}>
              <TextField
                minRows={5}
                multiline
                variant="outlined"
                label="Information"
                fullWidth
                inputProps={{ style: { fontSize: 11 } }}
                value={infoText}
                onChange={() => {}}
              />
            </div>
            <div style={styles.briefDesc}>
              <TextField
                minRows={5}
                multiline
                variant="outlined"
                label="Brief Description"
                fullWidth
                inputProps={{ style: { fontSize: 11 } }}
                value={briefDescText}
                onChange={(event) => {
                  setBriefDescText(event.target.value);
                }}
              />
            </div>
            <Box m={1} pt={1}>
              {isLoading ? (
                <div>
                  <CircularProgress color="primary" />
                </div>
              ) : (
                <Button
                  sx={{ px: 5 }}
                  variant="outlined"
                  color="primary"
                  onClick={sendEmail}
                >
                  Send Email
                </Button>
              )}
            </Box>
          </div>
        </Card>
      </Slide>
    </Modal>
  );
};
