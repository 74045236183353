/* Component representing the "Data" dropdown tab in the message details panel */
import React, { useState, Fragment, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import { ToggleGroup } from "mdp/components/Miscellaneous/ToggleGroup";
import { StaticTextField } from "mdp/components/Miscellaneous/StaticTextField";
import { StaticTextBox } from "mdp/components/Miscellaneous/StaticTextBox";
import { DataTable } from "mdp/components/Miscellaneous/DataTable";
import { DefaultText } from "mdp/components/Miscellaneous/DefaultText";
import { hex2Bin } from "utils/utilFunctions";
import "mdp/components/MessagesTab/MessageDetails/MessageDetailsPanel.css";

// column definitions of the "data used to determine message" table
const dataUsedColumns = [
  { id: "LRU_NAME", minWidth: "30%", align: "left" },
  { id: "LRU_DATA", minWidth: "70%", align: "right" },
];
const lruDiagColumns = [
  { id: "LRU_NAME", minWidth: "30%", align: "left" },
  { id: "LRU_DATA", minWidth: "70%", align: "right" },
];

export const DetailsPanelDataTab = (props) => {
  const {
    panelState,
    binHex,
    setBinHex,
    messageDetailsError,
    isMessageDetailsLoading,
    inExpanded,
  } = props;
  // const theme = useTheme();
  const styles = ({
    binhexSelect: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      marginBottom: "10px",
    },
  });

  const [selectedRow, setSelectedRow] = useState({});

  const getDataUsed = () => {
    // converts DATA_USED data from hex to binary if user selects "BIN"
    // returns empty list if DATA_USED is empty
    return panelState.DATA_USED
      ? binHex.binHex === "Bin"
        ? panelState.DATA_USED.map((i) => {
            return { ...i, LRU_DATA: hex2Bin(i.LRU_DATA) };
          })
        : panelState.DATA_USED
      : [];
  };
  const getLRUDiagData = () => {
    // converts LRU_DIAG data from hex to binary if user selects "BIN"
    // returns empty list if LRU_DIAG is empty
    return panelState.LRU_DIAG
      ? binHex.binHex === "Bin"
        ? panelState.LRU_DIAG.map((i) => {
            return { ...i, LRU_DATA: hex2Bin(i.LRU_DATA) };
          })
        : panelState.LRU_DIAG
      : [];
  };

  // reset selected lru diag data and data used row when new message is selected
  // in the mdc messages table
  useEffect(() => {
    setSelectedRow({});
  }, [panelState.MSG_ID]);

  return (
    <Fragment>
      {/* If there's an error fetching the data */}
      {messageDetailsError ? (
        <div>
          <DefaultText text={messageDetailsError.toString()} />
        </div>
      ) : null}
      {/* if data is loading show spinner */}
      {isMessageDetailsLoading ? (
        <div>
          <CircularProgress color="primary" />
        </div>
      ) : null}
      {/* show data if not error and not loading */}
      {!isMessageDetailsLoading && !messageDetailsError ? (
        <div className="detailsPanelDataTabContainer">
          {inExpanded !== true && (
            <div style={styles.binhexSelect}>
              <ToggleGroup
                nonEmpty
                exclusive
                selectOptions={["Bin", "Hex"]}
                filterState={binHex}
                setFilterState={setBinHex}
                filterName="binHex"
              />
            </div>
          )}
          <Grid container spacing={3}>
            <Grid item xs>
              <StaticTextField
                fieldLabel="MDC Software"
                fieldValue={panelState.MDC_SOFTWARE || ""}
              />
            </Grid>
            <Grid item xs>
              <StaticTextField
                fieldLabel="MDT Version"
                fieldValue={panelState.MDT_VERSION || ""}
              />
            </Grid>
          </Grid>
          <div className="detailsPanelDataTable">
            <div>
              <DataTable
                maxHeight="102px"
                fieldLabel="Data Used to Determine Message"
                data={getDataUsed()}
                columns={dataUsedColumns}
                selectedRow={selectedRow}
                setSelectedRow={setSelectedRow}
                rowKey="RECORD_ID"
              />
            </div>
            <div>
              <DataTable
                maxHeight="102px"
                fieldLabel="LRU Diagnostic Data"
                data={getLRUDiagData()}
                columns={lruDiagColumns}
                selectedRow={selectedRow}
                setSelectedRow={setSelectedRow}
                rowKey="RECORD_ID"
              />
            </div>
            <div>
              <StaticTextBox
                maxRows={11}
                fieldLabel="Label Definition"
                fieldValue={
                  selectedRow?.DEFINITION
                    ? selectedRow.DEFINITION.replace(/;/g, "\n")
                    : ""
                }
              />
            </div>
          </div>
        </div>
      ) : null}
    </Fragment>
  );
};
