// Import context as required
import { useContext } from 'react';
import { TimelineFiltersContext } from "orca/contexts/TimelineFiltersContext";
// import { TimelineDataContext } from "orca/contexts/TimelineDataContext";
import { AlertsFiltersContext } from 'orca/contexts/AlertsFiltersContext';
// import { AlertsDataContext } from 'orca/contexts/AlertsDataContext';
// import { ReviewDataContext } from "orca/contexts/ReviewDataContext";
// import { ReviewFiltersContext } from "orca/contexts/ReviewFiltersContext";
import { SpltFiltersContext } from 'orca/contexts/SpltFiltersContext';
// import { SpltDataContext } from 'orca/contexts/SpltDataContext';
import { MessageFiltersContext } from 'mdp/contexts/MessageFiltersContext';
// import { MessageDataContext } from "mdp/contexts/MessageDataContext";
// import { FleetTabFiltersContext } from 'mdp/contexts/FleetTabFiltersContext';
// import { EqIDDataContext } from 'mdp/contexts/EqIDDataContext';
import { EqIDFiltersContext } from 'mdp/contexts/EqIDFiltersContext';
// import { FlightDeckEffectsDataContext } from 'mdp/contexts/FlightDeckEffectsDataContext';
import { FlightDeckEffectsFiltersContext } from 'mdp/contexts/FlightDeckEffectsFiltersContext';
// import { RawDataDataContext } from 'mdp/contexts/RawDataDataContext';
import { RawDataFiltersContext } from 'mdp/contexts/RawDataFiltersContext';



export const ContextConsumer = ({ children }) => {
    // const { timelineTableState } = useContext(TimelineDataContext);
    const { timelineFilterState } = useContext(TimelineFiltersContext);
    const { alertsFilterState } = useContext(AlertsFiltersContext);
    // const { alertsTableState } = useContext(AlertsDataContext);
    // const { reviewTableState } = useContext(ReviewDataContext);
    // const { spltTableState } = useContext(SpltDataContext);
    const { spltFilterState } = useContext(SpltFiltersContext);
    const { messageFilterState /* , appliedMessageFilters */ } = useContext(MessageFiltersContext);
    // const { messageTableState } = useContext(MessageDataContext);
    // const { eqIDTableState } = useContext(EqIDDataContext);
    const { eqIDFilterState } = useContext(EqIDFiltersContext);
    // const { flightDeckEffectsTableState } = useContext(FlightDeckEffectsDataContext);
    const { flightDeckEffectsFilterState /* , appliedFlightDeckEffectsFilters */ } = useContext(FlightDeckEffectsFiltersContext);
    // const { rawDataTableState } = useContext(RawDataDataContext);
    const { rawDataFilterState /*, appliedRawDataFilters, */ } = useContext(RawDataFiltersContext);


    const componentStates = {
        // timelineTableState,
        timelineFilterState,
        alertsFilterState,
        // alertsTableState,
        // reviewTableState,
        // spltTableState,
        spltFilterState,
        messageFilterState,
        // appliedMessageFilters,
        // messageTableState,
        // eqIDTableState,
        eqIDFilterState,
        // flightDeckEffectsTableState,
        flightDeckEffectsFilterState,
        // appliedFlightDeckEffectsFilters,
        // rawDataTableState,
        rawDataFilterState,
        // appliedRawDataFilters
    }

    return children(componentStates);
  };
