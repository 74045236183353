/* Root component */
import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { TimelineTab } from 'orca/components/TimelineTab/TimelineTab';
import { SpltTab } from 'orca/components/SpltTab/SpltTab';
import { AlertsTab } from 'orca/components/AlertsTab/AlertsTab';
import { ReviewTab } from 'orca/components/ReviewTab/ReviewTab';
import { TabNav } from 'orca/components/TabNav';
import { ErrorBoundary } from 'dna/dnaErrorCaughtPage';
import { ContextConsumer } from 'dna/ContextConsumer';
import 'orca/OrcaTabNavigator.css';
import {
  Redirect,
  Route,
  useRouteMatch,
  Switch,
} from 'react-router-dom/cjs/react-router-dom.min';

export const OrcaTabNavigator = () => {
  const theme = useTheme();
  const styles = ({
    tabContent: {
      width: '100%',
      padding: '15px 5px 15px 20px',
      backgroundColor: theme.palette.background.tabContent,
    },
  });

  const { path } = useRouteMatch();
  // stores the current tab the user is on
  const [curTab, setCurTab] = useState('Timeline');
  // checks if navigating to Orca is the first time
  const [isFirstTime, setIsFirstTime] = useState(true);

  return (
    <>
      <ContextConsumer>
        {componentStates => (
          <ErrorBoundary returnPath={"/orca/Timeline"} returnName={"ORCA"} componentStates={componentStates}>
            <div className="main">
              <div className="tabNav">
                <TabNav curTab={curTab} setCurTab={setCurTab} />
              </div>
              <div style={styles.tabContent}>
                <Switch>
                  <Route path={`${path}/Timeline`}>
                    <TimelineTab
                      setIsFirstTime={setIsFirstTime}
                      isFirstTime={isFirstTime}
                    />
                  </Route>
                  <Route path={`${path}/Specialist`} component={SpltTab} />
                  <Route path={`${path}/Alerts`} component={AlertsTab} />
                  <Route path={`${path}/Review`} component={ReviewTab} />
                  <Route path="*">
                    <Redirect to={`${path}/Timeline`} />
                  </Route>
                </Switch>
              </div>
            </div>
          </ErrorBoundary>
        )}
      </ContextConsumer>
    </>
  );
};
