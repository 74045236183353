import React from "react";
import IconButton from "@mui/material/IconButton";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Tooltip from "@mui/material/Tooltip";
import CardContent from "@mui/material/CardContent";
import { useTheme } from "@mui/material/styles";
import "mdp/components/RawDataTab/RawDataTablePanel.css";

export const FilesTablePanelButtonBar = (props) => {
  const { numSelectdRows, setIsUploadPanelOpen, downloadRawFiles } = props;
  const theme = useTheme();
  const styles = ({
    title: {
      color: theme.palette.text.title2,
      fontSize: 18,
      borderBottom: "1px solid " + theme.palette.default.main,
      backgroundColor: theme.palette.secondary.main,
      height: "5%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "0 0 0 0",
    },
    iconButtons: {
      padding: "0 0 0 0",
      margin: "0 10px 0 0",
    },
    buttonIcons: {
      width: "25px",
      height: "25px",
      fill: theme.palette.primary.dark,
    },
  });

  return (
    <CardContent sx={styles.title}>
      {numSelectdRows > 0 ? (
        <div className="buttonBarNumSelected">{numSelectdRows} selected</div>
      ) : (
        <div style={{ width: "95px" }}></div>
      )}
      <div style={{ justifySelf: "center" }}>Raw Data Files</div>
      <div className="buttonBarButtonsGroup">
        {numSelectdRows > 0 ? (
          <Tooltip title="Download selected files">
            <IconButton sx={styles.iconButtons} onClick={downloadRawFiles} size="large">
              <SaveAltIcon sx={styles.buttonIcons} />
            </IconButton>
          </Tooltip>
        ) : null}
        <Tooltip title="Upload raw data">
          <IconButton
            sx={styles.iconButtons}
            onClick={() => setIsUploadPanelOpen(true)}
            size="large">
            <CloudUploadIcon sx={styles.buttonIcons} />
          </IconButton>
        </Tooltip>
      </div>
    </CardContent>
  );
};
