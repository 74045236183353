import React, { Fragment, useContext, useState, useEffect } from 'react';
import { CircularProgress, TextField, IconButton, Grid } from '@mui/material';
import { StaticTextField } from 'mdp/components/Miscellaneous/StaticTextField';
import { StaticTextBox } from 'mdp/components/Miscellaneous/StaticTextBox';
import { DefaultText } from 'mdp/components/Miscellaneous/DefaultText';
import { EqIDFiltersContext } from 'mdp/contexts/EqIDFiltersContext';
import { config } from 'config/config';
import { apiFetcher } from "utils/apiUtils";
import SaveIcon from '@mui/icons-material/Save';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

export const DetailsPanelDetailsTab = (props) => {
  const {
    eqDetailsError,
    isEqDetailsLoading,
    inExpanded,
    panelState,
  } = props;
  const { appliedEqIDFilters } = useContext(EqIDFiltersContext);

  const styles = {
    iconButtons: {
      padding: '0 0 0 0',
      margin: '0 10px 0 10px',
      height: '100%',
    },
  }
  const [eicas, setEicas] = useState();
  const [originalEicas, setOriginalEicas] = useState();
  const [isEicasUpdating, setIsEicasUpdating] = useState(false);

  const handleEdit = (event) => {
    setEicas(event.target.value);
  }

  const onResetClick = () => {
    setEicas(originalEicas);
  };

  const onSaveClick = () => {
    setIsEicasUpdating(true)
    apiFetcher(
      `${config.apiURL}/mdp/specialistInput`,
      "POST",
      {
        eicas,
        selectedEQID: panelState.EQ_ID,
      },
      { "Content-Type": "application/json" }
    ).then(() => {
      setIsEicasUpdating(false)
    });
    setOriginalEicas(eicas);
  };

  useEffect(() => {
    setEicas(panelState?.EICAS_MESSAGE?.filter(() => true)[0]?.MESSAGE);
    setOriginalEicas(panelState?.EICAS_MESSAGE?.filter(() => true)[0]?.MESSAGE);
  }, [panelState.EICAS_MESSAGE]);



  return (
    <Fragment>
      {/* If there's an error fetching the data */}
      {eqDetailsError ? (
        <div>
          <DefaultText text={eqDetailsError.toString()} />
        </div>
      ) : null}
      {/* if data is loading show spinner */}
      {isEqDetailsLoading ? (
        <div style={{ width: '100%', marginLeft: inExpanded !== undefined ? '45%' : '0%' }}>
          <CircularProgress color="primary" />
        </div>
      ) : null}
      {/* show data if not error and not loading */}
      {!isEqDetailsLoading && !eqDetailsError ? (
        <div>
          <Grid container spacing={3}>
            <Grid item container spacing={inExpanded !== undefined ? 0 : 3} xs={inExpanded !== undefined ? 6 : 12}>
              <Grid item xs={8}>
                <StaticTextField
                  fieldLabel="Equation ID"
                  fieldValue={panelState.EQ_ID || ''}
                />
              </Grid>
              <Grid item xs={4}>
                <StaticTextField
                  fieldLabel="ATA"
                  fieldValue={panelState.ATA || ''}
                />
              </Grid>

              <Grid item xs={8}>
                <StaticTextField
                  fieldLabel="Message"
                  fieldValue={panelState.MSG_TXT || ''}
                />
              </Grid>
              <Grid item xs={4}>
                <StaticTextField
                  fieldLabel="Message Type"
                  fieldValue={panelState.MSG_TYPE || ''}
                  labelSmall
                />
              </Grid>
              <Grid item xs={12} sx={{ textAlign: 'justify' }}>
                <Grid item xs alignItems={"center"}>
                  <TextField
                    variant="standard"
                    label="EICAS Message"
                    value={eicas}
                    onChange={panelState.IS_SPECIALIST ? handleEdit : null}
                  />
                {panelState.IS_SPECIALIST ? (
                  <>
                      { isEicasUpdating ? <CircularProgress sx={styles.iconButtons}/>
                        : <>
                          <IconButton sx={styles.iconButtons}
                          disabled={isEicasUpdating}
                          onClick={() => onResetClick()}
                          size="large">
                            <RestartAltIcon/>
                          </IconButton>
                          <IconButton sx={styles.iconButtons}
                          disabled={isEicasUpdating}
                          onClick={() => onSaveClick()}
                          size="large">
                            <SaveIcon/>
                          </IconButton>
                        </>
                      }
                    </>
                ) : null}
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <StaticTextField
                  fieldLabel="LRU"
                  fieldValue={panelState.LRU_NAME || ''}
                />
              </Grid>
              <Grid item xs={4}>
                <StaticTextField
                  fieldLabel="Status"
                  fieldValue={panelState.STATUS || ''}
                />
              </Grid>
              <Grid item xs={2}>
                <StaticTextField
                  fieldLabel="Timer"
                  fieldValue={panelState.TIMER || ''}
                />
              </Grid>

              <Grid item xs={6}>
                <StaticTextField
                  fieldLabel="MDT Version"
                  fieldValue={panelState.MDT_VERSION || ''}
                />
              </Grid>
              {appliedEqIDFilters?.acModel === config.CRJ700 && (
                <Grid item xs={6}>
                  <StaticTextField
                    fieldLabel="Component ID"
                    fieldValue={panelState.COMP_ID || ''}
                    labelSmall
                  />
                </Grid>
              )}
            </Grid>
            <Grid item container spacing={inExpanded !== undefined ? 0 : 3} xs={inExpanded !== undefined ? 6 : 12}>
              <Grid item xs={12}>
                <StaticTextBox
                  maxRows={7}
                  fieldLabel="Equation Description"
                  fieldValue={panelState.EQ_DESCRIPTION || ''}
                />
                {inExpanded && (
                  <Grid item xs={12}>
                    <StaticTextBox
                      maxRows={7}
                      fieldLabel="Logic Used to Trigger Message"
                      fieldValue={panelState.LOGIC || ''}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </div>
      ) : null}
    </Fragment>
  );
};
