import React, { Fragment, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { MessageFiltersContext } from "mdp/contexts/MessageFiltersContext";
import { config } from "config/config";


export const MdpNavButton = (props) => {
  /*
  Component representing the MDC button that navigates to
  mdc messages tab in MDP
  */
  const {
    hasMdpAccess,
    ataList,
    fleetList,
    route,
    data,
  } = props;
  const history = useHistory();

  const styles = ({
    snackBar: {
      vertical: "bottom",
      horizontal: "center",
    }
  });

  const {
    setMimicApplyClick,
    setMessageFilterState,
    defaultMessageFilterState,
  } = useContext(MessageFiltersContext);


  const [showValidationPopup, setShowValidationPopup] = useState(false);

  const handleValidationClosePopup = (event, reason) => {
    // function for closing "need to perform a search first" popup
    if (reason === "clickaway") {
      return;
    }
    setShowValidationPopup(false);
  };

  const disabledAtaList = ['25', '29', '35', '46', '52', '53',
    '55', '56', '57', '72', '74', '75', '76', '79', '80', '11'];

  // disable button if messageType is Marep or ata is in the disabledList
  const isButtonDisabled = (data) => {
    if (data.message_type === 'MAINT' || data.ac_model === "CRJ") {
      return true;
    }
    if (data.ata === "52-70") {
      return false;
    }
    if (disabledAtaList.includes(data.ata.slice(0, 2))) {
      return true;
    }
    return false;
  };


  const formatATA = (list) => {
    const comp = (a, b) => {
      if (a.Value < b.Value) {
        return -1;
      }
      if (a.Value > b.Value) {
        return 1;
      }
      return 0;
    };
    const compChapter = (a, b) => {
      if (a.chapter < b.chapter) {
        return -1;
      }
      if (a.chapter > b.chapter) {
        return 1;
      }
      return 0;
    };
    const ATAParentList = [];
    const newList = list.sort(comp).map((item) => {
      const chapter = item.Value.split('-')[0];
      if (!ATAParentList.includes(chapter)) {
        ATAParentList.push(chapter);
      }
      const newItem = item;
      if (item.DESCRIPTION && item.DESCRIPTION !== 'UNKNOWN') {
        newItem.label = `${item.Value}: ${item.DESCRIPTION || ''}`;
      } else {
        newItem.label = item.Value;
      }
      return { ...item, chapter };
    });

    const newATAParentList = ATAParentList.map((item) => {
      return {
        chapter: item,
        AC_MODEL: newList[0].AC_MODEL,
        wholeChapter: true,
        label: `Select all ${item}`,
      };
    });
    return [...newATAParentList, ...newList].sort(compChapter);
  };

  const filterOptionsByACModel = (options, acModel) => {
    if (options.data) {
      return options.data.reduce(
        (i, j) => {
          if (j.AC_MODEL === (acModel === config.CRJ ? "CRJ" : "CRJ700")) {
              i.push({ ...j, AC_MODEL: acModel })
            }
            return i
          }, []);
    } else {
      return [];
    }
  };

  const getOprName = (fleetDict, oprCode) => {
    if (fleetDict) {
      const oprName = Object.keys(fleetDict).find(key => fleetDict[key] === oprCode)
      return [oprName]
    } else {
      return [];
    }
  }

  const getTwoDigitATA = (newValue) => {
    let valueToSet = [...newValue];

    console.log(valueToSet);

    // Check if we added a "Select All" option
    const containsSelectAll = newValue.find((v) => v.wholeChapter);
    if (containsSelectAll) {
      const allChapters = formatATA(
        filterOptionsByACModel(ataList, newValue.AC_MODEL)
      ).filter((v) => {
        return v.chapter === containsSelectAll.chapter && !v.wholeChapter;
      });

      // Append new chapters selected to already set chapters
      // and remove the "Select All" option
      const removeSelectAllFilters = newValue.filter((v) => !v.wholeChapter);
      valueToSet = [...removeSelectAllFilters, ...allChapters];
    }

    return valueToSet;
  };

  const getFourDigitATA = (newValue) => {
    return formatATA(
        filterOptionsByACModel(ataList, newValue.AC_MODEL)
      ).filter((v) => {
        return v.Value === newValue.Value;
      });
  };

  // set up filter and switch to MDP Messages tab
  const handleClick = (event) => {
    if (!hasMdpAccess) {
        setShowValidationPopup(true);
    } else {
        // Format date and calculate day before and after
        const oneDayInMs = 1 * 24 * 60 * 60 * 1000; // 2 days in milliseconds
        const startDate = new Date(data.reported_date).setHours(0, 0, 0, 0);

        // Format ata in filtered data to all 2 digit ATA
        // get the chapter from 4 digit ata
        // link 73 ata to 71 ata
        const chapter = data.ata.split("-")[0] === "73" ? "71" : data.ata.split("-")[0];
        const acModel = data.ac_model === "CRJ" ? config.CRJ : config.CRJ700;

        setMessageFilterState({
        ...defaultMessageFilterState,
        acModel,
        startDate: new Date(startDate - oneDayInMs)
            .toISOString()
            .substring(0, 10),
        endDate: new Date(startDate + oneDayInMs).toISOString().substring(0, 10),
        endDateOffset: 2,
        fleets: getOprName(fleetList.oprData, data.operator_code),
        acsn: [
          { Value: data.ac_sn, AC_MODEL: acModel, OPERATOR_CODE: data.operator_code },
        ],
        // link ata 52-70 (ORCA) to 32-61 (MDP)
        // for the others, link to 2-digits ata
        ata: data.ata === "52-70" ? getFourDigitATA({ Value: "32-61", AC_MODEL: data.AC_MODEL })
          : getTwoDigitATA([
            {
            Value: `Select all ${chapter}`,
            AC_MODEL: data.AC_MODEL,
            chapter,
            wholeChapter: true,
            },
        ]),
        });

        setMimicApplyClick(true);
        history.push("/mdp/Messages", { from: route });
    }
  };

  return (
    <Fragment>
      <Tooltip title="Navigate to MDC Messages">
        <span>
        <Button
            onClick={(event) => { handleClick(event) }}
            disabled={isButtonDisabled(data)}
            variant="contained"
            size="small"
            color="primary"
            >
            MDC
          </Button>
        </span>
      </Tooltip>

      <Snackbar
        anchorOrigin={styles.snackBar}
        open={showValidationPopup}
        autoHideDuration={5000}
        onClose={(event, reason) => handleValidationClosePopup(event, reason)}
      >
        <Alert
          onClose={(event, reason) => handleValidationClosePopup(event, reason)}
          severity="error"
          elevation={6}
          variant="filled"
        >
          This features requires a subscription to the MDP, please contact MHIRJ eServices at eServices@mhirj.com to learn more.
        </Alert>
      </Snackbar>

    </Fragment>
  );
};
