/* eslint-disable max-len */
import React, { Fragment } from 'react';
import 'mdp/components/FlightDeckEffectsTab/FlightDeckEffectsDetails/FlightDeckEffectsDetails.css';
import { LinkTable } from 'mdp/components/FlightDeckEffectsTab/FlightDeckEffectsDetails/FIM/LinkTable';

export const DetailsPanelFimLinks = (props) => {
  const { selectedRow } = props;

  const FimLinksColumns = [{ id: 'LINK', minWidth: '100%', align: 'left' }];

  return (
    <Fragment>
      {selectedRow === [] && <div>Select a row</div>}

      {/* show data if not error and not loading */}
      {selectedRow?.FIT_NUM && (
        <div className="detailsPanelDataTable">
          <LinkTable
            maxHeight="102px"
            fieldLabel="FIM Task Link(s)"
            columns={FimLinksColumns}
            selectedRow={selectedRow}
            AC_MODEL={selectedRow?.AC_MODEL}
          />
        </div>
      )}
    </Fragment>
  );
};
