import React, { Fragment } from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

export const StaticTextField = (props) => {
  const { dropDown, onSelect, options, fieldValue, labelSmall, fieldLabel } =
    props;

  const styles = ({
    textField: {
      width: "100%",
    },
    textFieldText: {
      fontSize: 12,
    },
    textFieldLabelSmall: {
      fontSize: 14,
    },
  });

  return (
    <Fragment>
      {dropDown ? (
        <TextField
          variant="standard"
          sx={styles.textField}
          label={fieldLabel}
          value={fieldValue ?? ""}
          select
          onChange={onSelect}
          InputProps={{
            classes: {
              input: styles.textFieldText,
            },
          }}
          InputLabelProps={{
            shrink: true,
            classes: {
              root: labelSmall ? styles.textFieldLabelSmall : null,
            },
          }}>
          {options.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
      ) : (
        <TextField
          variant="standard"
          sx={styles.textField}
          label={fieldLabel}
          value={fieldValue ?? ""}
          InputProps={{
            readOnly: true,
            style: styles.textFieldText,
}}
          InputLabelProps={{
            shrink: true,
            classes: {
              root: labelSmall ? styles.textFieldLabelSmall : null,
            },
          }} />
      )}
    </Fragment>
  );
};
