/* Root component representing the Filter panel */
import React, { Fragment, useState, useContext, useEffect } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Tooltip from '@mui/material/Tooltip';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import PublishIcon from '@mui/icons-material/Publish';
import InputBase from '@mui/material/InputBase';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import { SelectBox } from 'mdp/components/Miscellaneous/SelectBox';
import { SelectBoxATA } from 'mdp/components/Miscellaneous/SelectBoxATA';
import { FiltersPanel } from 'mdp/components/Miscellaneous/FiltersPanel';
import { ToggleGroup } from 'mdp/components/Miscellaneous/ToggleGroup';
import { CheckBoxSelect } from 'mdp/components/Miscellaneous/CheckBoxSelect';
import { config } from 'config/config';
import { useFetch, apiFetcher } from 'utils/apiUtils';
import { MessageFiltersContext } from 'mdp/contexts/MessageFiltersContext';
import { MessageDataContext } from 'mdp/contexts/MessageDataContext';
import { getCurrentDate } from 'utils/utilFunctions';

import 'mdp/components/MessagesTab/Filters/MessageFiltersPanel.css';

export const formatFiltersState = (filterState) => {
  // apply these formatting before the search is sent to backend
  if (filterState.acModel.length) {
    return {
      ...filterState,
      acModel: filterState.acModel === config.CRJ ? 'CRJ' : 'CRJ700',
      acsn: filterState.acsn.map((i) => i.Value),
      actn: filterState.actn.map((i) => i.Value),
      eqIDs: filterState.eqIDs.map((i) => i.Value),
      ata: filterState.ata.map((i) => i.Value),
      lru: filterState.lru.map((i) => i.Value),
      messageText: filterState.messageText.map((i) => i.Value),
      messageType: filterState.messageType.map((i) => i.Value),
      flightPhase: filterState.flightPhase.map((i) => i.Value),
      eicas: filterState.eicas.map((i) => i.Value),
    };
  } else {
    return filterState;
  }
};

export const MessageFiltersPanel = (props) => {
  const { specialistEditMode } = props;
  const styles = ({
    accordianRoot: {
      boxShadow: 'none',
    },
    accordianLabel: {
      fontSize: 16,
    },
    searchBar: {
      marginTop: '30px',
      width: '100%',
    },
    searchBarText: {
      fontSize: 12,
    },
    loadingSpinner: {
      marginTop: '50%',
    },
    dateForm: {
      marginBottom: '15px',
    },
    dateFormInput: {
      fontSize: 12,
    },
    dateTimeContainer: {
      minHeight: '100%',
      width: '100%',
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',
    },
    dateTimeBox: {
      display: 'flex',
      flexBasis: '45%',
      justifyContent: 'center',
      flexDirection: 'column',
      margin: '5px',
      width: '100%'
    },
    timeBox: {
      display: 'flex',
      flexBasis: '45%',
      justifyContent: 'center',
      alignItems: 'space-between',
      flexDirection: 'row',
      margin: '5px',
      width: '100%'
    },
  });
  // whether filter components should check if their value is empty
  const [shouldValidateFilters, setShouldValidateFilters] = useState(false);
  const {
    setMessageTableState,
    isLoading: isMessagesLoading,
    setIsLoading: setIsMessagesLoading,
    setError,
  } = useContext(MessageDataContext);
  const {
    messageFilterState,
    setMessageFilterState,
    setAppliedMessageFilters,
    defaultMessageFilterState,
    setCurTablePage,
    // acsnFromFleetTab,
    mimicApplyClick,
    setMimicApplyClick,
  } = useContext(MessageFiltersContext);

  // fetch filter dropdown options
  const { data: acsnList, error: acsnListError } = useFetch(
    `${config.apiURL}/mdp/filteroptions/acsn`,
    'GET'
  );
  const { data: actnList, error: actnListError } = useFetch(
    `${config.apiURL}/mdp/filteroptions/actn`,
    'GET'
  );
  const { data: fleetList, error: fleetListError } = useFetch(
    `${config.apiURL}/mdp/unique/oprname`,
    'GET'
  );
  const { data: ataList, error: ataListError } = useFetch(
    `${config.apiURL}/mdp/filteroptions/ata`,
    'GET'
  );
  const { data: eqidList, error: eqidListError } = useFetch(
    `${config.apiURL}/mdp/filteroptions/eqid`,
    'GET'
  );
  const { data: lruList, error: lruListError } = useFetch(
    `${config.apiURL}/mdp/filteroptions/lru`,
    'GET'
  );
  const { data: fltNumList, error: fltNumListError } = useFetch(
    `${config.apiURL}/mdp/unique/flightnumber`,
    'GET'
  );
  const { data: fltLegList, error: fltLegListError } = useFetch(
    `${config.apiURL}/mdp/unique/flightleg`,
    'GET'
  );
  const { data: fltPhaseList, error: fltPhaseListError } = useFetch(
    `${config.apiURL}/mdp/filteroptions/flightphase`,
    'GET'
  );
  const { data: msgTypeList, error: msgTypeListError } = useFetch(
    `${config.apiURL}/mdp/filteroptions/messagetype`,
    'GET'
  );
  const { data: msgTxtList, error: msgTxtListError } = useFetch(
    `${config.apiURL}/mdp/filteroptions/messagetext`,
    'GET'
  );

  const { data: eicasMsgList, error: eicasMsgListError } = useFetch(
    `${config.apiURL}/mdp/filteroptions/eicas`,
    "GET"
  );

  const { data: savedFilters /* error: savedFiltersError */ } = useFetch(
    `${config.apiURL}/mdp/savedfilters`,
    'GET'
  );
  const { data: publicFilters /* error: publicFiltersError */ } = useFetch(
    `${config.apiURL}/mdp/publicfilters`,
    'GET'
  );

  const [userSideFilters, setUserSideFilters] = useState([]);
  const [userSidePublicFilters, setUserSidePublicFilters] = useState([]);
  const [showValidationPopup, setShowValidationPopup] = useState({
    show: false,
    type: 'error',
    message: '',
  });
  const [timer, setTimer] = useState(null);
  const [showFleetTab, setShowFleetTab] = useState(false);
  useEffect(() => {
    if (fleetList.oprData && Object.keys(fleetList.oprData).length === 1) {
      setShowFleetTab(false);
    } else {
      setShowFleetTab(true);
    }
  }, [fleetList.oprData]);

  const handleEndDateOffsetChange = (event) => {
    const newDate = new Date(messageFilterState.endDate);
    newDate.setDate(newDate.getDate() - event.target.value + 1);
    const offset = newDate.getTimezoneOffset();
    const newDateWithOffset = new Date(newDate.getTime() - offset * 60 * 1000);

    setMessageFilterState({
      ...messageFilterState,
      startDate: newDateWithOffset.toISOString().split('T')[0],
      endDateOffset: event.target.value,
    });
  };

  const handleChangeStartDate = (event) => {
    const date1 = new Date(messageFilterState.endDate);
    const date2 = new Date(event.target.value);
    const difference = date1.getTime() - date2.getTime();
    const TotalDays = Math.ceil(difference / (1000 * 3600 * 24));

    setMessageFilterState({
      ...messageFilterState,
      startDate: event.target.value,
      endDateOffset: TotalDays,
    });
  };

  const handleChangeEndDate = (event) => {
    const date1 = new Date(event.target.value);
    const date2 = new Date(messageFilterState.startDate);
    const difference = date1.getTime() - date2.getTime();
    const TotalDays = Math.ceil(difference / (1000 * 3600 * 24));

    setMessageFilterState({
      ...messageFilterState,
      endDate: event.target.value,
      endDateOffset: TotalDays,
    });
  };

  const handleClosePopup = (event, reason) => {
    // function for closing invalid filter popup
    if (reason === 'clickaway') {
      return;
    }
    setShowValidationPopup({ ...showValidationPopup, show: false });
  };

  const handleApplyFilter = (recordID) => {
    const filterToApply = userSideFilters.find((f) => f.record_id === recordID) || userSidePublicFilters.find((f) => f.record_id === recordID);
    if (
      filterToApply.JSON_VALUE.startDate &&
      filterToApply.JSON_VALUE.endDate &&
      filterToApply.JSON_VALUE.startTime &&
      filterToApply.JSON_VALUE.endTime &&
      filterToApply.JSON_VALUE.acModel.length
    ) {
      // making date unchanged by filter application:
      filterToApply.JSON_VALUE.startDate = messageFilterState.startDate;
      filterToApply.JSON_VALUE.endDate = messageFilterState.endDate;
      filterToApply.JSON_VALUE.endDateOffset = messageFilterState.endDateOffset;
      setMessageFilterState(filterToApply.JSON_VALUE);
      setShowValidationPopup({
        show: true,
        type: 'success',
        message: 'Filter settings applied successfully!',
      });
    } else {
      setShowValidationPopup({
        show: true,
        type: 'error',
        message: 'Filter settings are invalid!',
      });
    }
  }; // Applys filters params to current session

  const handleCreateFilter = async () => {
    try {
      const newFilter = {
        FILTER_NAME: `SavedFilters${getCurrentDate()}`,
        JSON_VALUE: messageFilterState,
        IS_PUBLIC: 'N',
      };
      const req = await apiFetcher(
        `${config.apiURL}/mdp/savedfilters?`,
        'POST',
        newFilter,
        { 'Content-Type': 'application/json' },
        true
      );
      if (req.success) {
        newFilter.OKTA_USERNAME = req.username;
        newFilter.record_id = req.id;
        setUserSideFilters([...userSideFilters, newFilter]);
      }
    } catch (e) {
      setShowValidationPopup({
        show: true,
        type: 'error',
        message: 'Error creating new filter!',
      });
    }
  };
  const handleSaveFilter = async (recordID) => {
    setShouldValidateFilters(true);
    if (!isFiltersValid()) {
      setShowValidationPopup({
        show: true,
        type: 'error',
        message: 'Date/Time and Aircraft Model filters cannot be empty!',
      });
      return;
    }
    try {
      setShouldValidateFilters(false);
      const filterToUpdate = userSideFilters.find(
        (f) => f.record_id === recordID
      );
      const req = await apiFetcher(
        `${config.apiURL}/mdp/savedfilters?recordid=${recordID}`,
        'PATCH',
        {
          FILTER_NAME: filterToUpdate.FILTER_NAME,
          JSON_VALUE: messageFilterState,
          IS_PUBLIC: filterToUpdate.IS_PUBLIC,
        },
        { 'Content-Type': 'application/json' },
        false
      );
      if (req.status === 200) {
        setShowValidationPopup({
          show: true,
          type: 'success',
          message: 'Filter updated!',
        });
        const newUserSideFilters = userSideFilters.map((filter) => {
          if (filter.record_id === recordID) {
            return { ...filter, JSON_VALUE: messageFilterState };
          }
          return { ...filter };
        });
        setUserSideFilters([...newUserSideFilters]);
        return;
      }
      setShowValidationPopup({
        show: true,
        type: 'error',
        message: 'Error occured while updating!',
      });
    } catch (e) {
      setShowValidationPopup({
        show: true,
        type: 'error',
        message: 'Error occured while updating!',
      });
    }
  }; // Saves current session params to filters
  const handleDeleteFilter = async (recordID) => {
    try {
      const req = await apiFetcher(
        `${config.apiURL}/mdp/savedfilters?recordid=${recordID}`,
        'DELETE',
        {},
        {},
        false
      );
      if (req.status === 200) {
        const newUserSideFilters = userSideFilters.filter((filter) => {
          return filter.record_id !== recordID;
        });
        setUserSideFilters([...newUserSideFilters]);
        const newUserSidePublicFilters = userSidePublicFilters.filter(
          (filter) => {
            return filter.record_id !== recordID;
          }
        );
        setUserSidePublicFilters([...newUserSidePublicFilters]);
      }
    } catch (e) {
      setShowValidationPopup({
        show: true,
        type: 'error',
        message: 'Error occured while deleting!',
      });
    }
  }; // Deletes filter

  const handleChangeFilterName = async (value, recordID) => {
    const changedFilters = userSideFilters.map((filter) => {
      if (filter.record_id === recordID) {
        return { ...filter, FILTER_NAME: value };
      } else {
        return { ...filter };
      }
    });
    setUserSideFilters([...changedFilters]);

    clearTimeout(timer);
    const newTimer = setTimeout(async () => {
      await apiFetcher(
        `${config.apiURL}/mdp/savedFilterName?recordid=${recordID}`,
        'PATCH',
        {
          FILTER_NAME: value,
        },
        { 'Content-Type': 'application/json' },
        false
      );
    }, 500);

    setTimer(newTimer);
  };

  const handleTogglePublic = async (value, recordID) => {
    const changedFilters = userSideFilters.map((filter) => {
      if (filter.record_id === recordID) {
        return { ...filter, IS_PUBLIC: value };
      } else {
        return { ...filter };
      }
    });
    setUserSideFilters([...changedFilters]);

    if (value === 'N') {
      const newUserSidePublicFilters = userSidePublicFilters.filter(
        (filter) => {
          return filter.record_id !== recordID;
        }
      );
      setUserSidePublicFilters([...newUserSidePublicFilters]);
    } else {
      const newFilter = userSideFilters.filter(
        (filter) => filter.record_id === recordID
      )[0];
      setUserSidePublicFilters([...userSidePublicFilters, newFilter]);
    }

    await apiFetcher(
      `${config.apiURL}/mdp/savedFilterPublicToggle?recordid=${recordID}`,
      'PATCH',
      {
        IS_PUBLIC: value,
      },
      { 'Content-Type': 'application/json' },
      false
    );
  };

  useEffect(() => {
    if (savedFilters && savedFilters.data && savedFilters.data.length) {
      setUserSideFilters(savedFilters.data);
    }
    if (publicFilters && publicFilters.data && publicFilters.data.length) {
      setUserSidePublicFilters(publicFilters.data);
    }
  }, [savedFilters, publicFilters]);

  const isFiltersLoading = () => {
    return Boolean(
      (!acsnList.data ||
        !actnList.data ||
        !fleetList.oprData ||
        !ataList.data ||
        !eqidList.data ||
        !lruList.data ||
        !fltNumList.data ||
        !fltLegList.data ||
        !fltPhaseList.data ||
        !msgTypeList.data ||
        !msgTxtList.data ||
        !eicasMsgList.data) &&
        !acsnListError &&
        !actnListError &&
        !fleetListError &&
        !ataListError &&
        !eqidListError &&
        !lruListError &&
        !fltNumListError &&
        !fltLegListError &&
        !fltPhaseListError &&
        !msgTypeListError &&
        !msgTxtListError &&
        !eicasMsgListError
    );
  };

  const isFiltersFetchError = () => {
    return Boolean(
      fleetListError ||
        acsnListError ||
        actnListError ||
        ataListError ||
        eqidListError ||
        lruListError ||
        fltNumListError ||
        fltLegListError ||
        fltPhaseListError ||
        msgTypeListError ||
        msgTxtListError ||
        eicasMsgListError
    );
  };

  const isFiltersValid = () => {
    // make sure the date/time and ac model filters arent empty
    // before starting a query
    return Boolean(
      messageFilterState.startDate &&
        messageFilterState.endDate &&
        messageFilterState.startTime &&
        messageFilterState.endTime &&
        messageFilterState.acModel.length
    );
  };

  const filterOptionsByACModel = (options) => {
    /*
    @props <options:list>: list of dropdown options
    [{value:"",AC_MODEL:""},{value:"",AC_MODEL:""},...]

    @return <list>: returns the options list filtered on the
    acmodel that the user currently has selected. Returns empty list
    if no ac model is selected
    */
    if (messageFilterState.acModel.length) {
      // our database uses CRJ and CRJ700 to identify CRJ200 and
      // CRJ700/900/1000 respectively, when displying models to the
      // user, make sure to use CRJ200 and CRJ700/900/1000

      return options.data.reduce(
        (i, j) => {
          if (j.AC_MODEL === (
            messageFilterState.acModel === config.CRJ ? 'CRJ' : 'CRJ700'
          )) {
            i.push({ ...j, AC_MODEL: messageFilterState.acModel });
          }
          return i
        }, []);
    } else {
      return [];
    }
  };

  const filterOptionsByFleet = (options, exclude) => {
    /*
    @props <options:list>: list of dropdown options
    [{ACSN/ACTN:"",AC_MODEL:"", OPERATOR_CODE:""},
    {ACSN/ACTN:"",AC_MODEL:"", OPERATOR_CODE:""},...]
    @props <exclude:boolean>: dictates if were excluding options by fleet

    @return <list>: returns the options list filtered on the
    fleets that the user currently has selected. Returns original list
    if no fleets is selected
    */
    if (messageFilterState.fleets.length && options) {
      const validOprcodes = messageFilterState.fleets.map(
        (oprname) => fleetList.oprData[oprname]
      );
      if (exclude) {
        options = options.filter(
          (option) => !validOprcodes.includes(option.OPERATOR_CODE)
        );
      } else {
        options = options.filter((option) =>
          validOprcodes.includes(option.OPERATOR_CODE)
        );
      }
    }
    return options;
  };

  const formatATA = (list) => {
    const comp = (a, b) => {
      if (a.Value < b.Value) {
        return -1;
      }
      if (a.Value > b.Value) {
        return 1;
      }
      return 0;
    };
    const compChapter = (a, b) => {
      if (a.chapter < b.chapter) {
        return -1;
      }
      if (a.chapter > b.chapter) {
        return 1;
      }
      return 0;
    };
    const ATAParentList = [];
    const newList = list.sort(comp).map((item) => {
      const chapter = item.Value.split('-')[0];
      if (!ATAParentList.includes(chapter)) {
        ATAParentList.push(chapter);
      }
      const newItem = item;
      if (item.DESCRIPTION && item.DESCRIPTION !== 'UNKNOWN') {
        newItem.label = `${item.Value}: ${item.DESCRIPTION || ''}`;
      } else {
        newItem.label = item.Value;
      }
      return { ...item, chapter };
    });

    const newATAParentList = ATAParentList.map((item) => {
      return {
        chapter: item,
        AC_MODEL: newList[0].AC_MODEL,
        wholeChapter: true,
        label: `Select all ${item}`,
      };
    });
    return [...newATAParentList, ...newList].sort(compChapter);
  };

  return <>
    <FiltersPanel
      applyURL={`${config.apiURL}/mdp/mdcmessages?offset=0&limit=${config.initialFetchLimit}`}
      filterStateName="MessageFiltersState"
      filterState={messageFilterState}
      setFilterState={setMessageFilterState}
      formatFilters={formatFiltersState}
      setAppliedFilters={setAppliedMessageFilters}
      defaultFilterState={defaultMessageFilterState}
      isFiltersLoading={isFiltersLoading()}
      isFiltersFetchError={isFiltersFetchError()}
      isFiltersValid={isFiltersValid()}
      isTableDataLoading={isMessagesLoading}
      setTableDataState={setMessageTableState}
      setIsTableDataLoading={setIsMessagesLoading}
      setError={setError}
      setCurTablePage={setCurTablePage}
      mimicApplyClick={mimicApplyClick}
      setMimicApplyClick={setMimicApplyClick}
      shouldValidateFilters={shouldValidateFilters}
      setShouldValidateFilters={setShouldValidateFilters}
      popupText="Date/Time and Aircraft Model filters cannot be empty!"
      specialistEditMode={specialistEditMode}
      // endDateOffset={endDateOffset}
      // setEndDateOffset={setEndDateOffset}
    >
      {!isFiltersLoading() && !isFiltersFetchError() && (
        <Fragment>
          <Accordion sx={styles.accordianRoot}>
            <AccordionSummary
              sx={styles.accordianLabel}
              expandIcon={<ExpandMoreIcon color="primary" />}
            >
              Date/Time (UTC)
            </AccordionSummary>
            <AccordionDetails className="otherFilters">
              <div style={styles.dateTimeContainer}>
                <div style={styles.dateTimeBox}>
                  <TextField
                    variant="standard"
                    fullWidth
                    sx={styles.dateForm}
                    id="startDate"
                    label="Start Date"
                    type="date"
                    value={messageFilterState.startDate}
                    onChange={handleChangeStartDate}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      style: styles.dateFormInput,
                    }} />
                </div>
                <div style={styles.dateTimeBox}>
                  <TextField
                    variant="standard"
                    fullWidth
                    sx={styles.dateForm}
                    id="endDate"
                    label="End Date"
                    type="date"
                    value={messageFilterState.endDate}
                    onChange={handleChangeEndDate}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      inputProps: { min: messageFilterState.startDate },
                      style: styles.dateFormInput,
                    }} />
                </div>

                <div style={styles.timeBox}>
                  <TextField
                    variant="standard"
                    fullWidth
                    sx={{ marginRight: '15px', ...styles.dateForm }}
                    id="endDate"
                    label="Start Time"
                    type="time"
                    value={messageFilterState.startTime}
                    onChange={(event) =>
                      setMessageFilterState({
                        ...messageFilterState,
                        startTime: event.target.value,
                      })
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      style: styles.dateFormInput,
                    }} />
                    <TextField
                    variant="standard"
                    fullWidth
                    sx={styles.dateForm}
                    id="endDate"
                    label="End Time"
                    type="time"
                    value={messageFilterState.endTime}
                    onChange={(event) =>
                      setMessageFilterState({
                        ...messageFilterState,
                        endTime: event.target.value,
                      })
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      style: styles.dateFormInput,
                    }}
                    />
                </div>
                <div style={{ width: '100%', display: 'block' }}>
                  <p>Or</p>
                </div>
                <TextField
                  variant="standard"
                  fullWidth
                  type="number"
                  label="Days"
                  value={messageFilterState.endDateOffset}
                  onChange={handleEndDateOffsetChange}
                  editable={"true"}
                  sx={styles.dateForm}
                  InputProps={{
                    style: styles.dateFormInput,
                  }} />
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion sx={styles.accordianRoot}>
            <AccordionSummary
              sx={styles.accordianLabel}
              expandIcon={<ExpandMoreIcon color="primary" />}
            >
              Aircraft Model
            </AccordionSummary>
            <AccordionDetails>
              <CheckBoxSelect
                filterState={messageFilterState}
                setFilterState={setMessageFilterState}
                options={[config.CRJ, config.CRJ700]}
                filterName="acModel"
                shouldValidate={shouldValidateFilters}
              />
            </AccordionDetails>
          </Accordion>
          {showFleetTab && (
            <Accordion sx={styles.accordianRoot}>
              <AccordionSummary
                sx={styles.accordianLabel}
                expandIcon={<ExpandMoreIcon color="primary" />}
              >
                Fleet
              </AccordionSummary>
              <AccordionDetails>
                <SelectBox
                  multiple
                  selectOptions={Object.keys(fleetList.oprData)}
                  filterState={messageFilterState}
                  setFilterState={setMessageFilterState}
                  filterName="fleets"
                  excludeName="excludeFleets"
                />
              </AccordionDetails>
            </Accordion>
          )}
          <Accordion sx={styles.accordianRoot}>
            <AccordionSummary
              sx={styles.accordianLabel}
              expandIcon={<ExpandMoreIcon color="primary" />}
            >
              Aircraft Serial Number
            </AccordionSummary>
            <AccordionDetails>
              <SelectBox
                multiple
                groupName="AC_MODEL"
                groupOptionLabel="Value"
                noOptionsText={'Select an AC Model first'}
                selectOptions={filterOptionsByFleet(
                  filterOptionsByACModel(acsnList),
                  messageFilterState.excludeFleets
                )}
                filterState={messageFilterState}
                setFilterState={setMessageFilterState}
                filterName="acsn"
                excludeName="excludeAcsn"
              />
            </AccordionDetails>
          </Accordion>
          <Accordion sx={styles.accordianRoot}>
            <AccordionSummary
              sx={styles.accordianLabel}
              expandIcon={<ExpandMoreIcon color="primary" />}
            >
              Aircraft Tail Number
            </AccordionSummary>
            <AccordionDetails>
              <SelectBox
                multiple
                groupName="AC_MODEL"
                groupOptionLabel="Value"
                noOptionsText={'Select an AC Model first'}
                selectOptions={filterOptionsByFleet(
                  filterOptionsByACModel(actnList),
                  messageFilterState.excludeFleets
                )}
                filterState={messageFilterState}
                setFilterState={setMessageFilterState}
                filterName="actn"
                excludeName="excludeActn"
              />
            </AccordionDetails>
          </Accordion>
          <Accordion sx={styles.accordianRoot}>
            <AccordionSummary
              sx={styles.accordianLabel}
              expandIcon={<ExpandMoreIcon color="primary" />}
            >
              Equation ID
            </AccordionSummary>
            <AccordionDetails>
              <SelectBox
                multiple
                groupName="AC_MODEL"
                groupOptionLabel="Value"
                noOptionsText={'Select an AC Model first'}
                selectOptions={filterOptionsByACModel(eqidList)}
                filterState={messageFilterState}
                setFilterState={setMessageFilterState}
                filterName="eqIDs"
                excludeName="excludeEqIDs"
              />
            </AccordionDetails>
          </Accordion>
          <Accordion sx={styles.accordianRoot}>
            <AccordionSummary
              sx={styles.accordianLabel}
              expandIcon={<ExpandMoreIcon color="primary" />}
            >
              ATA
            </AccordionSummary>
            <AccordionDetails>
              <SelectBoxATA
                multiple
                usePopper
                groupName="chapter"
                groupOptionLabel="label"
                noOptionsText={'Select an AC Model first'}
                selectOptions={formatATA(filterOptionsByACModel(ataList))}
                filterState={messageFilterState}
                setFilterState={setMessageFilterState}
                filterName="ata"
                excludeName="excludeAta"
              />
            </AccordionDetails>
          </Accordion>
          <Accordion sx={styles.accordianRoot}>
            <AccordionSummary
              sx={styles.accordianLabel}
              expandIcon={<ExpandMoreIcon color="primary" />}
            >
              LRU
            </AccordionSummary>
            <AccordionDetails>
              <SelectBox
                multiple
                groupName="AC_MODEL"
                groupOptionLabel="Value"
                noOptionsText={'Select an AC Model first'}
                selectOptions={filterOptionsByACModel(lruList)}
                filterState={messageFilterState}
                setFilterState={setMessageFilterState}
                filterName="lru"
                excludeName="excludeLru"
              />
            </AccordionDetails>
          </Accordion>
          {messageFilterState.acModel !== "CRJ100/200/400" && (
              <Accordion sx={styles.accordianRoot}>
                <AccordionSummary
                  sx={styles.accordianLabel}
                  expandIcon={<ExpandMoreIcon color="primary" />}
                >
                  EICAS
                </AccordionSummary>
                <AccordionDetails>
                  <SelectBox
                    multiple
                    groupName="AC_MODEL"
                    groupOptionLabel="Value"
                    noOptionsText={messageFilterState.acModel.length
                      ? "Subscription Required" : "Select an AC Model first"}
                    selectOptions={filterOptionsByACModel(eicasMsgList)}
                    filterState={messageFilterState}
                    setFilterState={setMessageFilterState}
                    filterName="eicas"
                    excludeName="excludeEicas"
                    limit={eicasMsgList?.data?.length ?? 100}
                  />
                </AccordionDetails>
              </Accordion>
            )}
          <Accordion sx={styles.accordianRoot}>
            <AccordionSummary
              sx={styles.accordianLabel}
              expandIcon={<ExpandMoreIcon color="primary" />}
            >
              Other
            </AccordionSummary>
            <AccordionDetails className="otherFilters">
              <div className="otherFiltersRow">
                <p className="otherFiltersText">Marked Events</p>
                <ToggleGroup
                  exclusive
                  selectOptions={['Yes', 'No']}
                  filterState={messageFilterState}
                  setFilterState={setMessageFilterState}
                  filterName="markedEvent"
                />
              </div>
              <div className="otherFiltersRow">
                <p className="otherFiltersText">Event Notes</p>
                <ToggleGroup
                  exclusive
                  selectOptions={['Yes', 'No']}
                  filterState={messageFilterState}
                  setFilterState={setMessageFilterState}
                  filterName="eventNotes"
                />
              </div>
              <div className="otherFiltersRow">
                <p className="otherFiltersText">False Messages</p>
                <ToggleGroup
                  exclusive
                  selectOptions={['Yes', 'No']}
                  filterState={messageFilterState}
                  setFilterState={setMessageFilterState}
                  filterName="falseMessages"
                />
              </div>
              <div className="otherFilters2">
                <SelectBox
                  multiple
                  groupName="AC_MODEL"
                  groupOptionLabel="Value"
                  noOptionsText={'Select an AC Model first'}
                  selectOptions={filterOptionsByACModel(msgTypeList)}
                  filterState={messageFilterState}
                  setFilterState={setMessageFilterState}
                  filterName="messageType"
                  excludeName="excludeMsgType"
                  title="Message Type"
                />
                <SelectBox
                  multiple
                  groupName="AC_MODEL"
                  groupOptionLabel="Value"
                  noOptionsText={'Select an AC Model first'}
                  selectOptions={filterOptionsByACModel(fltPhaseList)}
                  filterState={messageFilterState}
                  setFilterState={setMessageFilterState}
                  filterName="flightPhase"
                  excludeName="excludeFltPhase"
                  title="Flight Phase"
                />
                <SelectBox
                  multiple
                  title="Flight Number"
                  selectOptions={fltNumList.data.filter((i) => i !== null)}
                  filterState={messageFilterState}
                  setFilterState={setMessageFilterState}
                  filterName="flightNum"
                  excludeName="excludeflightNum"
                />
                <SelectBox
                  multiple
                  title="Flight Leg "
                  selectOptions={fltLegList.data.filter((i) => i !== null)}
                  filterState={messageFilterState}
                  setFilterState={setMessageFilterState}
                  filterName="flightLeg"
                  excludeName="excludeflightLeg"
                />
                <SelectBox
                  multiple
                  title="Message Text"
                  groupName="AC_MODEL"
                  groupOptionLabel="Value"
                  noOptionsText={'Select an AC Model first'}
                  selectOptions={filterOptionsByACModel(msgTxtList)}
                  filterState={messageFilterState}
                  setFilterState={setMessageFilterState}
                  filterName="messageText"
                  excludeName="excludeMessageText"
                />
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion sx={styles.accordianRoot}>
            <AccordionSummary
              sx={styles.accordianLabel}
              expandIcon={<ExpandMoreIcon color="primary" />}
            >
              My Filters
            </AccordionSummary>
            <AccordionDetails className="otherFilters">
              <div>
                <span className="float-left text-create">Create New</span>
                <IconButton
                  edge="end"
                  aria-label="save"
                  onClick={() => handleCreateFilter()}
                  className="float-right"
                  size="large">
                  <AddIcon />
                </IconButton>
              </div>
              <List dense>
                {userSideFilters.map((filter) => {
                  // filter.JSON_VALUE
                  return (
                    <ListItem
                      key={filter.record_id}
                      dense
                      disableGutters
                      divider
                    >
                      <InputBase
                        value={filter.FILTER_NAME}
                        placeholder="Add a filter Name"
                        onChange={(event) =>
                          handleChangeFilterName(
                            event.target.value,
                            filter.record_id
                          )
                        }
                      />
                      <ListItemText />

                      <Tooltip title="Apply Saved Filter Params">
                        <IconButton
                          edge="end"
                          aria-label="save"
                          onClick={() => handleApplyFilter(filter.record_id)}
                          size="large">
                          <PublishIcon />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Save Current Settings to this Filter">
                        <IconButton
                          edge="end"
                          aria-label="save"
                          onClick={() => handleSaveFilter(filter.record_id)}
                          size="large">
                          <SaveIcon />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Delete Filter">
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => handleDeleteFilter(filter.record_id)}
                          size="large">
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Make Filter Public">
                        {filter.IS_PUBLIC === 'Y' ? (
                          <Switch
                            size="small"
                            color="primary"
                            onClick={() =>
                              handleTogglePublic('N', filter.record_id)
                            }
                            defaultChecked
                          />
                        ) : (
                          <Switch
                            size="small"
                            color="primary"
                            onClick={() =>
                              handleTogglePublic('Y', filter.record_id)
                            }
                          />
                        )}
                      </Tooltip>
                    </ListItem>
                  );
                })}
              </List>
            </AccordionDetails>
          </Accordion>

          <Accordion sx={styles.accordianRoot}>
            <AccordionSummary
              sx={styles.accordianLabel}
              expandIcon={<ExpandMoreIcon color="primary" />}
            >
              Public Filters
            </AccordionSummary>
            <AccordionDetails className="otherFilters">
              <List dense>
                {userSidePublicFilters.map((filter) => {
                  // filter.JSON_VALUE
                  return (
                    <ListItem
                      key={filter.record_id}
                      dense
                      disableGutters
                      divider
                    >
                      <Tooltip title={filter.OKTA_USERNAME}>
                        <InputBase
                          value={filter.FILTER_NAME}
                          placeholder="Add a filter Name"
                        />
                      </Tooltip>

                      <ListItemText />

                      <Tooltip title="Apply Saved Filter Params">
                        <IconButton
                          edge="end"
                          aria-label="save"
                          onClick={() => handleApplyFilter(filter.record_id)}
                          size="large">
                          <PublishIcon />
                        </IconButton>
                      </Tooltip>
                    </ListItem>
                  );
                })}
              </List>
            </AccordionDetails>
          </Accordion>
        </Fragment>
      )}
    </FiltersPanel>
    <Snackbar
      open={showValidationPopup.show}
      autoHideDuration={5000}
      onClose={(event, reason) => handleClosePopup(event, reason)}
    >
      <MuiAlert
        onClose={(event, reason) => handleClosePopup(event, reason)}
        severity={showValidationPopup.type}
        elevation={6}
        variant="filled"
      >
        {showValidationPopup.message}
      </MuiAlert>
    </Snackbar>
  </>;
};
