/* Root component representing the Details panel */
import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import CardContent from "@mui/material/CardContent";
import AllOutIcon from "@mui/icons-material/AllOut";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { Button } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";

export const DetailsPanel = (props) => {
  const {
    hideIcon,
    setIsDetailsPanelExpand,
    specialistEditMode,
    children,
    title,
  } = props;
  const theme = useTheme();
  const styles = ({
    title: {
      color: theme.palette.text.title2,
      fontSize: 18,
      borderBottom: "1px solid " + theme.palette.default.main,
      backgroundColor: theme.palette.secondary.main,
      height: "5%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "0 0 0 0",
      justifySelf: "center",
    },
    detailsPanel: {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
    },
    detailsPanelBody: {
      height: "90%",
      width: "100%",
      overflowY: "auto",
      scrollbarWidth: "thin",
    },
    filtersPanelButtons: {
      height: "7%",
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      borderTop: "1px solid #e0e0e0",
    },
    detailsPanelTitle: {
      color: theme.palette.text.title2,
      margin: "auto",
    },
    detailsPanelButtons: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      position: "relative",
      marginRight: "5px",
    },
    iconButtons: {
      padding: "0 0 0 0",
      margin: "0 10px 0 0",
    },
    buttonIcons: {
      width: "25px",
      height: "25px",
      fill: theme.palette.primary.dark,
    },
  });
  const [isDialogueOpen, setIsDialogueOpen] = useState(false);
  const handleExpand = () => {
    if (!specialistEditMode) {
      setIsDetailsPanelExpand(true);
    } else {
      setIsDialogueOpen(true);
    }
  };

  return (
    <div style={styles.detailsPanel}>
      <CardContent sx={styles.title}>
        {hideIcon === undefined && <div style={{ width: "35px" }}></div>}
        <div style={styles.detailsPanelTitle}>{title}</div>
        {hideIcon === undefined && (
          <div style={styles.detailsPanelButtons}>
            <Tooltip title="Expand Panel">
              <IconButton
                sx={styles.iconButtons}
                onClick={() => handleExpand()}
                size="large">
                <AllOutIcon sx={styles.buttonIcons} />
              </IconButton>
            </Tooltip>
          </div>
        )}
      </CardContent>

      <div style={styles.detailsPanelBody}>{children}</div>
      <Dialog maxWidth="xs" open={isDialogueOpen}>
        <DialogTitle>
          Discard or Save Your Specialist Input Edits Before Switching Pages
        </DialogTitle>
        <DialogActions>
          <Button
            color="error"
            onClick={() => {
              setIsDialogueOpen(false);
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
