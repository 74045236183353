import React, { useState, createContext } from 'react';

export const AlertsDataContext = createContext();

export const AlertsDataContextProvider = (props) => {
  // if the Orca Data table data is loading
  const [isLoading, setIsLoading] = useState(false);
  // if theres an error when querying Orca Data table data
  const [error, setError] = useState(null);
  // state used to store data from Raw Data Table
  const [alertsTableState, setAlertsTableState] = useState(null);

  return (
    <AlertsDataContext.Provider
      value={{
        alertsTableState,
        setAlertsTableState,
        isLoading,
        setIsLoading,
        error,
        setError,
      }}
    >
      {props.children}
    </AlertsDataContext.Provider>
  );
};
export default AlertsDataContextProvider;
