/* eslint-disable max-len */
import React, { Fragment, useContext } from 'react';
import { FlightDeckEffectsDataContext } from 'mdp/contexts/FlightDeckEffectsDataContext';
import { FlightDeckEffectsFiltersContext } from 'mdp/contexts/FlightDeckEffectsFiltersContext';
import { FlightDeckEffectsTable } from 'mdp/components/FlightDeckEffectsTab/FlightDeckEffectsTable/FlightDeckEffectsTable';
import { config } from 'config/config';
import { useTheme, CardContent } from '@mui/material';
import { formatFiltersState } from 'mdp/components/FlightDeckEffectsTab/Filters/FlightDeckEffectsFiltersPanel';
import 'mdp/components/FlightDeckEffectsTab/FlightDeckEffectsTable/FlightDeckEffectsTablePanel.css';

const columns = [
  {
    key: 'MAIN_ATA',
    label: 'Main ATA',
    minWidth: 80,
    truncate: false,
    sortable: true,
    tooltip: 'Date when file was generated by Aircraft',
  },
  {
    key: 'MAIN_ATA_DESC',
    label: 'Main ATA Description',
    minWidth: 140,
    truncate: 30,
    sortable: true,
  },
  {
    key: 'SUB_ATA',
    label: 'Sub ATA',
    minWidth: 80,
    truncate: false,
    sortable: true,
  },
  {
    key: 'SUB_ATA_DESC',
    label: 'Sub ATA Desc',
    minWidth: 140,
    truncate: false,
    sortable: true,
  },
  {
    key: 'FLIGHT_DECK_EVENT',
    label: 'FDE',
    minWidth: 110,
    truncate: false,
    sortable: true,
  },
];

export const FlightDeckEffectsTablePanel = (props) => {
  const theme = useTheme();
  const styles = ({
    title: {
      color: theme.palette.text.title2,
      fontSize: 18,
      borderBottom: '1px solid ' + theme.palette.default.main,
      backgroundColor: theme.palette.secondary.main,
      height: '5%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0 0 0 0',
    },
  });

  const {
    flightDeckEffectsFilterState,
    setFlightDeckEffectsFilterState,
    appliedFlightDeckEffectsFilters,
    // setAppliedFlightDeckEffectsFilters,
    // defaultFlightDeckEffectsFilterState,
    rowsPerPage,
    setRowsPerPage,
    curTablePage,
    setCurTablePage,
    selectedRow,
    setSelectedRow,
    setMimicApplyClick,
  } = useContext(FlightDeckEffectsFiltersContext);
  const {
    flightDeckEffectsTableState,
    setFlightDeckEffectsTableState,
    isLoading,
    setIsLoading,
    error,
    setError,
  } = useContext(FlightDeckEffectsDataContext);
  return (
    <Fragment>
      <CardContent sx={styles.title}>
        <div>FDE & Sensory</div>
      </CardContent>
      <FlightDeckEffectsTable
        url={`${config.apiURL}/mdp/flightDeckEffects`}
        columns={columns}
        rowKey="FLIGHT_DECK_EVENT"
        tableData={{
          ...flightDeckEffectsTableState,
          data: flightDeckEffectsTableState?.data
            ? flightDeckEffectsTableState.data
            : null,
        }}
        setTableData={setFlightDeckEffectsTableState}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        error={error}
        setError={setError}
        appliedFilters={appliedFlightDeckEffectsFilters}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        curTablePage={curTablePage}
        setCurTablePage={setCurTablePage}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        flightDeckEffectsFilterState={flightDeckEffectsFilterState}
        setFlightDeckEffectsFilterState={setFlightDeckEffectsFilterState}
        setMimicApplyClick={setMimicApplyClick}
        formatFilters={formatFiltersState}
      />
    </Fragment>
  );
};
