import React, { Fragment, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from '@mui/material/Alert';
import { useTheme } from "@mui/material/styles";

export const PdfExportButton = (props) => {
  /*
  Component representing the export table button in the data table
  */
  const { exportToPDF, hasTableData } = props;
  const theme = useTheme();
  const styles = ({
    tableIconButton: {
      padding: "3 10 3 10",
      margin: "0 0 0 0",
      backgroundColor: "rgba(202, 204, 206, 0.38)",
      color: "black",
      "&:hover": {
        backgroundColor: "rgba(202, 204, 206)",
      },
    },
    buttonIcons: {
      width: "20px",
      height: "20px",
      marginRight: "8px",
      fill: theme.palette.primary.dark,
    },
    snackBar: {
      vertical: "bottom",
      horizontal: "center",
    }
  });

  const [showValidationPopup, setShowValidationPopup] = useState(false);

  const handleValidationClosePopup = (event, reason) => {
    // function for closing "need to perform a search first" popup
    if (reason === "clickaway") {
      return;
    }
    setShowValidationPopup(false);
  };

  const handleClick = () => {
    if (!hasTableData) {
        setShowValidationPopup(true);
    } else {
        exportToPDF();
    }
  }


  return (
    <Fragment>
      <Tooltip title="Export timeline to pdf">
        <Button
          sx={styles.tableIconButton}
          variant="contained"
          disableElevation
          size="small"
          onClick={handleClick}
        >
          <SaveAltIcon sx={styles.buttonIcons} />
          download
        </Button>
      </Tooltip>

      <Snackbar
        anchorOrigin={styles.snackBar}
        open={showValidationPopup}
        autoHideDuration={5000}
        onClose={(event, reason) => handleValidationClosePopup(event, reason)}
      >
        <MuiAlert
          onClose={(event, reason) => handleValidationClosePopup(event, reason)}
          severity="error"
          elevation={6}
          variant="filled"
        >
          A valid search must be performed first
        </MuiAlert>
      </Snackbar>
    </Fragment>
  );
};
