import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";


const filterOptions = createFilterOptions({
  ignoreCase: true, // ignore case on search
  limit: 100, // max number of dropdown options to show
});
const styles = ({
  selectBox: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  },
  header: {
    padding: "0 0 0 0",
    margin: "0 0 0 0",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  pillSelectRoot: {
    width: "100%",
    padding: "0 0 0 0",
    margin: "0 0 0 0",
  },
  labelText: {
    fontSize: 12,
  },
});

export const SelectBoxATA = (props) => {
  /*
  @props <title:String>: Title of the selectbox, it will show to the left of
  the exclude button, can be left empty
  @props <filterState>: the state the will be updated when options are selected
  or deselected
  @props <setFilterState>: setter for filterState
  @props <filterName>: name of the property in filterState that will be updated
  when options are selected or deselected e.g. "acModel" or "startDate"
  @props <excludeName>: name of the property in filterState that will be updated
  when the exlude button is toggled, if empty then the exclude button wont be
  rendered
  @props <selectOptions>: list of dropdown options
  @props <groupName>: name of the property in selectOptions to group options by
  e.g. if we want to group options by AC_MODEL
  @props <groupOptionLabel>: name of the property in selectOptions that has the
  label of the option e.g. "acsn" or "eqid"
  @props <multiple>: allow multiple option selection
  @props <noOptionsText>: text to show when no dropdown options available
  @props <placeholderText>: text to show in the select box
  */
  const {
    groupName,
    selectOptions,
    groupOptionLabel,
    setFilterState,
    placeholderText,
    multiple,
    noOptionsText,
    excludeName,
    filterState,
    title,
    filterName,
  } = props;
  const [inputValue, setInputValue] = useState("");

  const handleExcludeChange = (event, newState) => {
    setFilterState({
      ...filterState,
      [excludeName]: newState,
    });
  };
  const handleDropdownSelectChange = (event, newValue) => {
    let valueToSet = [...newValue];

    // Check if we added a "Select All" option
    const containsSelectAll = newValue.find((v) => v.wholeChapter);
    if (containsSelectAll) {
      // Get all chapters that are not currently selected and match the chapter
      // of the "Select All"
      const allChapters = selectOptions.filter((v) => {
        if (
          filterState[filterName].some(
            (filterAlreadyApplied) => v.Value === filterAlreadyApplied.Value
          )
        ) {
          return false;
        }
        return v.chapter === containsSelectAll.chapter && !v.wholeChapter;
      });

      // Append new chapters selected and remove the "Select All" option
      const removeSelectAllFilters = newValue.filter((v) => !v.wholeChapter);
      valueToSet = [...removeSelectAllFilters, ...allChapters];
    }
    try {
      valueToSet.sort((a, b) => {
        return a.Value.localeCompare(b.Value);
      });
    } catch {
      valueToSet.sort()
    }
    setFilterState({
      ...filterState,
      [filterName]: valueToSet,
    });
  };

  const handleTypedInputChangeGrouped = (event, newInputValue) => {
    const newInputSplit = newInputValue.split(";").map((item) => item.trim());
    // if they pressed the ; update the filterstate with the input
    if (newInputSplit.length > 1) {
      setFilterState({
        ...filterState,
        [filterName]: filterState[filterName].concat(
          selectOptions.filter((i) =>
            newInputSplit.includes(i[groupOptionLabel])
          )
        ),
      });
    } else {
      // update the text box as they type
      setInputValue(newInputValue);
    }
  };

  return (
    <div style={styles.selectBox}>
      {excludeName && (
        <div style={styles.header}>
          <Typography sx={styles.labelText}>{title}</Typography>
          <FormControlLabel
            control={
              <Switch
                size="small"
                color="primary"
                checked={filterState[excludeName]}
                onChange={handleExcludeChange}
              />
            }
            label={
              <Typography sx={styles.labelText}>Exclude</Typography>
            }
            labelPlacement="start"
          />
        </div>
      )}
      <Autocomplete
        sx={styles.pillSelectRoot}
        // by default "No Options" when dropdown is empty
        // if noOptionsText is provided show that if selectOptions
        // is empty
        noOptionsText={
          noOptionsText
            ? selectOptions.length
              ? "No Options"
              : noOptionsText
            : "No Options"
        }
        disableCloseOnSelect
        multiple={multiple}
        classes={{ input: styles.labelText }}
        // filter out options from dropdown that have already been selected
        options={selectOptions.filter(
          (i) =>
            !filterState[filterName]
              .map((j) => j[groupOptionLabel])
              .includes(i[groupOptionLabel])
        )}
        groupBy={(options) => options[groupName]}
        getOptionLabel={(options) => options[groupOptionLabel]}
        size="small"
        limitTags={6}
        filterOptions={filterOptions}
        value={filterState[filterName]}
        inputValue={inputValue}
        onChange={handleDropdownSelectChange}
        onInputChange={handleTypedInputChangeGrouped}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              variant="outlined"
              placeholder={placeholderText || ""}
            />
          );
        }}
      />
    </div>
  );
};
