import React, { useState, createContext } from 'react';

export const EqIDDataContext = createContext();

export const EqIDDataContextProvider = (props) => {
  // state used to store data from Equations Table
  const [eqIDTableState, setEqIDTableState] = useState(null);
  // if the Equations table data is loading
  const [isLoading, setIsLoading] = useState(false);
  // if theres an error when querying Equations table data
  const [error, setError] = useState(null);

  return (
    <EqIDDataContext.Provider
      value={{
        eqIDTableState,
        setEqIDTableState,
        isLoading,
        setIsLoading,
        error,
        setError,
      }}
    >
      {props.children}
    </EqIDDataContext.Provider>
  );
};

export default EqIDDataContextProvider;
