export const exportToJSON = (filename, jsonObj) => {
  const jsoned = JSON.stringify(jsonObj);
  const blob = new Blob([jsoned], { type: 'application/json' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  a.click();
};

export const getCurrentDate = () => {
  const newDate = new Date();
  const date = newDate.getDate();
  const month = newDate.getMonth() + 1;
  const year = newDate.getFullYear();

  return `${year}${month < 10 ? `0${month}` : `${month}`}${
    date < 10 ? `0${date}` : `${date}`
  }`;
};

export const handleFileUpload = (file, callback) => {
  const fileReader = new FileReader();

  const handleFileRead = (e) => {
    const content = fileReader.result;
    callback(JSON.parse(content));
  };

  fileReader.onloadend = handleFileRead;
  fileReader.readAsText(file);
};

export const hex2Bin = (hexString) => {
  if (hexString === '------') {
    return '------';
  } else {
    return parseInt(hexString, 16)
        .toString(2)
        .padStart(24, '0')
        .match(/.{1,4}/g)
        .join(' ');
  }
};
