import React, { useState, createContext } from 'react';

export const ReviewDataContext = createContext();

export const ReviewDataContextProvider = (props) => {
  // if the Review Data table data is loading
  const [isLoading, setIsLoading] = useState(false);
  // if theres an error when querying Review Data table data
  const [error, setError] = useState(null);
  // state used to store data from Review Data Table
  const [reviewTableState, setReviewTableState] = useState(null);

  return (
    <ReviewDataContext.Provider
      value={{
        reviewTableState,
        setReviewTableState,
        isLoading,
        setIsLoading,
        error,
        setError,
      }}
    >
      {props.children}
    </ReviewDataContext.Provider>
  );
};
export default ReviewDataContextProvider;
