import React from 'react';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import { config } from 'config/config';
import { apiFetcher } from 'utils/apiUtils';

export const LinkTable = (props) => {
  const { maxHeight, AC_MODEL, fieldLabel, selectedRow } = props;
  const theme = useTheme();
  const styles = ({
    container: {
      maxHeight,
      width: '100%',
      scrollbarWidth: 'thin',
    },
    tableRow: {
      '&$selected, &$selected:hover': {
        backgroundColor: theme.palette.secondary.main,
      },
    },
    outlinedDiv: {
      width: '100%',
    },
    cell: {
      fontSize: 11,
      padding: '6px 6px 6px 6px',
    },
    selected: {},
  });

  const buildLink = (val) => {
    let foundFirstNum = false;
    let linkNum = '';

    for (let i = 0; i < val.length; i++) {
      const c = val[i];
      if (!foundFirstNum && c >= '0' && c <= '9') {
        foundFirstNum = true;
      }
      if (foundFirstNum) {
        linkNum = linkNum.concat(c);
      }
    }
    // return "FIM".concat((linkNum).concat(".pdf"))
    return linkNum;
  };

  const getModelNum = () => {
    if (AC_MODEL === 'CRJ700') {
      return '700';
    } else {
      return '200';
    }
  };

  const handleClick = async (linkNum, modelNum) => {
    await apiFetcher(
        `${config.apiURL}/mdp/fimpdfs?`,
        'POST',
        { fileNum: linkNum, modelNum },
        { 'Content-Type': 'application/json' },
        false,
    )
        .then((response) =>
          response.blob().then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'FIM' + linkNum + '.pdf';
            a.click();
          }),
        )
        .catch((e) => {
          console.error(e);
        });
  };
  // eslint-disable-next-line react/display-name
  const InputComponent = React.forwardRef((props, ref) =>
    <div ref={ref} {...props} />);

  const OutlinedDiv = ({ children, label }) => {
    return (
      <TextField
        sx={styles.outlinedDiv}
        variant="outlined"
        label={label}
        multiline
        InputLabelProps={{ shrink: true }}
        InputProps={{
          inputComponent: InputComponent,
        }}
        inputProps={{ children }}
      />
    );
  };

  return (
    <OutlinedDiv label={fieldLabel}>
      <TableContainer sx={styles.container}>
        <Table size="small">
          <TableBody>
            <TableRow
              hover
              tabIndex={-1}
              key={selectedRow.FIT_NUM}
              sx={styles.tableRow}
              classes={{ selected: styles.selected }}
            >
              <TableCell
                sx={ styles.cell }
                size="small"
                key="1"
                align="left"
                style={{ minWidth: '100%', cursor: 'pointer' }}
                onClick={() =>
                  handleClick(buildLink(selectedRow.FIT_NUM), getModelNum())
                }
              >
                {selectedRow.FIT_NUM}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </OutlinedDiv>
  );
};
