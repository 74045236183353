/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import CardContent from '@mui/material/CardContent';
import { useTheme } from '@mui/material/styles';
import Modal from '@mui/material/Modal';
import Slide from '@mui/material/Slide';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { DetailsPanelDetailsTab } from 'mdp/components/EqIDLookupTab/EqIDDetails/DetailsPanelDetailsTab';
import { DetailsPanelLogicTab } from 'mdp/components/EqIDLookupTab/EqIDDetails/DetailsPanelLogicTab';
import { DetailsPanelFimLinks } from 'mdp/components/EqIDLookupTab/EqIDDetails/DetailsPanelFimLinks';
import { DetailsPanelSpecialistInputTab } from 'mdp/components/MessagesTab/MessageDetails/SpecialistInput/DetailsPanelSpecialistInputTab.js';
// import { DetailsPanelMMELTab } from 'mdp/components/EqIDLookupTab/EqIDDetails/DetailsPanelMMELTab';
import { Button } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';

export const EqIDDetailsPanelExpanded = (props) => {
  const {
    panelState,
    setSpecialistEditMode,
    eqDetailsError,
    isEqDetailsLoading,
    setIsDetailsPanelExpand,
    specialistEditMode,
    isDetailsPanelExpand,
  } = props;
  const [height, setHeight] = useState(window.innerHeight);
  useEffect(() => {
    const handleWindowResize = () => setHeight(window.innerHeight);
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);
  const theme = useTheme();
  const styles = ({
    title: {
      color: theme.palette.text.title2,
      fontSize: 18,
      borderBottom: '1px solid ' + theme.palette.default.main,
      backgroundColor: theme.palette.secondary.main,
      height: '5%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0 0 0 0',
    },
    detailsPanelTitle: {
      position: 'absolute',
    },
    detailsPanelButtons: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      position: 'relative',
      marginLeft: 'auto',
      marginRight: '5px',
    },
    iconButtons: {
      padding: '0 0 0 0',
      margin: '0 10px 0 10px',
    },
    buttonIcons: {
      width: '25px',
      height: '25px',
      fill: theme.palette.primary.dark,
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    card: {
      display: 'flex',
      flexDirection: 'column',
      width: '60%',
      height: height > 905 ? '65%' : '80%',
    },
    body: {
      padding: theme.spacing(2),
      overflow: 'scroll',
      height: '100%',
    },
  });

  const [isDialogueOpen, setIsDialogueOpen] = useState(false);

  const handleClose = () => {
    if (!specialistEditMode) {
      setIsDetailsPanelExpand(false);
    } else {
      setIsDialogueOpen(true);
    }
  };

  return <>
    <Modal
      sx={styles.modal}
      closeAfterTransition
      open={isDetailsPanelExpand}
      onClose={() => setIsDetailsPanelExpand(false)}
    >
      <Slide
        in={isDetailsPanelExpand}
        direction="down"
        mountOnEnter
        unmountOnExit
      >
        <Card elevation={3} sx={styles.card}>
          {/* Title bar with close button */}

          <CardContent sx={styles.title}>
            <div style={styles.detailsPanelTitle}>Equation Details</div>
            <div style={styles.detailsPanelButtons}>
              <IconButton
                sx={styles.iconButtons}
                onClick={() => handleClose()}
                size="large">
                <CloseIcon sx={styles.buttonIcons} />
              </IconButton>
            </div>
          </CardContent>

          {/* Details Area */}
          <div style={styles.body}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <DetailsPanelDetailsTab
                  panelState={panelState}
                  isEqDetailsLoading={isEqDetailsLoading}
                  eqDetailsError={eqDetailsError}
                  inExpanded = {true}
                />
              </Grid>

              <Grid item xs={12}>
                <DetailsPanelLogicTab
                  panelState={panelState}
                  isEqDetailsLoading={isEqDetailsLoading}
                  eqDetailsError={eqDetailsError}
                  isDetailsPanelExpand={isDetailsPanelExpand}
                  inExpanded = {true}
                />
              </Grid>
              <Grid item xs={12}>
                <div style={{ paddingBottom: "25px" }}>
                  <DetailsPanelFimLinks
                    panelState={panelState}
                    isEqDetailsLoading={isEqDetailsLoading}
                    eqDetailsError={eqDetailsError}
                    isDetailsPanelExpand={isDetailsPanelExpand}
                    />
                </div>
              </Grid>
              <Grid item xs={12} style={{ marginTop: '25px' }}>
                <DetailsPanelSpecialistInputTab
                  isMessageDetailsLoading={isEqDetailsLoading}
                  panelState={panelState}
                  selectedEQID={panelState?.EQ_ID}
                  specialistEditMode={specialistEditMode}
                  setSpecialistEditMode={setSpecialistEditMode}
                  messageDetailsError={eqDetailsError}
                  inExpanded = {true}
                />
              </Grid>
              {/* <Grid item xs={12}>
                <DetailsPanelMMELTab
                  panelState={panelState}
                  isEqDetailsLoading={isEqDetailsLoading}
                  eqDetailsError={eqDetailsError}
                  isDetailsPanelExpand={isDetailsPanelExpand}
                  setSpecialistEditMode={setSpecialistEditMode}
                  specialistEditMode={specialistEditMode}
                  inExpanded = {true}
                />
              </Grid> */}
            </Grid>
          </div>
        </Card>
      </Slide>
    </Modal>
    <Dialog maxWidth="xs" open={isDialogueOpen}>
      <DialogTitle>
        Discard or Save Your Specialist Input Edits Before Switching Pages
      </DialogTitle>
      <DialogActions>
        <Button
          color="error"
          onClick={() => {
            setIsDialogueOpen(false);
          }}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  </>;
};
