import React, { Fragment, useContext } from 'react';
import { AlertsFiltersContext } from 'orca/contexts/AlertsFiltersContext';
import { AlertsDataContext } from 'orca/contexts/AlertsDataContext';
import { AlertsTable } from 'orca/components/AlertsTab/AlertsTable';


const columns = [
  {
    key: 'ata',
    label: 'ATA',
    minWidth: 60,
  },
  {
    key: 'ac_sn',
    label: 'Serial',
    minWidth: 60,
  },
  {
    key: 'ac_tn',
    label: 'Tail',
    minWidth: 60,
  },
  {
    key: 'message_type',
    label: 'Report Type',
    minWidth: 60,
  },
  {
    key: 'reported_date',
    label: 'Reported Date',
    minWidth: 100,
  },
  {
    key: 'discrepancy',
    label: 'Discrepancy',
    minWidth: 150,
    maxWidth: 200,
  },
  {
    key: 'corrective_action',
    label: 'Corrective Action',
    minWidth: 150,
    maxWidth: 200,
  },
  {
    key: 'repetitive_snags',
    label: 'Repetitive Snags',
    minWidth: 100,
  },
];

export const AlertsTablePanel = (props) => {
  const {
    alertsTableState,
    isLoading,
    setIsLoading,
    error,
    setError,
  } = useContext(AlertsDataContext);

  const {
    keywordsSelected,
    setKeywordsSelected,
    acsnSelected,
    setAcsnSelected,
    selectedRows,
    setSelectedRows,
    rowSelected,
    setRowSelected,
  } = useContext(AlertsFiltersContext)

  // get a list of unique keywords
  const keywordsList = alertsTableState?.data[0] !== null
    ? (Array.from(
        new Set(alertsTableState?.data?.map((item) => item.keyword))))
    : [];

  // group the data by keywords
  const keywordNestedData = alertsTableState?.data[0] !== null
    ? (keywordsList?.map((keyword) => {
      return {
        keyword,
        value: alertsTableState?.data?.filter((item) => {
          return item.keyword === keyword;
        }).map((item) => {
          return {
            ...item,
            value: item.value.map((item, index) => {
              return {
                ...item,
                index, // add unique id
              };
            }),
          };
        }),
      };
    })) : [null];

  // unnested list of data used for downloading
  const unnestedData = alertsTableState?.data[0] !== null
    ? (keywordNestedData.flatMap((item) => item.value)
    .flatMap((item) => item.value)) : []


  return (
    <Fragment>
      <AlertsTable
        key="index"
        columns={columns}
        tableData={{
          data: alertsTableState?.data
          ? keywordNestedData : [],
          searchKeyword: alertsTableState?.searchKeyword,
          originalEventState: alertsTableState?.originalEventState,
        }}
        unnestedData={unnestedData || []}
        error={error}
        setError={setError}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        keywordsSelected={keywordsSelected}
        setKeywordsSelected={setKeywordsSelected}
        acsnSelected={acsnSelected}
        setAcsnSelected={setAcsnSelected}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        rowSelected={rowSelected}
        setRowSelected={setRowSelected}
      ></AlertsTable>
    </Fragment>
  );
};
