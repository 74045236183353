/* eslint-disable max-len */
import React, { Fragment, useContext } from "react";
import { RawDataDataContext } from "mdp/contexts/RawDataDataContext";
import { RawDataFiltersContext } from "mdp/contexts/RawDataFiltersContext";
import { FilesTablePanelButtonBar } from "./FilesTablePanelButtonBar";
import { RawDataTable } from "./RawDataTable";
import { apiFetcher } from "utils/apiUtils";
import { getCurrentDate } from "utils/utilFunctions";
import { config } from "config/config";
import { formatFiltersState } from "mdp/components/RawDataTab/RawDataFiltersPanel";
import "mdp/components/RawDataTab/RawDataTablePanel.css";
/* eslint-enablr max-len */

const columns = [
  {
    key: "FILE_TIMESTAMP",
    label: "Date Generated",
    minWidth: 150,
    truncate: false,
    sortable: true,
    tooltip: "Date when file was generated by Aircraft",
  },
  {
    key: "OPERATOR_NAME",
    label: "Fleet",
    minWidth: 120,
    truncate: 12,
    sortable: true,
  },
  {
    key: "FILE_EXT",
    label: "File Type",
    minWidth: 100,
    truncate: false,
    sortable: true,
  },
  {
    key: "AC_MODEL",
    label: "AC Model",
    minWidth: 110,
    truncate: false,
    sortable: true,
  },
  {
    key: "AC_TN",
    label: "AC Tail #",
    minWidth: 100,
    truncate: false,
    sortable: true,
  },
  {
    key: "AC_SN",
    label: "AC SN",
    minWidth: 100,
    truncate: false,
    sortable: true,
  },
  {
    key: "DISPLAY_FILE_NAME",
    label: "File Name",
    minWidth: 200,
    truncate: 25,
    sortable: true,
  },
  {
    key: "MSG_TOTAL",
    label: "Total Msgs",
    minWidth: 120,
    truncate: false,
    sortable: true,
  },
  {
    key: "MSG_INSERTED",
    label: "Inserted",
    minWidth: 100,
    truncate: false,
    sortable: true,
  },
  {
    key: "MSG_DUPLICATE",
    label: "Duplicates",
    minWidth: 100,
    truncate: false,
    sortable: true,
  },
  {
    key: "MSG_ERROR",
    label: "Errors",
    minWidth: 100,
    truncate: false,
    sortable: true,
  },
  {
    key: "MSG_WARNING",
    label: "Warnings",
    minWidth: 100,
    truncate: false,
    sortable: true,
  },
  {
    key: "ERROR_TEXT",
    label: "Error Text",
    minWidth: 240,
    truncate: 30,
    sortable: true,
  },

  {
    key: "WARNING_TEXT",
    label: "Warning Text",
    minWidth: 240,
    truncate: 30,
    sortable: true,
  },
];

export const RawDataTablePanel = (props) => {
  const { setIsUploadPanelOpen } = props;
  // const theme = useTheme();
  // const useStyles = makeStyles({
  //  tableHeader: {
  //    color: theme.palette.primary.main,
  //    backgroundColor: "white",
  //    fontWeight: "bold",
  //  },
  // });
  const {
    rawDataFilterState,
    setRawDataFilterState,
    appliedRawDataFilters,
    // setAppliedRawDataFilters,
    // defaultRawDataFilterState,
    rowsPerPage,
    setRowsPerPage,
    curTablePage,
    setCurTablePage,
    selectedRows,
    setSelectedRows,
    setMimicApplyClick,
  } = useContext(RawDataFiltersContext);
  const {
    rawDataTableState,
    setRawDataTableState,
    isLoading,
    setIsLoading,
    error,
    setError,
  } = useContext(RawDataDataContext);

  const downloadRawFiles = () => {
    /*
    When the user clicks the download selectd files button, send the
    selected rows from the raw data table to the backend to get the raw
    data files.
    */
    const filesToDownload = {
      filesToDownload: selectedRows.map((i) => {
        return {
          FILE_NAME: i.FILE_NAME,
          OPERATOR_CODE: i.OPERATOR_CODE,
          DATE_ARCHIVED: i.DATE_ARCHIVED,
        };
      }),
    };

    apiFetcher(
      `${config.apiURL}/mdp/rawdata/files`,
      "POST",
      filesToDownload,
      {
        "Content-Type": "application/json",
      },
      false
    )
      .then((response) =>
        response.blob().then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "RawMDCDataFiles_" + getCurrentDate() + ".zip";
          a.click();
        })
      )
      .catch((e) => {
        console.error(e);
      });
  };

  return (
    <Fragment>
      <FilesTablePanelButtonBar
        setIsUploadPanelOpen={setIsUploadPanelOpen}
        numSelectdRows={selectedRows.length}
        downloadRawFiles={downloadRawFiles}
      />
      <RawDataTable
        url={`${config.apiURL}/mdp/rawdata`}
        columns={columns}
        rowKey="RECORD_ID"
        tableData={{
          ...rawDataTableState,
          data: rawDataTableState
            ? rawDataTableState.data.map((i) => {
                return {
                  ...i,
                  DISPLAY_FILE_NAME: i.FILE_NAME.split(/_(.+)/)
                    .slice(0, -1)
                    .join(`_${i.FILE_TIMESTAMP?.slice(0, 10)}_`),
                };
              })
            : [],
        }}
        setTableData={setRawDataTableState}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        error={error}
        setError={setError}
        appliedFilters={appliedRawDataFilters}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        curTablePage={curTablePage}
        setCurTablePage={setCurTablePage}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        rawDataFilterState={rawDataFilterState}
        setRawDataFilterState={setRawDataFilterState}
        setMimicApplyClick={setMimicApplyClick}
        formatFilters={formatFiltersState}
      />
    </Fragment>
  );
};
