import React, { Fragment } from 'react';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import { StaticTextField } from 'mdp/components/Miscellaneous/StaticTextField';
import { StaticTextBox } from 'mdp/components/Miscellaneous/StaticTextBox';
import { DefaultText } from 'mdp/components/Miscellaneous/DefaultText';
import { DataTable } from 'mdp/components/Miscellaneous/DataTable';

const dataColumns = [
  { id: 'BUS', label: 'BUS', minWidth: '20px', align: 'left' },
  { id: 'IOC_BUS', label: 'IOC', minWidth: '15px', align: 'left' },
  { id: 'LABEL', label: 'LBL', minWidth: '15px', align: 'left' },
  { id: 'SDI', label: 'SDI', minWidth: '15px', align: 'left' },
  { id: 'OPERATOR', label: 'OPR', minWidth: '20px', align: 'left' },
  { id: 'BIT_MASK', label: 'Bit Mask', minWidth: '200px', align: 'left' },
  { id: 'Comments', label: 'Comments', minWidth: '200px', align: 'left' },
];

export const DetailsPanelLogicTab = (props) => {
  const {
    eqDetailsError,
    isEqDetailsLoading,
    panelState,
    isDetailsPanelExpand,
    inExpanded
  } = props;
  // const { appliedEqIDFilters } = useContext(EqIDFiltersContext);

  return (
    <Fragment>
      {/* If there's an error fetching the data */}
      {eqDetailsError ? (
        <div>
          <DefaultText text={eqDetailsError.toString()} />
        </div>
      ) : null}
      {/* if data is loading show spinner */}
      {isEqDetailsLoading ? (
        <div style={{ width: '100%', marginLeft: inExpanded !== undefined ? '45%' : '0%' }}>
          <CircularProgress color="primary" />
        </div>
      ) : null}
      {/* show data if not error and not loading */}
      {!isEqDetailsLoading && !eqDetailsError ? (
        <div style={{ width: '100%' }}>
          <Grid container spacing={3}>
            <Grid item xs={isDetailsPanelExpand ? 3 : 6}>
              <StaticTextField
                fieldLabel="Fault Logged"
                fieldValue={panelState.FAULT_LOGGED || ''}
              />
            </Grid>
            <Grid item xs={isDetailsPanelExpand ? 3 : 6}>
              <StaticTextField
                fieldLabel="Message #"
                fieldValue={panelState.MSG_NB || ''}
              />
            </Grid>
            {!isDetailsPanelExpand && (
              <Grid item xs={12}>
                <StaticTextBox
                  maxRows={5}
                  fieldLabel="Logic Used to Trigger Message"
                  fieldValue={panelState.LOGIC || ''}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <DataTable
                maxHeight="200px"
                tableHeader={true}
                fieldLabel="Data Logic"
                data={panelState?.EQ_DEFINITIONS || []}
                columns={dataColumns}
                unselectable
              />
            </Grid>
          </Grid>
        </div>
      ) : null}
    </Fragment>
  );
};
